import AxiosWrapper from '@/helpers/axios-wrapper'

/**
 * @returns Promise
 */
export function list() {
    const params = {};
    return AxiosWrapper('/insights/google_sheets_datashares/list/', {params});
}


export function store(formData) {
    return AxiosWrapper('/insights/google_sheets_datashares/store/', {headers: {"Content-Type": "multipart/form-data"}}, 'POST', formData);
}

/**
 * @returns Promise
 */
export function edit(formData) {
    return AxiosWrapper('/insights/google_sheets_datashares/edit/', {headers: {"Content-Type": "multipart/form-data"}}, 'POST', formData);
}

/**
 * @returns Promise
 * @param datashareId
 */
export function executeDump(datashareId) {
    const params = {datashareId: datashareId};
    return AxiosWrapper('/insights/google_sheets_datashares/execute_dump/', {params});
}

/**
 * @returns Promise
 */
export function deleteElement(formData) {
    return AxiosWrapper('/insights/google_sheets_datashares/delete_one/', {headers: {"Content-Type": "multipart/form-data"}}, 'POST', formData);
}
/**
 * @returns Promise
 */
export function disableElement(formData) {
    return AxiosWrapper('/insights/google_sheets_datashares/disable_one/', {headers: {"Content-Type": "multipart/form-data"}}, 'POST', formData);
}

/**
 * @returns Promise
 */
export function disableElements(formData) {
    return AxiosWrapper('/insights/google_sheets_datashares/disable_list/', {headers: {"Content-Type": "multipart/form-data"}}, 'POST', formData);
}
/**
 * @returns Promise
 */
export function enableElements(formData) {
    return AxiosWrapper('/insights/google_sheets_datashares/enable_list/', {headers: {"Content-Type": "multipart/form-data"}}, 'POST', formData);
}

export function enableElement(formData) {
    return AxiosWrapper('/insights/google_sheets_datashares/enable_one/', {headers: {"Content-Type": "multipart/form-data"}}, 'POST', formData);
}

/**
 * @returns Promise
 */
export function deleteElements(formData) {
    return AxiosWrapper('insights/google_sheets_datashares/delete_list/', {headers: {"Content-Type": "multipart/form-data"}}, 'POST', formData);
}