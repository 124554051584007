<template>
    <!--   <footer class="text-muted at-footer-bottom">-->
    <!--    <div class="container-fluid">-->
    <!--        <p class="float-end mb-1">-->
    <!--            <a href="#">-->
    <!--                <i class="fas fa-chevron-circle-up text-muted"/>-->
    <!--            </a>-->
    <!--        </p>-->
    <!--    </div>-->
    <!--</footer>-->
    <div class="element element-footer">
        <div class="at-wrapper-footer">
            <div class="at-footer">
                <div class="at-footer__inner">
                    <div class="at-footer-left">
                        <ul class="footer-menu pl-2">
                            <li><a href="https://tidart.com/en/legal-notice.html" target="_blank">Privacy policy</a></li>
                            <li><a href="https://tidart.com/en/cookies-policy.html" target="_blank">Cookies policy</a></li>
                            <li><a href="mailto:contact@tidart.com">contact@tidart.com</a></li>
                        </ul>
                    </div>
                    <div class="at-footer-right">
                        {{ getYear }} © Tidart - All right reserved.
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import moment from "moment";

    export default {
        name: 'Footer',
        computed: {
            getYear() {
                return moment().year();
            }
        }
    }
</script>
