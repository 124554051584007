<template>
    <div v-if="getDrawerDataShares.show"
         :class="['ap2-modal', 'ap2-modal-md', 'ap2-modal-is-drawer ap2-modal-scrollable', getDrawerDataShares.show ? 'open' : null]"
         id="datashares-dependencies-drawer">
        <div class="modal-wrapper">
            <div class="ap2-modal-header">
                <div class="modal-header-title-wrapper">
                    <div class="modal-header-reference">
                        {{ getDrawerDataShares.data.label }}
                    </div>
                    <div class="modal-header-title">
                        Related Datashare(s)
                    </div>
                </div>
                <div class="modal-header-close close-modal-demo">
                    <svg @click="closeDatasharesDrawer()"
                         height="1em"
                         viewBox="0 0 384 512"
                         xmlns="http://www.w3.org/2000/svg">
                        <path class="svg-fill"
                              d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"/>
                    </svg>
                </div>
            </div>
            <div class="ap2-modal-content ps">
                <div class="form-group ap2-form-group mt-2">
                    <div class="ap2-form-control-has-icon left is-search">
                        <input :value="filterItemsText"
                               @input="filterItemsText=$event.target.value"
                               class="form-control ap2-form-control"
                               placeholder="Search"
                               type="text">
                        <span class="icon" :style="'-webkit-mask-image: url(' + search + ')'"></span>
                    </div>
                </div>
                <perfect-scrollbar>
                    <div id="variable-content" class="collapsable-element show">
                        <table class="ap2-table is-sortable">
                            <thead class="sticky-top">
                            <tr>
                                <th scope="col" v-for="header in tableConfig.headers" :key="header">
                                    <div class="cell-content-wrapper">
                                        {{ header }}
                                    </div>
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="row in getItemsWithFilter" :key="row.id"
                                :class="row.status === 'Enabled' ? 'success' : 'paused'">
                                <td>
                                    <div class="align-items-center">{{ row.name }}</div>
                                </td>
                                <td>
                                    <div class="align-items-center">{{ row.currency }}</div>
                                </td>
                                <td>
                                    <div :class="['rounded-label', row.type.name]">
                                        <span></span>
                                    </div>
                                </td>
                                <td>
                                    <div class="inline-labels">
                                        <div v-if="row.status === 'Enabled'" class="success">
                                            <div class="rounded-label success">
                                                <span></span>
                                            </div>
                                        </div>
                                        <div v-else class="paused">
                                            <div class="rounded-label paused">
                                                <span></span>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <button @click="goToDatashare(row)"
                                            type="button"
                                            class="ap2-btn btn btn-outline-primary width-auto"
                                    >
                                        <i class="fa-solid fa-up-right-from-square"></i>
                                    </button>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </perfect-scrollbar>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import _ from "lodash";
import search from '@/assets/images/magnifying-glass-solid.svg';
import {PerfectScrollbar} from 'vue3-perfect-scrollbar';
import ListField from "@/components/commons/table-fields/list/index.vue";
import TextField from "@/components/commons/table-fields/text/index.vue";
import openLink from "@/assets/images/arrow-up-right-from-square-solid.svg";

export default {
    name: "DatasharesDrawer",
    components: {PerfectScrollbar},
    computed: {
        ...mapGetters(['getDrawerDataShares', 'getInsightsConstants']),
        getItemsWithFilter() {
            let items = [];
            if (this.filterItemsText) {
                _.forEach(this.getDrawerDataShares.data.datashares.filter(element => _.toLower(element.name).includes(_.toLower(this.filterItemsText))), item => {
                    items.push(item);
                })
            } else {
                _.forEach(this.getDrawerDataShares.data.datashares, item => {
                    items.push(item);
                })
            }
            return items
        },
    },
    data() {
        return {
            filterItemsText: null,
            search: search,
            tableConfig: {
                headers: [
                    'Name',
                    'Currency',
                    'Type',
                    'Status',
                    'Go To'

                ]
            }
        }
    },
    methods: {
        closeDatasharesDrawer() {
            _.set(this.getDrawerDataShares, 'show', false);
            _.set(this.getDrawerDataShares, 'data', null);
            this.filterItemsText = null;
        },
        goToDatashare(dataShare) {
            window.location = '/data-shares/' + dataShare.type.name + '/edit?dataShareId=' + dataShare.id
            _.set(this.getDrawerDataShares, 'show', false);
            _.set(this.getDrawerDataShares, 'data', null);
            this.filterItemsText = null;
        }
    }
}
</script>