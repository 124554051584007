<template>
    <div v-if="getModalConfirmation.action !== 'enabled' || dataSharesLoaded"
         :class="['ap2-modal ap2-modal-xs ap2-modal-content-center ap2-modal-two-options', getModalConfirmation.show ? 'open': null]"
         id="confirmation-modal">
        <div class="modal-wrapper ">
            <div class="ap2-modal-header">
                <div class="modal-header-title-wrapper">
                    <div class="modal-header-reference">
                        {{ getModalConfirmation.origin }}
                    </div>
                    <div class="modal-header-title text-capitalize">
                        {{ getModalConfirmation.action }}
                    </div>
                </div>

                <div class="modal-header-close close-modal-demo">
                    <svg @click="setAction('cancel')"
                         height="1em"
                         viewBox="0 0 384 512"
                         xmlns="http://www.w3.org/2000/svg">
                        <path class="svg-fill"
                              d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"/>
                    </svg>
                </div>
            </div>
            <div class="ap2-modal-content" id="modal-confirmation">
                <div class="warning-image"
                     :style="{'-webkit-mask': 'url(' + danger + ')', '-webkit-mask-repeat': 'no-repeat', '-webkit-mask-position': 'center'}"></div>
                <div class="modal-headline">
                    {{ getModalConfirmation.items ? 'Are you sure?' : 'Are you sure you want to exit?' }}
                </div>
                <p class="modal-text"> {{ createMessage() }} </p>
                <!--                <div v-else-if="getModalConfirmation.action === 'enabled' && dataShares">-->
                <!--                    <p> These datashares cannot be enabled because they do not have any enabled datasources:-->
                <!--                        <br/> </p>-->
                <!--                        <p v-for="element in dataShares"-->
                <!--                           :key="element.id">-->
                <!--                            {{element.name}}-->
                <!--                            <br/>-->
                <!--                        </p>-->
                <!--                    <p> <br/>The rest of them will be enabled </p>-->
                <!--                </div>-->
                <!--                <div v-else>-->
                <!--                    <p> You are about to enable {{ this.getModalConfirmation.items.length }} element(s) </p>-->
                <!--                </div>-->
            </div>
            <div class="ap2-modal-footer modal-controls" style="z-index: 11;">
                <div class="modal-controls">
                    <button type="button" class="ap2-btn btn btn-dark close-modal-demo"
                            @click="setAction('cancel')">
                        Cancel
                    </button>
                    <button type="button" class="ap2-btn btn btn-primary close-modal-demo"
                            @click="setAction('accept')">
                        Yes, I'm sure
                    </button>

                </div>
            </div>
        </div>
    </div>
    <div v-else class="loader page-loader" id="preload"></div>
</template>

<script>
import {mapGetters} from "vuex";
import _ from "lodash";
import danger from "@/assets/images/triangle-exclamation-solid.svg"
import {getProblematicDataSharesEnabling} from "@/services/data-share";
import {getCookie} from "@/helpers/get-cookie";

export default {
    name: "modalConfirmation",
    computed: {
        ...mapGetters(['getModalConfirmation']),
        problematicDataShares() {
            return this.dataShares
        }
    },
    data() {
        return {
            danger: danger,
            dataShares: null,
            dataSharesLoaded: false
        }
    },
    watch: {
        problematicDataShares: {
            handler: function (newVal) {
                this.dataSharesLoaded = true;
            }
        }
    },
    mounted() {
        this.$store.watch(state => state.modalConfirmation, async (value) => {
            if (value.action === 'enable' && value.origin === 'data-share' && value.show === true) {
                await this.loadProblematicDataShares();
            }
        }, {deep: true});
    },
    methods: {
        createMessage() {
            if (this.getModalConfirmation.action === 'delete') {
                return 'You are about to delete ' + this.getModalConfirmation.items.length + ' element(s)'
            } else if (this.getModalConfirmation.action === 'change') {
                return 'All unsaved changes will be lost'
            } else if (this.getModalConfirmation.action === 'pause') {
                return 'You are about to pause ' + this.getModalConfirmation.items.length + ' element(s)'
            } else if (this.getModalConfirmation.action === 'enable') {
                return this.getEnablingMessage();
            }
        },
        setAction(action) {
            if (this.getModalConfirmation.action === 'enable' && action === 'accept') {
                let itemsToEnable = _.filter(this.getModalConfirmation.items, element => !_.includes(this.problematicDataShares.map(item => item.id), element))
                _.set(this.getModalConfirmation, 'items', itemsToEnable)
            }
            _.set(this.getModalConfirmation, 'result', action)
            _.set(this.getModalConfirmation, 'show', false)
            this.dataShares = null;
            this.dataSharesLoaded = false;
        },
        getEnablingMessage() {
            if (this.problematicDataShares.length === 0) {
                return 'You are about to enable ' + this.getModalConfirmation.items.length + ' element(s)';
            } else {
                return 'These datashares cannot be enabled because they\ndo not have any enabled datasources:\n - ' + this.problematicDataShares.map(item => item.name).join('\n - ') + '\nThe rest of them will be enabled'
            }
        },
        async loadProblematicDataShares() {
            let response;
            try {
                const formData = new FormData();
                formData.append('datashareIds', JSON.stringify(this.getModalConfirmation.items));
                formData.append('csrfmiddlewaretoken', getCookie('csrftoken'));
                response = await getProblematicDataSharesEnabling(formData);
            } catch (e) {
                console.log("ERROR: ", e);
                return;
            }
            this.dataShares = response.data.result;
        },
    }
}
</script>

<style scoped>

</style>