<template>
    <thead>
    <tr class="sticky-top">
        <th
                v-if="hasCheckbox"
                :style="{width: '30px'}"
                scope="col"
        >
        </th>
        <th
                v-for="column in columns"
                :key="'header-' + column.property"
                scope="col"
                :class="[column.label === 'Name' ? 'sort-by': '', sort === 'asc' ? 'sort-asc': sort === 'desc' ? 'sort-des' : null]"
        >
            <div class="cell-content-wrapper">
                {{ column.label }}
                <div v-if="column.label === 'Name'" class="ap2-table-sort">
                    <div class="arrow-up"
                         @click="changeOrderBy(column, 'asc')"></div>
                    <div class="arrow-down"
                         @click="changeOrderBy(column, 'desc')"></div>
                </div>
            </div>
        </th>
        <th
                v-if="hasActions"
                :style="{width: '200px'}"
                scope="col"
        >
            Actions
        </th>
    </tr>
    </thead>
</template>
<script>
    export default {
        name: 'TableHeader',
        props: {
            columns: {
                type: Array
            },
            data: {
                type: Array
            },
            hasCheckbox: {
                type: Boolean
            },
            hasActions: {
                type: Boolean
            },
        },
        data() {
          return {
              sort: null,
          }
        },
        methods: {
            changeOrderBy(column, direction) {
                this.sort = direction;
                this.$emit('dispatch-order-by', column, direction)
            },
        }
    }
</script>
<style lang="scss">

</style>