<template>
    <div :class="['at-app-item filter-app', isSelected ?  'at-card-selected' : null]"
         @click="selectApp(app.id)">
        <div class="card at-card shadow-sm">
            <img class="at-app-icon " :src="app.logos.logo_150_150" alt="">
            <div class="container">
                <div class="row sticky-top">
                    <div class="col">
                        <h6 class="card-text m-0 mb-1 ">
                            <span>{{ app.name }}</span>
                        </h6>
                        <h6>
                            <span v-if="app.type === 'Google'" class="badge bg-warning">Google</span>
                            <span v-else-if="app.type === 'Facebook'" class="badge bg-primary">Facebook</span>
                            <span v-else-if="app.type === 'Twitter'" class="badge bg-info">Twitter</span>
                            <span v-else-if="app.type === 'TikTok'" class="badge bg-dark">Tiktok</span>
                            <span v-else-if="app.type === 'System'" class="badge bg-secondary">System</span>
                        </h6>
                    </div>
                    <div class="col">
                        <div class="d-flex justify-content-between align-items-center float-end">
                            <input @click.stop="selectApp(app.id)"
                                   v-model="selected"
                                   :value="app.id"
                                   type="checkbox">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <p class="at-description">
                        {{ app.description }}
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";
    import _ from "lodash";

    export default {
        name: "AppFilterWrapper",
        props: {
            app: {
                type: Object,
                required: true
            },
            dataSelected: {
                type: Array
            }
        },
        data() {
            return {
                selected: this.dataSelected,
                isSelected: false,
            }
        },
        watch: {
            dataSelected: {
                handler: function (newValue,) {
                    this.selected = newValue;
                },
                deep: true
            },
            selected: {
                handler: function (newValue,) {
                    this.$emit('dispatch-elements-selected', newValue)
                }
            }
        },
        methods: {
            selectApp(newValue) {
                if (!_.includes(this.selected, newValue)) {
                    this.selected.push(newValue);
                } else {
                    this.selected = _.filter(this.selected, item => item !== newValue);
                }
                this.isSelected = !this.isSelected;
            }
        }
    }
</script>

<style scoped>

</style>