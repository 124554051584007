import modalAppsFilter from "@/components/modal/modal-apps-filter";

export const SET_SEARCH_TERM = (state, searchTerm) => {
    state.searchTerm = searchTerm;
};

export const SET_MODAL_CLIENT = (state, modalClient) => {
    state.modalClient = modalClient;
};

export const SET_MODAL_SCRIPT = (state, modalScript) => {
    state.modalScript = modalScript;
};

export const SET_CONSTANTS = (state, constants) => {
    state.constants = constants;
};

export const SET_INSIGHTS_CONSTANTS = (state, insightsConstants) => {
    state.insightsConstants = insightsConstants;
};

export const SET_MODAL_APP = (state, modalApp) => {
    state.modalApp = modalApp;
};

export const SET_MODAL_APP_IN_CLIENT = (state, modalAppInClient) => {
    state.modalAppInClient = modalAppInClient;
};

export const SET_MODAL_MARKETPLACE = (state, modalMarketplace) => {
    state.modalMarketplace = modalMarketplace;
};

export const SET_MODAL_PLATFORMS = (state, modalPlatforms) => {
    state.modalPlatforms = modalPlatforms;
};

export const SET_MODAL_DATASHARE_TYPES = (state, modalDataShareTypes) => {
    state.modalDataShareTypes = modalDataShareTypes;
};

export const SET_MODAL_ACCOUNTS = (state, modalAccounts) => {
    state.modalAccounts = modalAccounts;
};

export const SET_MODAL_FIELDS_GOOGLE_SHEETS = (state, modalFieldsGoogleSheets) => {
    state.modalFieldsGoogleSheets = modalFieldsGoogleSheets;
};

export const SET_MODAL_FIELDS_LOOKER_STUDIO = (state, modalFieldsLookerStudio) => {
    state.modalFieldsLookerStudio = modalFieldsLookerStudio;
};

export const SET_MODAL_APPS_FILTER = (state, modalAppsFilter) => {
    state.modalAppsFilter = modalAppsFilter;
};

export const SET_MODAL_CREDENTIALS = (state, modalCredentials) => {
    state.modalCredentials = modalCredentials;
};

export const SET_MODAL_USER = (state, modalUser) => {
    state.modalUser = modalUser;
};

export const SET_MODAL_TEAM = (state, modalTeam) => {
    state.modalTeam = modalTeam;
};

export const SET_MODAL_RESET_PASSWORD = (state, modalResetPassword) => {
    state.modalResetPassword = modalResetPassword;
};

export const SET_APPS_FILTERED = (state, appsFiltered) => {
    state.appsFiltered = appsFiltered;
};

export const SET_MODAL_OPTIONS_PARAMETERS = (state, modalOptionsParameters) => {
    state.modalOptionsParameters = modalOptionsParameters;
};

export const SET_ELEMENTS_VIEW_MODE = (state, elementsViewMode) => {
    state.elementsViewMode = elementsViewMode;
};

export const SET_CLIENT_TEAM = (state, clientTeams) => {
    state.clientTeams = clientTeams;
};

export const SET_CURRENT_OBJECT = (state, currentObject) => {
    state.currentObject = currentObject;
};

export const SET_CURRENT_CLIENT = (state, currentClient) => {
    state.currentClient = currentClient;
};

export const SET_USER = (state, user) => {
    state.user = user;
};

export const SET_APP_PLATFORM = (state, appPlatform) => {
    state.appPlatform = appPlatform;
};

export const SET_AD_BLOCK = (state, adBlock) => {
    state.adBlock = adBlock;
};

export const SET_SIDEBAR_OPEN = (state, sidebarOpen) => {
    state.sidebarOpen = sidebarOpen;
};

export const SET_SAVING_OBJECT = (state, savingObject) => {
    state.savingObject = savingObject;
};

export const SET_BUlK_ACTIONS = (state, bulkActions) => {
    state.bulkActions = bulkActions;
};

export const SET_DETAIL_ACTION = (state, detailAction) => {
    state.detailAction = detailAction;
};

export const SET_FILTER_DRAWER= (state, filterDrawer) => {
    state.filterDrawer = filterDrawer;
};

export const SET_DRAWER_DATASOURCE= (state, drawerDataSources) => {
    state.drawerDataSources = drawerDataSources;
};

export const SET_DRAWER_DATASHARES= (state, drawerDataShares) => {
    state.drawerDataShares = drawerDataShares;
};

export const SET_DRAWER_METRICS= (state, drawerMetrics) => {
    state.drawerMetrics = drawerMetrics;
};

export const SET_DRAWER_DIMENSIONS= (state, drawerDimension) => {
    state.drawerDimension = drawerDimension;
};

export const SET_CHANGED_CLIENTS = (state, changedClients) => {
    state.changedClients = changedClients;
};

export const SET_CLIENTS_DRAWER_OPEN = (state, clientsDrawerOpen) => {
    state.clientsDrawerOpen = clientsDrawerOpen;
};

export const SET_DRAWER_SUMMARY = (state, drawerSummary) => {
    state.drawerSummary = drawerSummary;
};

export const SET_DRAWER_APP_PAGE = (state, drawerAppPage) => {
    state.drawerAppPage = drawerAppPage;
};

export const SET_DRAWER_TEAM_VARIABLES = (state, drawerTeamVariables) => {
    state.drawerTeamVariablese = drawerTeamVariables;
};

export const SET_DRAWER_CLIENT_ACCOUNTS = (state, drawerClientAccounts) => {
    state.drawerClientAccounts = drawerClientAccounts;
};

export const SET_DRAWER_APP_IN_CLIENT_ACCOUNTS = (state, drawerAppInClientAccounts) => {
    state.drawerAppInClientAccounts = drawerAppInClientAccounts;
};

export const SET_DRAWER_TEAMS = (state, drawerTeams) => {
    state.drawerTeams = drawerTeams;
};

export const SET_DRAWER_USERS = (state, drawerUsers) => {
    state.drawerUsers = drawerUsers;
};

export const SET_DRAWER_GROUP_CAMPAIGNS = (state, drawerGroupCampaigns) => {
    state.drawerGroupCampaigns = drawerGroupCampaigns;
};

export const SET_DRAWER_CAMPAIGN_AD_GROUPS = (state, drawerCampaignAdGroups) => {
    state.drawerCampaignAdGroups = drawerCampaignAdGroups;
};

export const SET_DRAWER_MANAGE_GROUPS = (state, drawerManageGroups) => {
    state.drawerManageGroups = drawerManageGroups;
};

export const SET_MODAL_OVERWRITE = (state, modalOverwrite) => {
    state.modalOverwrite = modalOverwrite;
};

export const SET_MODAL_CONFIRMATION_DATA_SOURCE = (state, modalConfirmationDataSource) => {
    state.modalConfirmationDataSource = modalConfirmationDataSource;
};


export const SET_MODAL_CONFIRMATION = (state, modalConfirmation) => {
    state.modalConfirmation = modalConfirmation;
};

export const SET_LOADING = (state, loading) => {
    state.loading = loading;
};

export const SET_IS_OBJECT_CHANGED = (state, isObjectChanged) => {
    state.isObjectChanged = isObjectChanged;
};

export const SET_DATASOURCE_ACCOUNT = (state, dataSourceAccount) => {
    state.dataSourceAccount = dataSourceAccount;
};

export const SET_EMPTY_SELECTED_DATA = (state, emptySelectedData) => {
    state.emptySelectedData = emptySelectedData;
};