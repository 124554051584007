<template>
    <div class="form-group ap2-form-group " style="width: 50%">
        <input
                class="form-control ap2-form-control"
                type="date"
                id="table-date"
                name="table-date"
                :value="value"
        >
    </div>
</template>
<script>
    export default {
        name: 'DateField',
        props: {
            value: {
                type: String
            }
        }
    }
</script>