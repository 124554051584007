<template>
    <div v-if="getModalOverwrite.show & block.length > 0"
         class="ap2-modal ap2-modal-md  ap2-scrollbar ap2-modal-short ap2-modal-scrollable ap2-modal-scrollable-short open"
         id="overwrite-modal">
        <div class="modal-wrapper ">
            <div class="ap2-modal-header">
                <div>
                    <div class="thumbnail "
                         :style="getCurrentObject.object.logos.logo_150_150 ? 'background-image: url(' + getCurrentObject.object.logos.logo_150_150 + ')' : 'background-image: url(' + getConstants.DEFAULT_LOGO_APP + ')'"></div>
                </div>
                <div class="modal-header-title-wrapper">
                    <div class="modal-header-reference">
                        {{ getCurrentObject.object.name }}
                    </div>
                    <div class="modal-header-title">
                        Overwrite variables
                    </div>
                </div>

                <div @click="close()"
                     class="modal-header-close close-modal-demo">
                    <svg height="1em" viewBox="0 0 384 512" xmlns="http://www.w3.org/2000/svg">
                        <path class="svg-fill"
                              d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"/>
                    </svg>
                </div>
            </div>
            <div v-for="data in block"
                 :key="data.name"
                 class="ap2-modal-content">
                <div class="variables-overwrite-header">
                    <div class="variable-info-wrapper">
                        <div class="variable-index">
                            Variable {{ page + 1 }}/{{ total }}
                        </div>
                        <div class="variable-name">
                            {{ data.name }}
                        </div>
                    </div>
                    <nav aria-label="...">
                        <ul class="ap2-pagination pagination pagination-sm justify-content-end">
                            <li @click="previousPage()"
                                :class="['page-item', !allowPrevious ?  'disabled' : null]">
                                <a class="page-link" tabindex="-1">Previous</a>
                            </li>
                            <li
                                    id="page-selector"
                                    :class="['page-item', isCurrentPageClass(page)]"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                    style="..."
                            >
                                <button class="page-link dropdown-toggle">
                                    {{ page + 1 }}
                                </button>
                            </li>
                            <div
                                    tabindex="-1"
                                    role="menu"
                                    aria-hidden="true"
                                    class="dropdown-menu at-dropdown-menu-pages overflow-auto">
                                <div class="page-scrollbar-container ps">
                                    <section class="at-dropdown-items_listWrap">
                                        <div class="col-xs-12">
                                        </div>
                                        <ul class="nav flex-column">
                                            <li v-for="page in getNextPages"
                                                @click="setPage(page)"
                                                :key="page"
                                            >
                                                <a :class="['dropdown-item', getNextPages === page ? 'active' : null]">
                                                    {{ page }}
                                                </a>
                                            </li>
                                        </ul>
                                    </section>
                                </div>
                            </div>
                            <li @click="nextPage()"
                                :class="['page-item', !allowNext ?  'disabled' : null]">
                                <a class="page-link">Next</a>
                            </li>
                        </ul>
                    </nav>
                </div>
                <perfect-scrollbar>
                    <div v-for="item in data.accounts"
                         :key="item.account.id" class="entity-wrapper">
                        <div :class="['entity-header', 'sticky-top', item.account.network.toLowerCase(), 'ap2-toggle-collapse-entity']"
                             data-ap2-target-entity="#overwrite2">
                            <div class="entity-row">
                                <div class="app-entity-labels">
                                    <!--                        <div class="entity-rounded-button google" tooltip="Google"><span></span></div>-->
                                    <!--                        <div class="entity-rounded-button facebook" tooltip="Facebook"><span></span></div>-->
                                    <!--                        <div class="entity-rounded-button system" tooltip="System"><span></span></div>-->
                                    <div :class="['entity-rounded-button', item.account.network.toLowerCase()]"
                                         :tooltip="item.account.network">
                            <span :style="'background-image: url(' + getPlatformImage(item.account.network) + ')'">
                            </span>
                                    </div>
                                </div>

                                <ul class="entity-item">
                                    <li class="entity-name">
                                        {{ item.account.name }}
                                    </li>
                                    <li class="entity-id">
                                        {{ item.account.identifier }}
                                    </li>
                                </ul>
                            </div>
                            <div class="variables-overwrite-badge-wrapper">
                                <ul class="variables-overwrite-badge">
                                    <li>{{ typeof item.value === 'object' ? item.value.name : item.value }}</li>

                                </ul>
                            </div>
                            <div class="entity-row entity-header-controls ap2-form-inline">
                                <div class="entity-header-actions">
                                    <div @click="setDisplayedAccounts(item.account, data.id)"
                                         :class="['rounded-control', 'ap2-toggle-collapse-entity', displayedAccounts.indexOf(item.account.id) !== -1 ? 'show' : null]"
                                         tooltip="Details">
                                        <div :style="{'-webkit-mask-image': 'url(' + chevronDown + ')', 'transform': getRotated(item.account)}"
                                             class="collapse-toggle">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="treeData[item.account.id].loading" class="text-center">
                            <i class="fas fa-spinner fa-spin"></i> Loading affected apps
                        </div>
                        <div v-else-if="treeData[item.account.id].data && treeData[item.account.id].data.length > 0"
                             :class="['entity-detail-wrapper', displayedAccounts.indexOf(item.account.id) !== -1 ? 'show' : null]"
                             id="overwrite2">
                            <tree-view
                                    id="my-tree"
                                    :initial-model="treeData[item.account.id].data"
                                    :model-defaults="modelDefaults"
                            >
                                <template v-slot:text="{ model, customClasses }">
                                    <div class="d-flex align-items-center">
                                        <div class="ms-1 mt-1 mb-1">
                                            <i :class="model.icon"></i>
                                            <span class="ms-1">{{ model.label }}</span>
                                        </div>
                                    </div>
                                </template>
                            </tree-view>
                        </div>
                        <div v-else
                             :class="['text-center', 'entity-detail-wrapper', displayedAccounts.indexOf(item.account.id) !== -1 ? 'show' : null]"
                             id="overwrite2">
                            No apps affected
                        </div>
                    </div>
                </perfect-scrollbar>
            </div>
            <div class="ap2-modal-footer modal-controls">
                <div class="modal-controls">
                    <button @click="close()"
                            type="button" class="ap2-btn btn btn-dark close-modal-demo">Cancel without saving
                    </button>
                    <button @click="save()"
                            type="button" class="ap2-btn btn btn-success close-modal-demo">Overwrite & save
                    </button>

                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import {getModalOverwrite} from "@/store/getters";
    import {mapGetters} from "vuex";
    import _ from "lodash";
    import Google from "@/assets/images/google.svg";
    import Facebook from "@/assets/images/facebook.svg";
    import chevronDown from "@/assets/images/chevron-down-solid.svg";
    import chevronUp from "@/assets/images/check-solid.svg";
    import System from "@/assets/images/code.svg";
    import chevronDownSolid from "@/assets/images/chevron-down-solid.svg";
    import TreeView from "@grapoza/vue-tree"
    import {getOverwriteApps} from "@/services/client";
    import {PerfectScrollbar} from 'vue3-perfect-scrollbar';

    export default {
        name: 'ModalOverwriteVariables',
        components: {TreeView, PerfectScrollbar},
        data() {
            return {
                page: 0,
                pageSize: 1,
                chevronDownSolid: chevronDownSolid,
                displayedAccounts: [],
                google: Google,
                facebook: Facebook,
                chevronDown: chevronDown,
                chevronUp: chevronUp,
                system: System,
                modelDefaults: {
                    state: {
                        expanded: false,
                    },
                    customizations: {
                        classes: {
                            treeViewNodeSelfExpandedIndicator: 'fas fa-chevron-right',
                            treeViewNodeSelf: 'large-line',
                            treeViewNodeSelfText: 'big-text'
                        },
                    }
                },
                treeData: {}
            }
        },
        computed: {
            ...mapGetters(['getModalOverwrite', 'getCurrentObject', 'getConstants', 'getDetailAction']),
            items() {
                if (this.getModalOverwrite.data) {
                    return _.chunk(Object.keys(this.getModalOverwrite.data), this.pageSize);
                }
                return []
            },
            block() {
                if (this.items.length === 0) {
                    return []
                }
                let uniqueItem = this.getModalOverwrite.data[this.items[this.page]];
                uniqueItem['id'] = this.items[this.page];
                return [uniqueItem];
            },
            currentPageSize() {
                return this.block.length;
            },
            start() {
                if (this.items.length === 0) {
                    return 0;
                }
                let normalStart = (this.page * this.currentPageSize) + 1;
                let optimalStart = (this.page * this.pageSize) + 1;
                if (normalStart < optimalStart) {
                    return ((this.page - 1) * this.pageSize) + this.pageSize + 1;
                }
                return normalStart;
            },
            end() {
                if (this.items.length === 0) {
                    return 0;
                }

                let end = (this.start - 1) + this.currentPageSize;
                return this.total < end ? this.total : end;
            },
            total() {
                return this.getModalOverwrite.data ? Object.keys(this.getModalOverwrite.data).length : 0;
            },
            allowNext() {
                return ((this.page + 1) * this.pageSize) < this.total;
            },
            allowPrevious() {
                return this.page > 0;
            },
            getNextPages() {
                let page = 1;
                let pages = [];
                while (page <= this.items.length) {
                    pages.push(page);
                    page++;
                }
                return pages;
            },
            trees() {
                let treesAccounts = {}
                _.forEach(this.block[0].accounts, item => {
                    treesAccounts[item.account.id] = {'data': null, 'loading': false};
                });
                return treesAccounts
            }
        },
        methods: {
            nextPage() {
                if (this.allowNext) {
                    this.page = this.page + 1;
                }
                this.displayedAccounts = [];
            },
            previousPage() {
                if (this.allowPrevious) {
                    this.page = this.page - 1;
                }
                this.displayedAccounts = [];
            },
            setPage(page) {
                this.page = page - 1;
                this.displayedAccounts = [];
            },
            isCurrentPageClass(page) {
                return this.page === (page - 1) ? 'active' : null;
            },
            getPlatformImage(network) {
                if (network === this.getConstants.APP_TYPE_FACEBOOK) {
                    return this.facebook;
                } else if (network === this.getConstants.APP_TYPE_GOOGLE) {
                    return this.google;
                } else {
                    return this.system;
                }
            },
            setDisplayedAccounts(account, accountVariableId) {
                if (_.includes(this.displayedAccounts, account.id)) {
                    _.remove(this.displayedAccounts, function (account_id) {
                        return account_id === account.id;
                    });
                } else {
                    this.displayedAccounts.push(account.id)
                    if (!this.treeData[account.id].data) {
                        this.loadAffectedApps(account.id, accountVariableId)
                    }
                }
            },
            getRotated(account) {
                if (this.displayedAccounts.indexOf(account.id) !== -1) {
                    return 'rotate(180deg)'
                } else {
                    return null
                }
            },
            async loadAffectedApps(accountId, accountVariableId) {
                this.treeData[accountId].loading = true;
                let response;
                let input = {'accountId': accountId, 'accountVariableId': accountVariableId}
                try {
                    response = await getOverwriteApps(input);
                } catch (e) {
                    return;
                }
                if (response.data.status === this.getConstants.RESPONSE_CODES.RESPONSE_CODE_ERROR) {
                    this.$toast.open({
                        message: 'Error recovering affected apps',
                        type: 'error',
                        position: 'top-right',
                        duration: 2000,
                    });
                    return;
                }
                this.treeData[accountId].data = response.data.result
                this.treeData[accountId].loading = false;
            },
            close() {
                this.displayedAccounts = [];
                _.set(this.getModalOverwrite, 'show', false);
                _.set(this.getModalOverwrite, 'data', null);
                _.set(this.getDetailAction, 'action', null);
            },
            save() {
                this.displayedAccounts = [];
                _.set(this.getDetailAction, 'action', 'saveWithOverwrite');
                _.set(this.getModalOverwrite, 'show', false);
                _.set(this.getModalOverwrite, 'data', null);
            }
        },
        watch: {
            block: {
                handler: function (newVal) {
                    if (newVal !== undefined && newVal.length > 0) {
                        _.forEach(newVal[0].accounts, item => {
                            this.treeData[item.account.id] = {'data': null, 'loading': false};
                        });
                    }
                }
            },
            deep: true
        }

    }

</script>
