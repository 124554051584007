<template>
    <div id="ap2-overlay"></div>
</template>

<script>
    export default {
        name: "Overlay"
    }
</script>

<style scoped>

</style>