<template>
    <div v-if="getModalConfirmationDataSource.show & block.length > 0"
         class="ap2-modal ap2-modal-md  ap2-scrollbar ap2-modal-short ap2-modal-scrollable ap2-modal-scrollable-short open"
         id="overwrite-modal">
        <div class="modal-wrapper ">
            <div class="ap2-modal-header">
                <div class="modal-header-title-wrapper">
                    <div class="modal-header-reference">
                        {{ getModalConfirmationDataSource.action }} datasource(s)
                    </div>
                    <div class="modal-header-title">
                        This will affect these DataShares in the following ways:
                    </div>
                </div>

                <div @click="close()"
                     class="modal-header-close close-modal-demo">
                    <svg height="1em" viewBox="0 0 384 512" xmlns="http://www.w3.org/2000/svg">
                        <path class="svg-fill"
                              d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"/>
                    </svg>
                </div>
            </div>
            <div class="ap2-modal-content">
                <perfect-scrollbar>
                    <div v-for="item in block"
                         :key="item.id" class="entity-wrapper">
                        <div :class="['entity-header', 'sticky-top', item.platform.name.toLowerCase(), 'ap2-toggle-collapse-entity']"
                             data-ap2-target-entity="#overwrite2">
                            <div class="entity-row">
                                <div :class="['rounded-label', item.platform.name]" :tooltip="item.platform.label">
                                    <span></span>
                                </div>
                                <ul class="entity-item">
                                    <li class="entity-name">
                                        {{ item.name }}
                                    </li>
                                </ul>
                            </div>
                            <div class="entity-row entity-header-controls ap2-form-inline">
                                <div class="entity-header-actions">
                                    <div @click="setDisplayedDataSources(item)"
                                         :class="['rounded-control', 'ap2-toggle-collapse-entity', displayedDataSources.indexOf(item.id) !== -1 ? 'show' : null]"
                                         tooltip="Details">
                                        <div :style="{'-webkit-mask-image': 'url(' + chevronDown + ')', 'transform': getRotated(item)}"
                                             class="collapse-toggle">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="treeData[item.id].loading" class="text-center">
                            <i class="fas fa-spinner fa-spin"></i> Loading DataShares
                        </div>
                        <div v-else-if="treeData[item.id].data && treeData[item.id].data.length > 0"
                             :class="['entity-detail-wrapper', displayedDataSources.indexOf(item.id) !== -1 ? 'show' : null]"
                             id="overwrite2">
                            <tree-view
                                    id="my-tree"
                                    :initial-model="treeData[item.id].data"
                                    :model-defaults="modelDefaults"
                            >
                                <template v-slot:text="{ model, customClasses }">
                                    <div class="d-flex align-items-center">
                                        <div class="ms-1 mt-1 mb-1">
                                            <i :class="model.icon"></i>
                                            <span class="ms-1">{{ model.label }}</span>
                                        </div>
                                    </div>
                                </template>
                            </tree-view>
                        </div>
                        <div v-else
                             :class="['text-center', 'entity-detail-wrapper', displayedDataSources.indexOf(item.id) !== -1 ? 'show' : null]"
                             id="overwrite2">
                            No datashares affected
                        </div>
                    </div>
                </perfect-scrollbar>
            </div>
            <div class="ap2-modal-footer modal-controls">
                <div class="modal-controls">
                    <button @click="close()"
                            type="button" class="ap2-btn btn btn-dark close-modal-demo">Cancel
                    </button>
                    <button @click="accept()"
                            type="button" class="ap2-btn btn btn-success close-modal-demo">{{getModalConfirmationDataSource.action}}
                    </button>

                </div>
            </div>
        </div>

    </div>
</template>

<script>
import {getModalConfirmationDataSource, getModalOverwrite} from "@/store/getters";
import {mapGetters} from "vuex";
import _ from "lodash";
import Google from "@/assets/images/google.svg";
import Facebook from "@/assets/images/facebook.svg";
import chevronDown from "@/assets/images/chevron-down-solid.svg";
import chevronUp from "@/assets/images/check-solid.svg";
import System from "@/assets/images/code.svg";
import chevronDownSolid from "@/assets/images/chevron-down-solid.svg";
import TreeView from "@grapoza/vue-tree"
import {getDataSourceRelatedDataShares} from "@/services/data-source";
import {PerfectScrollbar} from 'vue3-perfect-scrollbar';

export default {
    name: 'ModalConfirmationDataSource',
    components: {TreeView, PerfectScrollbar},
    data() {
        return {
            page: 0,
            pageSize: 1,
            chevronDownSolid: chevronDownSolid,
            displayedDataSources: [],
            google: Google,
            facebook: Facebook,
            chevronDown: chevronDown,
            chevronUp: chevronUp,
            system: System,
            modelDefaults: {
                state: {
                    expanded: false,
                },
                customizations: {
                    classes: {
                        treeViewNodeSelfExpandedIndicator: 'fas fa-chevron-right',
                        treeViewNodeSelf: 'large-line',
                        treeViewNodeSelfText: 'big-text'
                    },
                }
            },
            treeData: {}
        }
    },
    computed: {
        ...mapGetters(['getModalConfirmationDataSource', 'getInsightsConstants']),
        block() {
          return this.getModalConfirmationDataSource.items;
        },
    },
    watch: {
        block: {
            handler: function (newVal) {
                if (newVal !== undefined && newVal.length > 0) {
                    _.forEach(newVal, item => {
                        this.treeData[item.id] = {'data': null, 'loading': false};
                    });
                }
            }
        },
        deep: true
    },
    methods: {
        setDisplayedDataSources(item) {
            if (_.includes(this.displayedDataSources, item.id)) {
                _.remove(this.displayedDataSources, function (datasource_id) {
                    return datasource_id === item.id;
                });
            } else {
                this.displayedDataSources.push(item.id)
                if (!this.treeData[item.id].data) {
                    this.loadRelatedDataShares(item.id)
                }
            }
        },
        getRotated(item) {
            if (this.displayedDataSources.indexOf(item.id) !== -1) {
                return 'rotate(180deg)'
            } else {
                return null
            }
        },
        async loadRelatedDataShares(dataSourceId) {
            this.treeData[dataSourceId].loading = true;
            let response;
            try {
                response = await getDataSourceRelatedDataShares(dataSourceId, this.getModalConfirmationDataSource.action);
            } catch (e) {
                return;
            }
            if (response.data.status === this.getInsightsConstants.RESPONSE_CODES.RESPONSE_CODE_ERROR) {
                this.$toast.open({
                    message: 'Error recovering related DataShares',
                    type: 'error',
                    position: 'top-right',
                    duration: 2000,
                });
                return;
            }
            this.treeData[dataSourceId].data = response.data.result
            this.treeData[dataSourceId].loading = false;
        },
        close() {
            this.displayedDataSources = [];
            _.set(this.getModalConfirmationDataSource, 'show', false);
            _.set(this.getModalConfirmationDataSource, 'items', []);
            _.set(this.getModalConfirmationDataSource, 'origin', null);
        },
        accept() {
            this.displayedDataSources = [];
            _.set(this.getModalConfirmationDataSource, 'show', false);
            _.set(this.getModalConfirmationDataSource, 'result', 'accept');
        }
    },

}

</script>
