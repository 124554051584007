import {createApp} from 'vue';

import App from '@/components/Index';

import store from './store';
import router from './router';

import Popper from "vue3-popper";
import VueToast from 'vue-toast-notification';
import VueTimepicker from 'vue3-timepicker'
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import {MonthPicker} from 'vue-month-picker'
import {MonthPickerInput} from 'vue-month-picker'
import {PerfectScrollbar} from 'vue3-perfect-scrollbar';
import 'vue3-timepicker/dist/VueTimepicker.css'

import '@fortawesome/fontawesome-free/css/all.css'
import 'vue-toast-notification/dist/theme-sugar.css';
import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css'

import '@fortawesome/fontawesome-free/js/all.js'
import vue3GoogleLogin from 'vue3-google-login'


createApp(App)
    .use(store)
    .use(router)
    .use(VueToast)
    .use(VueTimepicker)
    .use(MonthPicker)
    .use(MonthPickerInput)
    .use(Datepicker)
    .use(Popper)
    .use(PerfectScrollbar)
    .use(vue3GoogleLogin, {
        clientId: '609759317853-meec70s81vvnri48k8j9cr932emh4ie6.apps.googleusercontent.com',
    })
    .directive('clickOutside', {
        bind: function (el, binding, vnode) {
            el.clickOutsideEvent = (event) => {
                if (!(el === event.target || el.contains(event.target)))
                    vnode.context[binding.expression](event)
            };
            document.addEventListener('click', el["clickOutsideEvent"])
        },
        unbind: function (el) {
            document.removeEventListener('click', el["clickOutsideEvent"])
        },
    })
    .mount('#app');

