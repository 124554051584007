<template>
    <div v-if="getDrawerDataSource.show"
         :class="['ap2-modal', 'ap2-modal-md', 'ap2-modal-is-drawer', getDrawerDataSource.show ? 'open' : null]"
         id="drawer-test">
        <div class="modal-wrapper ">
            <div class="ap2-modal-header">
                <div class="modal-header-title-wrapper">
                    <div class="modal-header-reference">
                        Data Sources
                    </div>
                    <div class="modal-header-title">
                        New Data Source(s)
                    </div>
                </div>
                <div class="modal-header-close close-modal-demo">
                    <svg @click="closeDataSourceDrawer()"
                         height="1em"
                         viewBox="0 0 384 512"
                         xmlns="http://www.w3.org/2000/svg">
                        <path class="svg-fill"
                              d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"/>
                    </svg>
                </div>
            </div>
            <div class="ap2-modal-content ps" id="ps-drawer-teams">
                <perfect-scrollbar>
                    <div @click="setCollapse()"
                         :class="['drawer-title-section', 'ap2-toggle-collapse', getCollapseShow ? 'show' : null]"
                         data-ap2-target="#tst-options">
                    <span class="ap2-toggle-collapse-title">
                        Data Sources
                    </span>
                        <span class="ap2-toggle-collapse-chevron">
                        <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                            <path class="svg-fill"
                                  d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"></path>
                        </svg>
                    </span>
                    </div>
                    <div id="tst-options" :class="['collapsable-element', getCollapseShow ? 'show' : null]">
                        <div v-if="dataSources.length > 0" class="form-group ap2-form-group mt-2">
                            <div class="ap2-form-control-has-icon left is-search">
                                <input :value="filterItemsText"
                                       @input="filterItemsText=$event.target.value"
                                       class="form-control ap2-form-control"
                                       placeholder="Search"
                                       type="text">
                                <span class="icon" :style="'-webkit-mask-image: url(' + search + ')'"></span>
                            </div>
                        </div>
                        <div v-if="loading" class="text-center">
                            <i class="fas fa-spinner fa-spin"></i> Loading DataSource
                        </div>
                        <ul v-if="dataSources.length > 0 & !loading"
                            class="input-selector-list has-visible-input">
                            <li v-for="dataSource in getItemsWithFilter"
                                class="form-label"
                                :key="dataSource.id"
                                :class="[dataSource.platform.name, 'form-label']">
                                <input v-model="itemsSelected"
                                       type="checkbox"
                                       :id="dataSource.id"
                                       :value="dataSource.name">
                                <label :for="dataSource.id">
                                    <span></span>
                                    {{ dataSource.name }}
                                </label>
                            </li>
                        </ul>
                        <p v-if="dataSources.length === 0 && !loading" class="mt-2">
                            <center>
                                No more Data Sources to add
                            </center>
                        </p>
                    </div>
                </perfect-scrollbar>
            </div>
            <div class="ap2-modal-footer modal-controls">
                <div class="modal-controls">
                    <button @click="cancelButtonActions()"
                            type="button"
                            class="ap2-btn btn btn-dark close-modal-demo">
                        Cancel
                    </button>
                    <button @click="setSelectedItems()"
                            type="button"
                            class="ap2-btn btn btn-success close-modal-demo">
                        Save
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import _ from "lodash";
import search from '@/assets/images/magnifying-glass-solid.svg';
import {getDrawerDataSource} from "@/store/getters";
import {PerfectScrollbar} from 'vue3-perfect-scrollbar';
import {list, list_exclude_inactive} from "@/services/data-source";

export default {
    name: "DataSourceDrawer",
    components: {PerfectScrollbar},
    computed: {
        ...mapGetters(['getDrawerDataSource', 'getConstants', 'getCurrentObject']),
        getItemsWithFilter() {
            let items = [];
            if (this.filterItemsText) {
                _.forEach(this.dataSources.filter(element => _.toLower(element.name).includes(_.toLower(this.filterItemsText))), item => {
                    items.push(item);
                })
            } else {
                _.forEach(this.dataSources, item => {
                    items.push(item);
                })
            }
            return items
        },
    },
    data() {
        return {
            filterItemsText: null,
            search: search,
            itemsSelected: [],
            dataSources: [],
            getCollapseShow: true,
            loading: false,
        }
    },
    mounted() {
        this.$store.watch(state => state.drawerDataSources, async (value) => {
            if (value.origin === 'data-share' && value.show === true) {
                await this.loadDataSources();
            }
        }, {deep: true});
    },
    methods: {
        closeDataSourceDrawer() {
            this.itemsSelected = [];
            this.filterItemsText = null;
            this.getCollapseShow = true;
            _.set(this.getDrawerDataSource, 'data', {
                itemsSelected: []
            })
            _.set(this.getDrawerDataSource, 'show', false);
        },
        setSelectedItems() {
            _.set(this.getDrawerDataSource, 'result', this.getSelectDataSourceObjects());
            this.closeDataSourceDrawer()
        },
        cancelButtonActions() {
            this.itemsSelected = this.getDrawerDataSource.data.itemsSelected;
            this.closeDataSourceDrawer();
        },
        setCollapse() {
            this.getCollapseShow ? this.getCollapseShow = false : this.getCollapseShow = true
        },
        async loadDataSources() {
            this.dataSources = []
            this.loading = true;
            let response;
            try {
                response = await list_exclude_inactive();
            } catch (e) {
                console.log("ERROR: ", e);
                return;
            }
            let selectedItemsIds = [];
            _.forEach(this.getDrawerDataSource.data.itemsSelected, item => {
                selectedItemsIds.push(item.id);
            });
            _.forEach(response.data.result, dataSource => {
                if (!selectedItemsIds.includes(dataSource.id)) {
                    this.dataSources.push(dataSource);
                }
            })
            this.loading = false;
        },
        getSelectDataSourceObjects() {
            let selectedDataSources = []
            _.forEach(this.dataSources, dataSource => {
                if (_.includes(this.itemsSelected, dataSource.name)) {
                    selectedDataSources.push(dataSource)
                }
            })
            return selectedDataSources;
        }
    }
}
</script>

<style scoped>

</style>