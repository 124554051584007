<template>
    <ModalFields v-if="getModalFieldsGoogleSheets.show"
                 @dispatch-close-modal="close()"
                 @dispatch-save-fields="saveFields($event)"
                 :modal-data="getModalFieldsGoogleSheets.data"
                 :ref="'modalFields'">
        <template v-slot:field-list="{scope}">
            <li v-for="field in $refs.modalFields.filteredFields[scope.id].fields"
                :key="field.id"
                :class="[isFieldDisabled(field) ? 'disabled': null]">
                <input
                        type="checkbox"
                        :id="'check-'+field.id"
                        :name="'check-'+field.id"
                        @input="changeSelectField(scope.id, field)"
                        :checked="isFieldSelected(scope.id, field)"
                >
                <label :for="'check-'+field.id"
                       :class="['entity-icon', scope.icon_class]">
                    {{ field.label }}
                </label>
                <div class="to-right">
                        <span class="field-unit-type">
                            <span :class="['field-unit-type_icon', field.datatype]"></span>
                            {{ field.datatype }}
                        </span>
                    <span class="info-icon"></span>
                </div>
            </li>
        </template>
    </ModalFields>
</template>
<script>

import ModalFields from "@/components/modal/modal-fields/index.vue";
import {mapGetters} from "vuex";
import _ from "lodash";
import {getFieldsDependenciesForField} from "@/services/field-scope";
import {getModalFieldsGoogleSheets} from "@/store/getters";

export default {
    name: 'GoogleSheetDataShareModalFields',
    components: {ModalFields},
    data() {
        return {}
    },
    computed: {
        ...mapGetters(['getModalFieldsGoogleSheets', 'getInsightsConstants', 'getCurrentObject']),
    },
    created() {
        window.addEventListener('keydown', (e) => {
            if (e.key === 'Escape') {
                this.close();
            }
        });
    },
    methods: {
        async changeSelectField(scopeId, field) {
            if (this.isFieldSelected(scopeId, field)) {
                this.unSelectField(field.name, scopeId);
            } else {
                this.$refs.modalFields.fieldsByScope[scopeId].selected.push(field);
            }
        },
        isFieldSelected(scopeId, field) {
            let element = _.find(this.$refs.modalFields.fieldsByScope[scopeId].selected, fieldSelected => fieldSelected.name === field.name)
            return element !== undefined;
        },
        close() {
            _.set(this.getModalFieldsGoogleSheets, 'show', false);
        },
        saveFields(fieldsByScope) {
            _.set(this.getModalFieldsGoogleSheets, 'show', false);
            _.set(this.getModalFieldsGoogleSheets, 'data', fieldsByScope);
        },
        unSelectField(fieldName, scopeId) {
            this.$refs.modalFields.fieldsByScope[scopeId].selected = _.filter(this.$refs.modalFields.fieldsByScope[scopeId].selected, field => field.name !== fieldName);
        },
        isFieldDisabled(field) {
            return this.$route.name.includes('Edit') && field.type === 'Dimension'
        }
    }
}
</script>
<style scoped>

</style>