<template>
    <ListHeader v-if="$route.name === 'DataShares'"
                :has-filters="true">
        <template v-slot:breadcrumb>
            <div id="breadcrumb">
                <ul>
                    <li>
                        <a class="navigate-router">
                            <span class="breadcrumb-level-1 navigate-to ap2-truncate"
                                  :style="getBulkActions.selectedItems.length > 0 ? 'color: var(--bulkActionsText)' : null">
                                Data shares
                            </span>
                        </a>
                    </li>
                </ul>
            </div>
        </template>
        <template v-slot:bulk-actions>
            <div v-if="getBulkActions.origin === 'data-share' && getBulkActions.selectedItems.length > 0"
                 class="header-right">
                <DataSharesBulkActions/>
            </div>
        </template>
        <template v-slot:filters>
            <div v-if="getBulkActions.selectedItems.length === 0 && !showSearch" class="header-right">
                <div>
                    <ul class="filter-pills-wrapper">
                        <li v-if="selectedStatus">
                            <img v-if="selectedStatus === 'Enabled'" :src="play" class="img-filter-pill">
                            <img v-if="selectedStatus === 'Disabled'" :src="pause" class="img-filter-pill">
                            <span></span>{{ selectedStatus }}
                            <div @click="resetStatusFilter(selectedStatus)"
                                 class="close">
                                <svg height="1em" viewBox="0 0 384 512" xmlns="http://www.w3.org/2000/svg">
                                    <path class="svg-fill"
                                          d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"></path>
                                </svg>
                            </div>
                        </li>
                        <li v-for="platform in selectedPlatforms"
                            :key="platform.name"
                            :class="platform.name">
                            <span></span>
                            {{ platform.label }}
                            <div @click="resetPlatformFilter(platform)"
                                 class="close">
                                <svg height="1em" viewBox="0 0 384 512" xmlns="http://www.w3.org/2000/svg">
                                    <path class="svg-fill"
                                          d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"></path>
                                </svg>
                            </div>
                        </li>

                    </ul>
                </div>
            </div>
        </template>
    </ListHeader>
</template>

<script>
    import {mapGetters} from "vuex";
    import search from '@/assets/images/magnifying-glass-solid.svg';
    import ListHeader from "@/components/commons/header/list-header";
    import newIcon from "@/assets/images/plus-solid.svg";
    import DataSharesBulkActions from "@/components/commons/bulk-actions/data-shares/index.vue";
    import _ from "lodash";
    import play from "@/assets/images/play-solid.svg";
    import pause from "@/assets/images/pause-solid.svg";
    import google from "@/assets/images/google.svg";
    import facebook from "@/assets/images/facebook.svg";
    import tiktok from "@/assets/images/tiktok.svg";
    import dv360 from "@/assets/images/DV360.svg";
    import twitter from "@/assets/images/twitter-x.svg";
    import system from "@/assets/images/code.svg";

    export default {
        name: 'DataSharesListHeader',
        components: {
            DataSharesBulkActions,
            ListHeader
        },
        computed: {
            ...mapGetters(['getFilterDrawer', 'getBulkActions', 'getSearchTerm']),
            typeFilters() {
                let app = [];
                _.forEach(this.getFilterDrawer.filters, filter => {
                    if (filter.type === 'appType') {
                        app.push(filter.filter)
                    }
                })
                return app;
            },
            showSearch() {
                return this.getFilterDrawer.filters.length === 0;
            },
            statusFilter() {
                let fieldType = null;
                _.forEach(this.getFilterDrawer.filters, filter => {
                    if (filter.type === 'appStatus') {
                        fieldType = filter.filter
                    }
                })
                return fieldType;
            },
        },
        data() {
            return {
                searchTerm: null,
                search: search,
                newIcon: newIcon,
                selectedStatus: null,
                play: play,
                pause: pause,
                selectedPlatforms: []
            }
        },
        watch: {
            typeFilters: {
                handler: function (newVal) {
                    this.selectedPlatforms = newVal;
                },

            },
            searchTerm: {
                handler: function (newVal) {
                    this.$store.dispatch('setSearchTerm', {searchTerm: newVal})
                }
            },
            statusFilter: {
                handler: function (newVal) {
                    this.selectedStatus = newVal;
                }
            },

        },
        methods: {
            resetStatusFilter(fieldType) {
                let filters = this.getFilterDrawer.filters;
                _.remove(filters, function (filter) {
                    return filter.filter === fieldType;
                })
                _.set(this.getFilterDrawer, 'filters', filters);
                this.selectedFieldType = null;
            },
            resetPlatformFilter(platform) {
                let filters = _.filter(_.cloneDeep(this.getFilterDrawer.filters), filter => filter.type !== 'appType' || filter.filter.name !== platform.name);
                _.set(this.getFilterDrawer, 'filters', filters)
                this.selectedPlatforms = _.filter(this.selectedPlatforms, selectedPlatform => selectedPlatform.name !== platform.name);
            },
            resetFilters() {
                _.set(this.getFilterDrawer, 'filters', []);
            },

        }
    }
</script>
