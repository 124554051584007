<template>
    <div v-if="getUser && getFilterDrawer.origin === 'clients'"
         :class="['ap2-modal', 'ap2-modal-md', 'ap2-modal-is-drawer', getFilterDrawer.show ? 'open' : null]"
         id="drawer-test">
        <div class="modal-wrapper ">
            <div class="ap2-modal-header">
                <div class="modal-header-title-wrapper">
                    <div class="modal-header-reference">
                        Clients
                    </div>
                    <div class="modal-header-title">
                        Filters
                    </div>
                </div>

                <div class="modal-header-close close-modal-demo">
                    <svg @click="closeClientsDrawer()"
                         height="1em"
                         viewBox="0 0 384 512"
                         xmlns="http://www.w3.org/2000/svg">
                        <path class="svg-fill"
                              d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"/>
                    </svg>
                </div>
            </div>
            <div class="ap2-modal-content">
                <perfect-scrollbar>
                    <toggle-collapse
                            @dispatch-selected-option="changeSelectedTeam"
                            :name="'Filter teams'"
                            :options="getClientTeamsList()"
                            :options-type="'radius'"
                            :selected-option="selectedTeam"
                    />
                    <toggle-collapse
                            @dispatch-selected-option="changeSelectedStatus"
                            :name="'Filter elements'"
                            :options="getElementsViewModeList()"
                            :options-type="'icon'"
                            :selected-option="selectedStatus"
                    />
                    <multi-toggle-collapse
                            :name="'Filter type'"
                            :options="getAppsTypes()"
                            :options-type="'icon'"
                            :selected-options="selectedAppTypes"
                    />
                </perfect-scrollbar>
            </div>
            <div class="ap2-modal-footer modal-controls">
                <div class="modal-controls">
                    <button @click="closeClientsDrawer()"
                            type="button"
                            class="ap2-btn btn btn-dark close-modal-demo">
                        Cancel
                    </button>
                    <button @click="setFilters()"
                            type="button"
                            class="ap2-btn btn btn-success close-modal-demo">
                        Save
                    </button>

                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import {mapGetters} from "vuex";
    import ToggleCollapse from "@/components/commons/toggle-collapse/index.vue";
    import Constants from "@/helpers/constants";
    import allElements from '@/assets/images/grid-solid.svg';
    import enabled from '@/assets/images/play-solid.svg';
    import disabled from '@/assets/images/pause-solid.svg';
    import _ from 'lodash';
    import {PerfectScrollbar} from 'vue3-perfect-scrollbar';
    import MultiToggleCollapse from "@/components/commons/multi-toggle-collapse/index.vue";
    import google from "@/assets/images/google.svg";
    import facebook from "@/assets/images/facebook.svg";
    import tiktok from "@/assets/images/tiktok.svg";
    import dv360 from "@/assets/images/DV360.svg";
    import twitter from "@/assets/images/twitter-x.svg";
    import system from "@/assets/images/code.svg";

    export default {
        name: 'filter-client-drawer',
        components: {MultiToggleCollapse, ToggleCollapse, PerfectScrollbar},
        computed: {
            ...mapGetters(['getUser', 'getConstants', 'getFilterDrawer']),
            ApptypeFilters() {
                let filters = [];
                _.forEach(this.getFilterDrawer.filters, filter => {
                    if (filter.type === 'appType') {
                        filters.push(filter.filter)
                    }
                })
                return filters;
            },
            statusFilter() {
                let status = null;
                _.forEach(this.getFilterDrawer.filters, filter => {
                    if (filter.type === 'clientStatus') {
                        status = filter.filter
                    }
                })
                return status;
            },
            teamFilter() {
                let team = null;
                _.forEach(this.getFilterDrawer.filters, filter => {
                    if (filter.type === 'clientTeam') {
                        team = filter.filter
                    }
                })
                return team;
            }
        },
        watch: {
            statusFilter: {
                handler: function (newVal) {
                    this.selectedStatus = newVal;
                }
            },
            teamFilter: {
                handler: function (newVal) {
                    this.selectedTeam = newVal;
                }
            },
            ApptypeFilters: {
                handler: function (newVal) {
                    this.selectedAppTypes = newVal;
                }
            },
            selectedAppTypes: {
                handler: function (newVal) {
                    if (newVal !== this.selectedAppTypes) {
                        if (this.selectedAppTypes.includes(Constants.ELEMENTS_VIEW_MODE_ALL)) {
                            if (this.selectedAppTypes.length > 1) {
                                _.remove(this.selectedAppTypes, function (type) {
                                    return type === Constants.ELEMENTS_VIEW_MODE_ALL;
                                })
                            }
                        } else {
                            if (newVal.includes(Constants.ELEMENTS_VIEW_MODE_ALL)) {
                                this.selectedAppTypes = [Constants.ELEMENTS_VIEW_MODE_ALL];
                            }
                        }
                    }
                }, deep: true
            }

        },
        data() {
            return {
                selectedAppTypes: [],
                selectedTeam: null,
                selectedStatus: null
            }
        },
        created() {
            this.selectedAppTypes = this.ApptypeFilters;
            this.selectedTeam = this.teamFilter;
            this.selectedStatus = this.statusFilter;
        },
        methods: {
            getAppsTypes() {
                return [
                    {'name': 'Google', 'icon': google},
                    {'name': 'Facebook', 'icon': facebook},
                    {'name': 'TikTok', 'icon': tiktok},
                    {'name': 'DV360', 'icon': dv360},
                    {'name': 'Twitter', 'icon': twitter}
                ]
            },
            getClientTeamsList() {
                let teams = []
                _.forEach(this.getUser.teams, team => {
                    if (team.status === 'Enabled') {
                        teams.push(team.name);
                    }
                });
                return teams;
            },
            getElementsViewModeList() {
                return [
                    {'name': 'Enabled', 'icon': enabled},
                    {'name': 'Disabled', 'icon': disabled}
                ]
            },
            closeClientsDrawer() {
                this.selectedAppTypes = [];
                this.selectedStatus = null;
                _.set(this.getFilterDrawer, 'show', false)
            },
            changeSelectedTeam(team) {
                this.selectedTeam = team;
            },
            changeSelectedStatus(status) {
                this.selectedStatus = status;
            },
            setFilters() {
                let filters = [];
                if (this.selectedAppTypes) {
                    _.forEach(this.selectedAppTypes, type => {
                        filters.push({'type': 'appType', 'filter': type})
                    })
                }
                if (this.selectedTeam) {
                    filters.push({'type': 'clientTeam', 'filter': this.selectedTeam})
                }
                if (this.selectedStatus) {
                    filters.push({'type': 'clientStatus', 'filter': this.selectedStatus})
                }
                this.selectedStatus = null;
                this.selectedTeam = null;
                this.selectedAppTypes = [];
                _.set(this.getFilterDrawer, 'filters', filters)
                this.$store.dispatch('setSearchTerm', {searchTerm: null})
                this.closeClientsDrawer();
            }

        }
    }
</script>