import constants from "@/helpers/constants";
import _ from 'lodash';

export function getProgrammingToShow(programming_type, programming_execution) {
    if (programming_type !== null || programming_type !== '') {
        if (programming_type === 'Custom crontab') {
            if (!_.includes(_.values(programming_execution), null)) {
                return [programming_execution.minute,
                    programming_execution.hour,
                    programming_execution.month_day,
                    programming_execution.month,
                    programming_execution.week_day].join(' ')
            }
            return ''
        } else if (programming_type === 'Once per hour') {
            return {'minute': programming_execution.minute};
        } else if (programming_type === 'Once per day') {
            return {
                'hours': parseInt(programming_execution.hour), 'minutes': parseInt(programming_execution.minute),
                'seconds': 0
            };
        } else if (programming_type === 'Once per week') {
            return {
                'hours': parseInt(programming_execution.hour), 'minutes': parseInt(programming_execution.minute),
                'seconds': 0
            };
        } else if (programming_type === 'Once per month') {
            return {
                'hours': parseInt(programming_execution.hour), 'minutes': parseInt(programming_execution.minute),
                'seconds': 0
            };
        }
    } else {
        if (programming_type !== null) {
            if (programming_type === 'Once per day') {
                return {
                    'hours': new Date().getHours(), 'minutes': new Date().getHours(), 'seconds': 0
                };
            } else {
                return null
            }
        } else {
            return null
        }
    }
}

export function getProgrammingDayToShow(programming_type, programming_execution) {
    console.log('PROGRAMMING TYPE', programming_type)
    console.log('PROGRAMMING EXE', programming_execution)
    if (programming_type !== null || programming_type !== '') {
        if (programming_type === 'Once per week') {
            return constants.WEEKDAYS.find(item => item.id === parseInt(programming_execution.week_day));
        } else if (programming_type === 'Once per month') {
            return constants.MONTHDAYS.find(item => item.id === parseInt(programming_execution.month_day));
        } else if (programming_type === 'Once per hour') {
            return constants.MINUTES.find(item => item.id === parseInt(programming_execution.minute));
        }
    } else {
        return null;
    }
}