<template>
    <div v-if="getUser && getDrawerTeamVariables.show"
         :class="['ap2-modal', 'ap2-modal-md', 'ap2-modal-is-drawer', getDrawerTeamVariables.show ? 'open' : null]"
         id="drawer-test">
        <div class="modal-wrapper ">
            <div class="ap2-modal-header">
                <div class="modal-header-title-wrapper">
                    <div class="modal-header-reference">
                        Team Variables
                    </div>
                    <div class="modal-header-title">
                        Select
                    </div>
                </div>
                <div class="modal-header-close close-modal-demo">
                    <svg @click="closeTeamVariablesDrawer()"
                         height="1em"
                         viewBox="0 0 384 512"
                         xmlns="http://www.w3.org/2000/svg">
                        <path class="svg-fill"
                              d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"/>
                    </svg>
                </div>
            </div>
            <div class="ap2-modal-content ps" id="ps-drawer-test">
                <div id="tst-options" :class="['collapsable-element', getCollapseShow ? 'show' : null]">
                    <div class="form-group ap2-form-group">
                    <div class="ap2-form-control-has-icon left is-search">
                        <input :value="filterItemsText"
                               @input="filterItemsText=$event.target.value"
                               class="form-control ap2-form-control"
                               placeholder="Search"
                               type="text">
                        <span class="icon" :style="'-webkit-mask-image: url(' + search + ')'"></span>
                    </div>
                </div>
                <div v-if="loading" class="text-center">
                    <i class="fas fa-spinner fa-spin"></i> Loading team Variables
                </div>
                <ul v-if="teamVariables.length > 0 & !loading" class="input-selector-list has-visible-input">
                    <li v-for="item in getItemsWithFilter"
                        class="form-label"
                        :key="item.id">
                        <input v-model="itemsSelected"
                               type="checkbox"
                               :id="item.id"
                               :value="item.name">
                        <label :for="item.id">
                            {{ item.name }}
                        </label>
                    </li>
                </ul>
                <p v-if="teamVariables.length === 0 && !loading" class="mt-2">
                <center>
                    No more team variables to add
                    </center>
                </p>
                </div>
            </div>
            <div class="ap2-modal-footer modal-controls">
                <div class="modal-controls">
                    <button @click="cancelButtonActions()"
                            type="button"
                            class="ap2-btn btn btn-dark close-modal-demo">
                        Cancel
                    </button>
                    <button @click="setSelectedItems()"
                            type="button"
                            class="ap2-btn btn btn-success close-modal-demo">
                        Save
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";
    import _ from "lodash";
    import search from '@/assets/images/magnifying-glass-solid.svg';
    import {list} from "@/services/team-variable";
    import {getDrawerTeamVariables} from "@/store/getters";

    export default {
        name: "TeamVariablesDrawer",
        props: {
            data: {
                type: Array
            }
        },
        computed: {
            ...mapGetters(['getDrawerTeamVariables', 'getUser', 'getConstants']),
            getItemsWithFilter() {
                let items = [];
                if (this.filterItemsText) {
                    _.forEach(this.teamVariables.filter(element => _.toLower(element.name).includes(_.toLower(this.filterItemsText))), item => {
                        items.push(item);
                    })
                } else {
                    _.forEach(this.teamVariables, item => {
                        items.push(item);
                    })
                }
                return items
            },
        },
        data() {
            return {
                filterItemsText: null,
                search: search,
                teamVariables: [],
                itemsSelected: [],
                getCollapseShow: true,
                loading: false
            }
        },
        mounted() {
            this.$store.watch(state => state.drawerTeamVariables, async (value) => {
                if (value.origin === 'teamVariables' && value.show === true) {
                    await this.loadTeamVariables();
                }
            }, {deep: true});
        },
        methods: {
            getDrawerTeamVariables,
            closeTeamVariablesDrawer() {
                this.itemsSelected = [];
                this.teamVariables = [];
                this.filterItemsText = null;
                _.set(this.getDrawerTeamVariables, 'data', {
                    itemsSelected: []
                })
                _.set(this.getDrawerTeamVariables, 'show', false);
            },
            setSelectedItems() {
                _.set(this.getDrawerTeamVariables, 'result', this.getSelectTeamVariablesObjects());
                this.closeTeamVariablesDrawer()
            },
            cancelButtonActions() {
                this.itemsSelected = this.getDrawerTeamVariables.data.itemsSelected;
                this.closeTeamVariablesDrawer();
            },
            async loadTeamVariables() {
                this.loading = true;
                let response;
                try {
                    response = await list();
                } catch (e) {
                    console.log("ERROR: ", e);
                    return;
                }
                _.forEach(response.data.result, teamVariable => {
                    if (!_.includes(this.getDrawerTeamVariables.data.itemsSelected, teamVariable.id)) {
                        this.teamVariables.push(teamVariable);
                    }
                })
                this.loading = false
            },
            getSelectTeamVariablesObjects() {
                let selectedTeamVariables = []
                _.forEach(this.teamVariables, teamVariable => {
                    if (_.includes(this.itemsSelected, teamVariable.name)) {
                        selectedTeamVariables.push(teamVariable)
                    }
                })
                return selectedTeamVariables;
            }
        }
    }
</script>

<style scoped>

</style>