<template>
    <div v-if="getModalAppsFilter.show">
        <transition name="modal">
            <div class="modal-mask">
                <div class="modal-wrapper">
                    <div class="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="staticBackdropLabel">
                                    Filter by App</h5>
                                <div class="at-nav-controls">
                                    <div class="input-group me-2">
                                        <input @input="searchValue = $event.target.value"
                                               :value="searchValue"
                                               type="text"
                                               class="form-control"
                                               placeholder="Search apps"
                                               aria-label="Search apps"
                                               aria-describedby="button-addon2">
                                        <button class="btn btn-outline-secondary" type="button" id="button-addon2">
                                            <i class="fas fa-search"/>
                                        </button>
                                    </div>
                                    <div class="btn-group me-2">
                                       <span v-for="type in getAppTypes()"
                                             @click="setPlatform(type.name)"
                                             :key="type.name"
                                             :class="['btn btn-sm btn-text-center ', type.class, getAppPlatform === type.name ? 'btn-selected': null]"
                                             type="button">
                                             {{ type.name }}
                                        </span>
                                    </div>
                                    <div class="btn-group me-2">
                                        <button
                                                @click="apply()"
                                                :class="['btn btn-primary btn-text-center ', appsSelected.length === 0 ? 'button-no-clickable': null]"
                                                type="button">
                                            Apply
                                        </button>
                                    </div>
                                    <div class="at-nav-controls">
                                        <button @click="close()"
                                                type="button"
                                                class="btn-close"
                                                data-bs-dismiss="modal"
                                                aria-label="Close">
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-body">
                                <div class="at-marketplace-container-filter">
                                    <app-filter-wrapper
                                            v-for="app in filterApps"
                                            @dispatch-elements-selected="changeSelected"
                                            :key="app.id"
                                            :app="app"
                                            :data-selected="appsSelected"
                                            class="at-app-item"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
    import {list_marketplace as list} from "@/services/app";
    import AppFilterWrapper from "@/components/wrappers/app-filter-wrapper";
    import {mapGetters} from "vuex";
    import _ from "lodash";

    export default {
        name: 'ModalAppsFilter',
        components: {
            AppFilterWrapper,
        },
        data() {
            return {
                apps: [],
                searchValue: null,
                selectedType: 'All',
                appsSelected: [],
            }
        },
        computed: {
            ...mapGetters(['getModalAppsFilter', 'getAppPlatform', 'getAppsFiltered', 'getConstants']),
            filterApps() {
                let toReturn = this.apps
                if (this.searchValue) {
                    toReturn = _.filter(this.apps, app => _.toLower(app.name).includes(_.toLower(this.searchValue)))
                }
                if (this.selectedType !== 'All') {
                    toReturn = _.filter(toReturn, app => app.type === this.selectedType);
                }
                return toReturn
            }
        },
        created() {
            window.addEventListener('keydown', (e) => {
                if (e.key === 'Escape') {
                    this.close();
                }
            });
        },
        mounted() {
            this.$store.watch(state => state.modalAppsFilter, async (value) => {
                if (value.show === true) {
                    this.id = value.id;
                    await this.loadApps();
                }
            }, {deep: true});
            this.$store.watch(state => state.appsFiltered, async (value) => {
                this.appsSelected = _.cloneDeep(value);
            });
        },

        methods: {
            async loadApps() {
                let response;
                try {
                    response = await list();
                } catch (e) {
                    console.log("Error: ", e);
                    return null;
                }

                this.apps = response.data.result;
            },
            close() {
                _.set(this.getModalAppsFilter, 'show', false);
                this.appsSelected = _.cloneDeep(this.getAppsFiltered);
            },
            setPlatform(platform) {
                this.selectedType = platform;
            },
            getAppTypes() {
                return this.getConstants.APP_TYPES;
            },
            changeSelected(selected) {
                this.appsSelected = selected;
            },
            apply() {
                this.$store.dispatch('setAppsFiltered', {appsFiltered: _.cloneDeep(this.appsSelected)});
                this.close();
            }
        }
    }
</script>
