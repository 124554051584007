<template>
    <div class="form-group ap2-form-group" style="width: 50%">
    <input
            class="form-control ap2-form-control"
            type="datetime-local"
            id="table-datetime"
            name="meeting-time"
            :value="value">
    </div>
</template>
<script>
    export default {
        name: 'DatetimeField',
        props: {
            value: {
                type: String,
            }
        }
    }
</script>