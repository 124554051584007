<template>
    <DetailHeader v-if="$route.name === 'DimensionsEdit' || $route.name === 'DimensionsNew'"
                  :route-to-duplicate="getRouteToDuplicate">
        <template v-slot:breadcrumb>
            <div id="breadcrumb">
                <ul>
                    <li>
                        <a href="/dimensions" class="navigate-router">
                            <span class="breadcrumb-level-1 navigate-to ap2-truncate"
                                  @click="openConfirmationModal($event)">
                                Dimensions
                            </span>
                        </a>
                        <ul>
                            <li>
                                <span v-if="getMainSubsectionName"
                                      class="breadcrumb-level-2 ap2-truncate">
                                    {{ getMainSubsectionName }}
                                </span>
                            </li>
                        </ul>
                        <img :src="chevronDownSolid" class="breadcrumb-chevron">
                    </li>
                    <li>
                        <span class="breadcrumb-level-1 ap2-truncate">
                            {{ getSecondarySectionName }}
                        </span>
                    </li>
                </ul>
            </div>
        </template>
    </DetailHeader>
</template>

<script>
import DetailHeader from "@/components/commons/header/detail-header";
import chevronDownSolid from "@/assets/images/chevron-down-solid.svg";
import {mapGetters} from "vuex";
import _ from "lodash";

export default {
    name: "DimensionDetailHeader",
    components: {DetailHeader},
    data() {
        return {
            chevronDownSolid: chevronDownSolid,
        }
    },
    computed: {
        ...mapGetters(['getModalConfirmation', 'getIsObjectChanged', 'getCurrentObject']),
        getMainSubsectionName() {
            let subsectionName = null;
            if (this.getCurrentObject.object) {
                if (this.getCurrentObject.object.id) {
                    if (this.getCurrentObject.object.label) {
                        subsectionName = this.getCurrentObject.object.label;
                    } else {
                        subsectionName = this.getCurrentObject.object.name;
                    }
                }
            }
            return subsectionName;
        },
        getSecondarySectionName() {
            let sectionName = null;
            if (this.$route.name.includes('New')) {
                return 'New'
            }
            if (this.$route.name.includes('Edit')) {
                return 'View'
            }
            return sectionName
        },
        getRouteToDuplicate() {
            let routeToDuplicate = null;
            if (this.$route.name === 'DimensionsEdit' && this.getCurrentObject.object) {
                if (this.getCurrentObject.object.id) {
                    routeToDuplicate = '/dimensions/new?originalDimensionId=' + this.getCurrentObject.object.id;
                }
            }
            return routeToDuplicate
        }
    },
    methods: {
        openConfirmationModal(event) {
            if (this.getIsObjectChanged) {
                event.preventDefault();
                _.set(this.getModalConfirmation, 'origin', 'dimension')
                _.set(this.getModalConfirmation, 'show', true)
                _.set(this.getModalConfirmation, 'items', null)
                _.set(this.getModalConfirmation, 'action', 'change')
            }
        },
    }
}
</script>

<style scoped>

</style>