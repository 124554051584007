<template>
    <GeneralBulkActions @dispatch-bulk-action="applyAction"/>
</template>

<script>
    import _ from "lodash";
    import GeneralBulkActions from "@/components/commons/bulk-actions"
    import {mapGetters} from "vuex";

    export default {
        name: "DataSharesBulkActions",
        components: {GeneralBulkActions},
        computed: {
            ...mapGetters(['getBulkActions', 'getEmptySelectedData', 'getConstants', 'getModalConfirmation'])
        },
        methods: {
            applyAction(action) {
                if (action === 'delete') {
                    _.set(this.getModalConfirmation, 'origin', 'data-share')
                    _.set(this.getModalConfirmation, 'show', true)
                    _.set(this.getModalConfirmation, 'items', this.getBulkActions.selectedItems)
                    _.set(this.getModalConfirmation, 'action', 'delete')
                } else if (action === 'disable') {
                    _.set(this.getModalConfirmation, 'origin', 'data-share');
                    _.set(this.getModalConfirmation, 'show', true);
                    _.set(this.getModalConfirmation, 'items', this.getBulkActions.selectedItems);
                    _.set(this.getModalConfirmation, 'action', 'pause')
                } else if (action === 'enable') {
                    _.set(this.getModalConfirmation, 'origin', 'data-share');
                    _.set(this.getModalConfirmation, 'show', true);
                    _.set(this.getModalConfirmation, 'items', this.getBulkActions.selectedItems);
                    _.set(this.getModalConfirmation, 'action', 'enable')
                }
                _.set(this.getBulkActions, 'selectedItems', []);
                _.set(this.getEmptySelectedData, 'empty', true);

            },
        }
    }
</script>

<style scoped>

</style>