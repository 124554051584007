import _ from 'lodash';

export const getSearchTerm = (state) => state.searchTerm;
export const getMessage = (state) => {
    return _.toUpper(state.message);
};
export const getModalClient = (state) => state.modalClient;
export const getModalScript = (state) => state.modalScript;
export const getModalApp = (state) => state.modalApp;
export const getConstants = (state) => state.constants;
export const getInsightsConstants = (state) => state.insightsConstants;
export const getModalAppInClient = (state) => state.modalAppInClient;
export const getModalMarketplace = (state) => state.modalMarketplace;
export const getModalAccounts = (state) => state.modalAccounts;
export const getModalFieldsGoogleSheets = (state) => state.modalFieldsGoogleSheets;
export const getModalFieldsLookerStudio = (state) => state.modalFieldsLookerStudio;
export const getModalPlatforms = (state) => state.modalPlatforms;
export const getModalDataShareType = (state) => state.modalTypes;
export const getModalAppsFilter = (state) => state.modalAppsFilter;
export const getModalCredentials = (state) => state.modalCredentials;
export const getModalUser = (state) => state.modalUser;
export const getModalTeam = (state) => state.modalTeam;
export const getModalResetPassword = (state) => state.modalResetPassword;
export const getModalOptionsParameters = (state) => state.modalOptionsParameters;
export const getDrawerSummary = (state) => state.drawerSummary;
export const getElementsViewMode = (state) => state.elementsViewMode;
export const getClientTeams = (state) => state.clientTeams;
export const getCurrentObject = (state) => state.currentObject;
export const getCurrentClient = (state) => state.currentClient;
export const getAppsFiltered = (state) => state.appsFiltered;
export const getUser = (state) => state.user;
export const getAppPlatform = (state) => state.appPlatform;
export const getAdBlock = (state) => state.adBlock;
export const getSidebarOpen = (state) => state.sidebarOpen;
export const getSavingObject = (state) => state.savingObject;
export const getBulkActions = (state) => state.bulkActions;
export const getDetailAction = (state) => state.detailAction;
export const getFilterDrawer = (state) => state.filterDrawer;
export const getChangedClients = (state) => state.changedClients;
export const getClientsDrawerOpen = (state) => state.clientsDrawerOpen;
export const getDrawerAppPage = (state) => state.drawerAppPage;
export const getDrawerTeamVariables = (state) => state.drawerTeamVariables;
export const getDrawerClientAccounts = (state) => state.drawerClientAccounts;
export const getDrawerAppInClientAccounts = (state) => state.drawerAppInClientAccounts;
export const getDrawerTeams = (state) => state.drawerTeams;
export const getDrawerUsers = (state) => state.drawerUsers;
export const getDrawerGroupCampaigns = (state) => state.drawerGroupCampaigns;
export const getDrawerCampaignAdGroups = (state) => state.drawerCampaignAdGroups;
export const getDrawerManageGroups = (state) => state.drawerManageGroups;
export const getModalOverwrite = (state) => state.modalOverwrite;
export const getModalConfirmationDataSource = (state) => state.modalConfirmationDataSource;
export const getModalConfirmation = (state) => state.modalConfirmation;
export const getLoading = (state) => state.loading;
export const getIsObjectChanged = (state) => state.isObjectChanged;
export const getDataSourceAccount = (state) => state.dataSourceAccount;
export const getDrawerDataSource = (state) => state.drawerDataSources;
export const getDrawerDataShares = (state) => state.drawerDataShares;
export const getDrawerMetrics = (state) => state.drawerMetrics;
export const getDrawerDimensions = (state) => state.drawerDimension;
export const getEmptySelectedData = (state) => state.emptySelectedData;

