<template>
    <div>
        <div class="modal-header sticky-top">
            <h6>App in client instances</h6>
            <div class="at-nav-controls">
                <button
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#users-app-collapse"
                        role="button"
                        class="btn"
                        aria-expanded="false"
                        aria-controls="collapseExample"
                >
                    <i class="fas fa-chevron-down"/>
                </button>
            </div>
        </div>
        <div class="container mt-3 collapse show" id="scripts-app-collapse">
            <ModalAppTable
                    :config="tableConfig"
                    :data="tableData"
            >
                <template v-slot:actions="{ row }">
                    <div class="btn-group btn-group-sm" role="group" aria-label="Button group with nested dropdown">
                        <button
                                @click="goToAppInClient(row.id, row.client.id)"
                                type="button"
                                class="btn btn-outline-secondary text-nowrap"
                                data-bs-toggle="modal"
                                data-bs-target="#app-modal"
                                data-bs-dismiss="modal"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Edit team"
                        >
                            <i class="fas fa-arrow-alt-circle-right"></i>
                            Go to App in client
                        </button>
                        <div class="btn-group" role="group">
                            <button
                                    id="btnGroupDrop1"
                                    type="button"
                                    class="btn btn-outline-secondary dropdown-toggle"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                            />
                            <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="btnGroupDrop1">
                                <li @click="goToClient(row.client.id)">
                                    <a class="dropdown-item">
                                        <i class="fas fa-arrow-alt-circle-right"></i>
                                        Go to client
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </template>
            </ModalAppTable>
        </div>
    </div>
</template>

<script>

    import ModalAppTable from "@/components/modal/modal-app/table";

    export default {
        name: "AppInstances",
        components: {ModalAppTable},
        props: {
            app: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                tableConfig: {
                    columns: [
                        {property: 'client.name', label: 'Client', type: 'string'},
                        {property: 'name_suffix', label: 'Name + Suffix', type: 'string'},
                    ],
                    hasCheckbox: false,
                    hasActions: true
                },
                tableData: this.app.apps_in_client
            }
        },
        methods: {
            goToAppInClient(id, clientId) {
                window.location = '/apps-in-client?clientId=' + clientId + '&appInClientId=' + id;
            },
            goToClient(clientId) {
                window.location = '/apps-in-client?clientId=' + clientId;
            }
        }
    }
</script>