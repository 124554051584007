<template>
    <ListHeader v-if="$route.name === 'AppsInClient'"
                :has-filters="true">
        <template v-slot:breadcrumb>
            <div id="breadcrumb">
                <ul>
                    <li>
                        <a href="/clients" class="navigate-router">
                            <span class="breadcrumb-level-1 navigate-to ap2-truncate"
                                  :style="getBulkActions.selectedItems.length > 0 ? 'color: var(--bulkActionsText)' : null">
                                Clients
                            </span>
                        </a>
                        <ul>
                            <li>

                                    <span v-if="getCurrentClient" class="breadcrumb-level-2 ap2-truncate"
                                          :style="getBulkActions.selectedItems.length > 0 ? 'color: var(--bulkActionsText)' : null">
                                        <a :href="'/clients/edit?clientId=' + getCurrentClient.id"
                                           class="navigate-router">
                                            {{ getCurrentClient.name }}
                                        </a>
                                    </span>
                            </li>
                        </ul>
                        <img :src="chevronDownSolid" class="breadcrumb-chevron">
                    </li>
                    <li>
                        <span :style="getBulkActions.selectedItems.length > 0 ? 'color: var(--linksColorBulk)' : null"
                              class="breadcrumb-level-1 ap2-truncate">
                            Apps
                        </span>
                    </li>
                </ul>
            </div>
        </template>
        <template v-slot:bulk-actions>
            <div v-if="getBulkActions.origin === 'apps-in-client' && getBulkActions.selectedItems.length > 0"
                 class="header-right">
                <AppsClientBulkActions/>
            </div>
        </template>
        <template v-slot:filters>
            <div v-if="getBulkActions.selectedItems.length === 0 && !showSearch" class="header-right">
                <div>
                    <ul class="filter-pills-wrapper">
                        <li v-for="type in selectedTypes"
                            :key="type"
                            :class="type">
                            <span :style="{ 'background-image': 'url(' + getTypeIcon(type) + ')' }"></span>
                            {{ type }}
                            <div @click="resetTypeFilter(type)"
                                 class="close">
                                <svg height="1em" viewBox="0 0 384 512" xmlns="http://www.w3.org/2000/svg">
                                    <path class="svg-fill"
                                          d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"></path>
                                </svg>
                            </div>
                        </li>
                        <li v-if="selectedStatus">
                            <img v-if="selectedStatus === 'Enabled'" :src="play" class="img-filter-pill">
                            <img v-if="selectedStatus === 'Disabled'" :src="pause" class="img-filter-pill">
                            <span></span>{{ selectedStatus }}
                            <div @click="resetStatusFilter(selectedStatus)"
                                 class="close">
                                <svg height="1em" viewBox="0 0 384 512" xmlns="http://www.w3.org/2000/svg">
                                    <path class="svg-fill"
                                          d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"></path>
                                </svg>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </template>
    </ListHeader>
</template>

<script>
    import {mapGetters} from "vuex";
    import search from '@/assets/images/magnifying-glass-solid.svg';
    import AppsClientBulkActions from "@/components/commons/bulk-actions/apps-client";
    import ListHeader from "@/components/commons/header/list-header";
    import _ from "lodash";
    import ellipsisVertical from "@/assets/images/ellipsis-vertical-solid.svg";
    import facebook from "@/assets/images/facebook.svg";
    import google from "@/assets/images/google.svg";
    import system from "@/assets/images/code.svg";
    import tiktok from "@/assets/images/tiktok.svg";
    import dv360 from "@/assets/images/DV360.svg";
    import twitter from "@/assets/images/twitter-x.svg";
    import play from "@/assets/images/play-solid.svg";
    import pause from "@/assets/images/pause-solid.svg";
    import chevronDownSolid from "@/assets/images/chevron-down-solid.svg";

    export default {
        name: 'AppsClientListHeader',
        components: {
            ListHeader,
            AppsClientBulkActions,
        },
        computed: {
            ...mapGetters(['getFilterDrawer', 'getBulkActions', 'getSearchTerm', 'getCurrentClient']),
            typeFilters() {
                let filters = [];
                _.forEach(this.getFilterDrawer.filters, filter => {
                    if (filter.type === 'appType') {
                        filters.push(filter.filter)
                    }
                })
                return filters;
            },
            statusFilter() {
                let status = null;
                _.forEach(this.getFilterDrawer.filters, filter => {
                    if (filter.type === 'appStatus') {
                        status = filter.filter
                    }
                })
                return status;
            },
            showSearch() {
                return this.getFilterDrawer.filters.length === 0;
            },
        },
        data() {
            return {
                searchTerm: null,
                ellipsisVertical: ellipsisVertical,
                search: search,
                play: play,
                pause: pause,
                selectedStatus: null,
                selectedTypes: [],
                chevronDownSolid: chevronDownSolid
            }
        },
        watch: {
            searchTerm: {
                handler: function (newVal) {
                    this.$store.dispatch('setSearchTerm', {searchTerm: newVal})
                }
            },
            statusFilter: {
                handler: function (newVal) {
                    this.selectedStatus = newVal;
                }
            },
            typeFilters: {
                handler: function (newVal) {
                    this.selectedTypes = newVal;
                }
            }
        },
        methods: {
            openFiltersDrawer() {
                _.set(this.getFilterDrawer, 'show', true)
            },
            resetTypeFilter(type) {
                let filters = this.getFilterDrawer.filters;
                _.remove(filters, function (filter) {
                    return filter.filter === type;
                })
                _.set(this.getFilterDrawer, 'filters', filters)
                _.remove(this.selectedTypes, function (selectedType) {
                    return selectedType === type;
                })
            },
            resetStatusFilter(status) {
                let filters = this.getFilterDrawer.filters;
                _.remove(filters, function (filter) {
                    return filter.filter === status;
                })
                _.set(this.getFilterDrawer, 'filters', filters);
                this.selectedStatus = null;
            },
            getTypeIcon(type) {
                let icons = {
                    'Google': google,
                    'Facebook': facebook,
                    'TikTok': tiktok,
                    'DV360': dv360,
                    'Twitter': twitter,
                    'System': system
                }
                return icons[type]
            },
            resetFilters() {
                _.set(this.getFilterDrawer, 'filters', []);
            }
        }
    }
</script>
