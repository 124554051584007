<template>
    <month-picker-input
            v-if="!date"
            ref="monthPickerInput"
            @input="changeDate"
            :no-default="true"
            :class="[disabled  ? 'at-disabled-month-picker' : null, 'account-holder', 'form-control ap2-form-control']"
    />
    <month-picker-input
            v-else
            ref="monthPickerInput"
            @input="changeDate"
            :default-month="this.dateAux.month"
            :default-year="this.dateAux.year"
            :class="[disabled  ? 'at-disabled-month-picker' : null, 'account-holder', 'form-control ap2-form-control']"
    />
</template>
<script>
    import {MonthPickerInput} from 'vue-month-picker'

    export default {
        name: 'MonthlyPickerField',
        props: {
            date: {
                type: Object,
                required: false,
                default: null
            },
            disabled: {
                type: Boolean,
                required: false,
                default: false
            }
        },
        components: {
            MonthPickerInput
        },
        data() {
            return {
                dateAux: this.date
            }
        },
        mounted() {
            this.$watch(
                    () => {
                        return this.$refs.monthPickerInput.monthPickerVisible;
                    },
                    (val) => {
                        this.$emit('dispatch-month-picker', val);
                    }
            );
        },
        watch: {
            date: {
                handler: 'changeDateAux'
            },
        },
        methods: {
            changeDate(date) {
                if (!this.dateAux) {
                    this.dateAux = {}
                }
                this.dateAux.month = date.monthIndex;
                this.dateAux.year = date.year;
                this.$emit('dispatch-change', this.dateAux);
            },
            changeDateAux(value) {
                this.dateAux = value;
            }
        }
    }
</script>