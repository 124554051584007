<template>
    <div>
        <div
                @click="setCollapse()"
                :class="['drawer-title-section', 'ap2-toggle-collapse', getCollapseShow ? 'show' : null]"
                data-ap2-target="#tst-options">
            <span class="ap2-toggle-collapse-title">
                {{ name }}
            </span>
            <span class="ap2-toggle-collapse-chevron">
                <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                    <path class="svg-fill"
                          d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"></path>
                </svg>
            </span>
        </div>
        <div id="tst-options" :class="['collapsable-element', getCollapseShow ? 'show' : null]">
            <ul v-if="optionsType === 'radius'"
                class="input-selector-list">
                <ul class="input-selector-list has-visible-input">
                    <li v-for="option in options"
                        @click="setSelectedOption(option)"
                        :key="option"
                        class="form-label">
                        <input name="radio-elements" type="radio" :checked="selectedOption === option">
                            <label :class="selectedOption === option ? 'selected-option' : null">
                                {{ option }}
                            </label>
                    </li>
                </ul>
            </ul>
            <ul v-else-if="optionsType === 'icon'" class="input-selector-list has-icon">
                    <li v-for="option in options"
                        @click="setSelectedOption(option.name)"
                        :key="option.name"
                        :class="[option.name, 'form-label']">
<!--                        <input :style="'background-image: url(' + option.icon + ')'" name="icons-elements" type="checkbox" :checked="selectedOption === option.name">-->
                        <label :class="selectedOption === option.name ? 'selected-option' : null">
                            <span :style="{ 'background-image': 'url(' + option.icon + ')' }"></span>
                            {{ option.name }}
                        </label>
                    </li>
            </ul>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'toggle-collapse',
        props: {
            name: {
                type: String
            },
            options: {
                type: Array
            },
            optionsType: {
                type: String
            },
            selectedOption: {
                type: String
            },
        },
        data() {
            return {
                getCollapseShow: true
            }
        },
        methods: {
            setSelectedOption(option) {
                this.$emit('dispatch-selected-option', option)
            },
            setCollapse() {
                this.getCollapseShow ? this.getCollapseShow = false : this.getCollapseShow = true
            }
        }
    }
</script>