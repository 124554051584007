<template>
    <DetailHeader v-if="$route.name === 'ScriptEdit' || $route.name === 'ScriptNew'">
        <template v-slot:breadcrumb>
            <div id="breadcrumb">
                <ul>
                    <li>
                        <a href="/scripts" class="navigate-router">
<!--                        <router-link :to="'/scripts'" class="navigate-router">-->
                            <span class="breadcrumb-level-1 navigate-to ap2-truncate"
                                  @click="openConfirmationModal($event)">
                                Scripts
                            </span>
                        </a>
<!--                        </router-link>-->
                        <ul>
                            <li>
                                <span v-if="getMainSubsectionName"
                                      class="breadcrumb-level-2 ap2-truncate">
                                    {{ getMainSubsectionName }}
                                </span>
                            </li>
                        </ul>
                        <img :src="chevronDownSolid" class="breadcrumb-chevron">
                    </li>
                    <li>
                        <span class="breadcrumb-level-1 ap2-truncate">
                            {{ getSecondarySectionName }}
                        </span>
                    </li>
                </ul>
            </div>
        </template>
    </DetailHeader>
</template>

<script>
    import DetailHeader from "@/components/commons/header/detail-header";
    import _ from "lodash";
    import chevronDownSolid from "@/assets/images/chevron-down-solid.svg";
    import {mapGetters} from "vuex";

    export default {
        name: "ScriptDetailHeader",
        components: {DetailHeader},
        data() {
            return {
                chevronDownSolid: chevronDownSolid,
            }
        },
        computed: {
            ...mapGetters(['getModalConfirmation', 'getCurrentObject', 'getIsObjectChanged']),
            getMainSubsectionName() {
                let subsectionName = null;
                if (this.getCurrentObject.object) {
                    subsectionName = this.getCurrentObject.object.name;
                }
                return subsectionName;
            },
            getSecondarySectionName() {
                let sectionName = null;
                if (this.$route.name.includes('New')) {
                    return 'New'
                }
                if (this.$route.name.includes('Edit')) {
                    return 'Edit'
                }
                return sectionName
            }
        },
        methods: {
            openConfirmationModal(event) {
                if (this.getIsObjectChanged) {
                    event.preventDefault();
                    _.set(this.getModalConfirmation, 'origin', 'script')
                    _.set(this.getModalConfirmation, 'show', true)
                    _.set(this.getModalConfirmation, 'items', null)
                    _.set(this.getModalConfirmation, 'action', 'change')
                }
            },
        }
    }
</script>

<style scoped>

</style>