<template>
    <ListHeader v-if="$route.name === 'Clients'"
                :has-filters="true">
        <template v-slot:breadcrumb>
            <div id="breadcrumb">
                <ul>
                    <li>
                        <a class="navigate-router">
                            <span class="breadcrumb-level-1 navigate-to ap2-truncate"
                                  :style="getBulkActions.selectedItems.length > 0 ? 'color: var(--bulkActionsText)' : null">
                                Clients
                            </span>
                        </a>
                    </li>
                </ul>
            </div>
        </template>
        <template v-slot:bulk-actions>
            <div v-if="getBulkActions.origin === 'clients' && getBulkActions.selectedItems.length > 0" class="header-right">
                <ClientsBulkActions/>
            </div>
        </template>
        <template v-slot:filters>
            <div v-if="getBulkActions.selectedItems.length === 0 && getFilterDrawer.filters.length > 0" class="header-right">
                <div>
                    <ul class="filter-pills-wrapper">
                        <li v-for="type in selectedAppTypes"
                            :key="type"
                            :class="type">
                            <span :style="{ 'background-image': 'url(' + getTypeIcon(type) + ')' }"></span>
                            {{ type }}
                            <div @click="resetTypeFilter(type)"
                                 class="close">
                                <svg height="1em" viewBox="0 0 384 512" xmlns="http://www.w3.org/2000/svg">
                                    <path class="svg-fill"
                                          d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"></path>
                                </svg>
                            </div>
                        </li>
                        <li v-if="selectedTeam && selectedTeam !== 'All Teams'">
                            <img :src="usersSolid" class="img-filter-pill">
                            <span></span>{{ selectedTeam }}
                            <div @click="resetTeamFilter(selectedTeam)"
                                 class="close">
                                <svg height="1em" viewBox="0 0 384 512" xmlns="http://www.w3.org/2000/svg">
                                    <path class="svg-fill"
                                          d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"></path>
                                </svg>
                            </div>
                        </li>
                        <li v-if="selectedStatus && selectedStatus !== 'All elements'">
                            <img v-if="selectedStatus === 'Enabled'" :src="play" class="img-filter-pill">
                            <img v-if="selectedStatus === 'Disabled'" :src="pause" class="img-filter-pill">
                            <span></span>{{ selectedStatus }}
                            <div @click="resetStatusFilter(selectedStatus)"
                                 class="close">
                                <svg height="1em" viewBox="0 0 384 512" xmlns="http://www.w3.org/2000/svg">
                                    <path class="svg-fill"
                                          d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"></path>
                                </svg>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </template>
    </ListHeader>
</template>

<script>
    import {mapGetters} from "vuex";
    import ListHeader from "@/components/commons/header/list-header";
    import ClientsBulkActions from "@/components/commons/bulk-actions/clients/index.vue";
    import ellipsisVertical from '@/assets/images/ellipsis-vertical-solid.svg';
    import usersSolid from '@/assets/images/users-solid.svg';
    import pause from '@/assets/images/pause-solid.svg';
    import play from '@/assets/images/play-solid.svg';
    import allElements from '@/assets/images/grid-solid.svg';
    import chevronDownSolid from '@/assets/images/chevron-down-solid.svg';
    import search from '@/assets/images/magnifying-glass-solid.svg';
    import _ from "lodash";
    import google from "@/assets/images/google.svg";
    import facebook from "@/assets/images/facebook.svg";
    import tiktok from "@/assets/images/tiktok.svg";
    import dv360 from "@/assets/images/DV360.svg";
    import twitter from "@/assets/images/twitter-x.svg";
    import system from "@/assets/images/code.svg";

    export default {
        name: 'ClientListHeader',
        components: {
            ListHeader,
            ClientsBulkActions
        },
        computed: {
            ...mapGetters(['getClientTeams', 'getElementsViewMode', 'getBulkActions', 'getSearchTerm', 'getFilterDrawer']),
            typeFilters() {
                let filters = [];
                _.forEach(this.getFilterDrawer.filters, filter => {
                    if (filter.type === 'appType') {
                        filters.push(filter.filter)
                    }
                })
                return filters;
            },
            statusFilter() {
                let status = null;
                _.forEach(this.getFilterDrawer.filters, filter => {
                    if (filter.type === 'clientStatus') {
                        status = filter.filter
                    }
                })
                return status;
            },
            teamFilter() {
                let team = null;
                _.forEach(this.getFilterDrawer.filters, filter => {
                    if (filter.type === 'clientTeam') {
                        team = filter.filter
                    }
                })
                return team;
            },
            showSearch() {
                return this.getFilterDrawer.filters.length === 0;
            },
        },
        data() {
            return {
                selectedAppTypes: [],
                ellipsisVertical: ellipsisVertical,
                usersSolid: usersSolid,
                play: play,
                pause: pause,
                search: search,
                allElements: allElements,
                chevronDownSolid: chevronDownSolid,
                searchTerm: null,
                selectedTeam: null,
                selectedStatus: null
            }
        },
        watch: {
            searchTerm: {
                handler: function (newVal) {
                    this.$store.dispatch('setSearchTerm', {searchTerm: newVal})
                }
            },
            statusFilter: {
                handler: function (newVal) {
                    this.selectedStatus = newVal;
                }
            },
            teamFilter: {
                handler: function (newVal) {
                    this.selectedTeam = newVal;
                }
            },
            typeFilters: {
                handler: function (newVal) {
                    this.selectedAppTypes = newVal;
                }
            }
        },
        methods: {
            resetTypeFilter(type) {
                let filters = this.getFilterDrawer.filters;
                _.remove(filters, function (filter) {
                    return filter.filter === type;
                })
                _.set(this.getFilterDrawer, 'filters', filters)
                _.remove(this.selectedAppTypes, function (selectedAppType) {
                    return selectedAppType === type;
                })
            },
            getTypeIcon(type) {
                let icons = {
                    'Google': google,
                    'Facebook': facebook,
                    'TikTok': tiktok,
                    'DV360':dv360,
                    'Twitter': twitter
                }
                return icons[type]
            },
            openClientsDrawer() {
                _.set(this.getFilterDrawer, 'show', true)
            },
            resetTeamsFilter() {
                this.$store.dispatch('setClientTeam', {clientTeams: 'All Teams'});
            },
            resetStatusFilter(status) {
                let filters = this.getFilterDrawer.filters;
                _.remove(filters, function (filter) {
                    return filter.filter === status;
                })
                _.set(this.getFilterDrawer, 'filters', filters);
                this.selectedStatus = null;
            },
            resetTeamFilter(team) {
                let filters = this.getFilterDrawer.filters;
                _.remove(filters, function (filter) {
                    return filter.filter === team;
                })
                _.set(this.getFilterDrawer, 'filters', filters);
                this.selectedTeam = null;
            },
            resetFilters() {
                _.set(this.getFilterDrawer, 'filters', []);
            }
        }
    }
</script>
