<template>
    <div class="form-group ap2-form-group" style="width: 50%">
        <input
                :value="value"
                :min="min"
                :step="step"
                type="number"
                class="form-control ap2-form-control"
                aria-label="Sizing example input"
                aria-describedby="inputGroup-sizing-sm"
                placeholder="Set a value"
                @wheel="$event.target.blur()"
        >
    </div>
</template>
<script>
    export default {
        name: "InputNumberField",
        props: {
            value: {
                type: [Number, String],
                required: false,
                default: null
            },
            min: {
                type: Number,
                required: false,
                default: 0
            },
            step: {
                type: String,
                required: false,
                default: '.01'
            },
            disabled: {
                type: Boolean,
                required: false,
                default: false
            }
        }
    }
</script>