<template>
    <div id="header"
         class="element"
         :style="getBulkActions.selectedItems.length > 0 ? 'background-color: var(--bulkActionsArea)' : null">
        <slot name="breadcrumb"/>
        <slot name="bulk-actions"/>
        <div v-if="getBulkActions.selectedItems.length === 0"
             class="header-right">
            <div @click="resetFilters()"
                 :class="['search-extensible', getFilterDrawer.filters.length === 0 ? 'active' : null]">
                <input :value="searchTerm"
                       @input="searchTerm=$event.target.value"
                       type="text"
                       :class="['input', getFilterDrawer.filters.length === 0 ? 'active' : null]"
                       placeholder="Search">
                <span v-if="searchTerm"
                      @click="resetSearchTerm()"
                      class="icon"
                      :style="['mask-image: url(' + plusSolid + ')', 'mask-repeat: no-repeat',
                      'mask-position: center', 'mask-size: 11px', 'background-color: var(--overAppContainerColorMedium)',
                      'transform: rotate(45deg)']"></span>
                <span v-else class="icon" :style="'-webkit-mask-image: url(' + search + ')'"></span>
            </div>
            <slot name="filters"/>
            <div v-if="hasFilters"
                 @click="openFiltersDrawer()"
                 class="filter-menu">
                <img :src="ellipsisVertical" alt="tikitiki">
            </div>
            <slot name="actions"/>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";
    import _ from 'lodash';
    import Constants from "@/helpers/constants";
    import ClientsBulkActions from "@/components/commons/bulk-actions/clients/index.vue";
    import ellipsisVertical from '@/assets/images/ellipsis-vertical-solid.svg';
    import usersSolid from '@/assets/images/users-solid.svg';
    import pause from '@/assets/images/pause-solid.svg';
    import play from '@/assets/images/play-solid.svg';
    import allElements from '@/assets/images/grid-solid.svg';
    import chevronDownSolid from '@/assets/images/chevron-down-solid.svg';
    import search from '@/assets/images/magnifying-glass-solid.svg';
    import plusSolid from '@/assets/images/plus-solid.svg';
    import AppsBulkActions from "@/components/commons/bulk-actions/apps";
    import {getFilterDrawer} from "@/store/getters";

    export default {
        name: 'ListHeader',
        props: {
            hasFilters: {
                type: Boolean
            }
        },
        computed: {
            ...mapGetters(['getBulkActions', 'getSearchTerm', 'getFilterDrawer']),
            showSearch() {
                return this.getFilterDrawer.filters.length === 0;
            }
        },
        mounted() {
            this.$store.watch(state => state.searchTerm, () => this.searchTerm = this.getSearchTerm);
        },
        data() {
            return {
                ellipsisVertical: ellipsisVertical,
                usersSolid: usersSolid,
                play: play,
                pause: pause,
                search: search,
                plusSolid: plusSolid,
                allElements: allElements,
                chevronDownSolid: chevronDownSolid,
                searchTerm: null,
            }
        },
        watch: {
            searchTerm: {
                handler: function (newVal) {
                    this.$store.dispatch('setSearchTerm', {searchTerm: newVal})
                }
            }
        },
        methods: {
            openFiltersDrawer() {
                _.set(this.getFilterDrawer, 'show', true)
            },
            resetSearchTerm() {
                this.searchTerm = null;
            },
            resetFilters() {
                _.set(this.getFilterDrawer, 'filters', []);
            }
        }
    }
</script>
