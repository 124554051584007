<template>
    <GeneralBulkActions @dispatch-bulk-action="applyAction"/>
</template>

<script>
import _ from "lodash";
import GeneralBulkActions from "@/components/commons/bulk-actions"
import {mapGetters} from "vuex";
import {getCookie} from "@/helpers/get-cookie";
import {enableElements, disableElements, deleteElements} from "@/services/data-source";


export default {
    name: "DataSourcesBulkActions",
    components: {GeneralBulkActions},
    computed: {
        ...mapGetters(['getBulkActions', 'getEmptySelectedData', 'getConstants', 'getModalConfirmationDataSource', 'getModalConfirmation'])
    },
    methods: {
        applyAction(action) {
            if (action === 'delete') {
                _.set(this.getModalConfirmation, 'origin', 'data-source')
                _.set(this.getModalConfirmation, 'show', true)
                _.set(this.getModalConfirmation, 'items', this.getBulkActions.selectedItems)
                _.set(this.getModalConfirmation, 'action', 'delete')
                // _.set(this.getModalConfirmationDataSource, 'show', true)
                // _.set(this.getModalConfirmationDataSource, 'items', this.getBulkActions.selectedItems)
                // _.set(this.getModalConfirmationDataSource, 'action', 'delete')
                // _.set(this.getModalConfirmationDataSource, 'origin', 'list')
            } else if (action === 'disable') {
                _.set(this.getModalConfirmation, 'origin', 'data-source');
                _.set(this.getModalConfirmation, 'show', true);
                _.set(this.getModalConfirmation, 'items', this.getBulkActions.selectedItems);
                _.set(this.getModalConfirmation, 'action', 'pause')
                // _.set(this.getModalConfirmationDataSource, 'show', true);
                // _.set(this.getModalConfirmationDataSource, 'items', this.getBulkActions.selectedItems);
                // _.set(this.getModalConfirmationDataSource, 'action', 'disable')
                // _.set(this.getModalConfirmationDataSource, 'origin', 'list')
            } else if (action === 'enable') {
                this.enableElements(this.getBulkActions.selectedItems);
            }
            _.set(this.getBulkActions, 'selectedItems', []);
            _.set(this.getEmptySelectedData, 'empty', true);


        },
        async enableElements(elements) {
            let response;
            let elementsIds = elements.map(element => element.id);
            try {
                const formData = new FormData();
                formData.append('ids', JSON.stringify(elementsIds));
                formData.append('csrfmiddlewaretoken', getCookie('csrftoken'));
                response = await enableElements(formData);
            } catch (e) {
                return e;
            }

            if (response.data.status === this.getConstants.RESPONSE_CODES.RESPONSE_CODE_ERROR) {
                this.$toast.open({
                    message: response.data.message,
                    type: 'error',
                    position: 'top-right',
                    duration: 5000,
                });
                return;
            }
            _.set(this.getBulkActions, 'changedItems', elements);
            _.set(this.getBulkActions, 'change', 'enabled');

            this.$toast.open({
                message: response.data.message,
                type: 'success',
                position: 'top-right',
                duration: 5000,
            });
        },
    }
}
</script>

<style scoped>

</style>