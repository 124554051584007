import AxiosWrapper from '@/helpers/axios-wrapper'

/**
 * @returns Promise
 */
export function list() {
    const params = {};
    return AxiosWrapper('/users/list/', {params});
}

/**
 * @returns Promise
 * @param userId
 */
export function detail(userId) {
    const params = {userId: userId};
    return AxiosWrapper('/users/detail/', {params});
}

/**
 * @returns Promise
 */
export function draftDetail() {
    return AxiosWrapper('/users/draft_detail/', {});
}

/**
 * @returns Promise
 */
export function store(formData) {
    return AxiosWrapper('/users/store/', {headers: {"Content-Type": "multipart/form-data"}}, 'POST', formData);
}

/**
 * @returns Promise
 */
export function edit(formData) {
    return AxiosWrapper('/users/edit/', {headers: {"Content-Type": "multipart/form-data"}}, 'POST', formData);
}

/**
 * @returns Promise
 */
export function resetPassword(formData) {
    return AxiosWrapper('/users/reset_password/', {headers: {"Content-Type": "multipart/form-data"}}, 'POST', formData);
}

/**
 * @returns Promise
 */
export function deleteElement(formData) {
    return AxiosWrapper('/users/delete_one/', {headers: {"Content-Type": "multipart/form-data"}}, 'POST', formData);
}

/**
 * @returns Promise
 */
export function deleteElements(formData) {
    return AxiosWrapper('/users/delete_list/', {headers: {"Content-Type": "multipart/form-data"}}, 'POST', formData);
}