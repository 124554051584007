<template>
    <div v-if="this.$route.name === 'Credentials'" id="header" class="element">
        <div id="breadcrumb">
            <ul>
                <li>
                    <span class="breadcrumb-level-1 navigate-to navigate-router ap2-truncate">
                        Credentials
                    </span>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>

    export default {
        name: 'CredentialsHeader',
    }
</script>
