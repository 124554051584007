export function setMultiline() {
    try {
            var multilineElements = document.querySelectorAll('.multilineEllipseText');

            multilineElements.forEach(function (element) {
                var textContainerHeight = element.parentNode.offsetHeight;
                var ellipsisText = element;

                while (ellipsisText.offsetHeight > textContainerHeight) {
                    var text = ellipsisText.textContent;
                    ellipsisText.textContent = text.replace(/\W*\s(\S)*$/, '...');
                }
            });
    } catch (e) {
        console.log('Multiline error', e);
    }

}