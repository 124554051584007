<template>
    <div>
        <div
            @click="setCollapse()"
            :class="['drawer-title-section', 'ap2-toggle-collapse', getCollapseShow ? 'show' : null]"
            data-ap2-target="#tst-options">
            <span class="ap2-toggle-collapse-title">
                {{ name }}
            </span>
            <span class="ap2-toggle-collapse-chevron">
                <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                    <path class="svg-fill"
                          d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"></path>
                </svg>
            </span>
        </div>
        <div v-if="optionsType === 'icon'" id="tst-options" :class="['collapsable-element', getCollapseShow ? 'show' : null]">
            <ul class="input-selector-list has-icon">
                    <li v-for="option in options"
                        @click="setSelectedOptions(option.name)"
                        :key="option.name"
                        class="form-label"
                        :class="[option.name, 'form-label']">
                        <label :class="selectedOptions.indexOf(option.name) !== -1 ? 'selected-option' : null">
                            <span></span>
                            {{ option.name }}
                        </label>
                    </li>
            </ul>
        </div>
    </div>
</template>

<script>

import _ from 'lodash';
export default {
    name: 'multi-toggle-collapse',
    props: {
        name: {
            type: String
        },
        options: {
            type: Array
        },
        optionsType: {
            type: String
        },
        selectedOptions: {
            type: Array
        },
    },
    data() {
        return {
            getCollapseShow: true,
        }
    },
    methods: {
        setSelectedOptions(option) {
            if (_.includes(this.selectedOptions, option)) {
                _.remove(this.selectedOptions, function (selectedOption) {
                    return selectedOption === option;
                })
            } else {
                this.selectedOptions.push(option);
            }
        },
        setCollapse() {
            this.getCollapseShow ? this.getCollapseShow = false : this.getCollapseShow = true
        }
    }
}
</script>