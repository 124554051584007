<template>
    <div v-if="getModalAccounts.show">
        <div
                :class="['ap2-modal ap2-modal-md  ap2-scrollbar ap2-modal-short ap2-modal-scrollable  ap2-modal-scrollable-short', getModalAccounts.show ? 'open': null]"
                id="marketplace-modal">
            <div class="modal-wrapper">
                <div class="ap2-modal-header">
                    <div class="modal-header-title-wrapper">
                        <div class="modal-header-reference">
                            INSIGHTS
                        </div>
                        <div class="modal-header-title mt-2 d-flex align-items-center">
                            <div :class="['rounded-label', getModalAccounts.data.platform.name]">
                                <span></span>
                            </div>
                            <div>
                                Select a {{ getModalAccounts.data.platform.label }} account
                            </div>
                        </div>
                    </div>
                    <div class="modal-header-close close-modal-demo">
                        <svg @click="close()"
                             height="1em"
                             viewBox="0 0 384 512"
                             xmlns="http://www.w3.org/2000/svg">
                            <path class="svg-fill"
                                  d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"/>
                        </svg>
                    </div>
                </div>
                <div class="ap2-modal-content">
                    <div class="modal-actions-header">
                        <div class="pull-right">
                            <div class="search-extensible active">
                                <input :value="searchValue"
                                       @input="searchValue=$event.target.value"
                                       type="text"
                                       class="input active"
                                       placeholder="Search">
                                <span v-if="searchValue"
                                      @click="resetSearchValue()"
                                      class="icon"
                                      :style="['mask-image: url(' + plusSolid + ')', 'mask-repeat: no-repeat',
                      'mask-position: center', 'mask-size: 11px', 'background-color: var(--overAppContainerColorMedium)',
                      'transform: rotate(45deg)']"></span>
                                <span v-else class="icon" :style="'-webkit-mask-image: url(' + search + ')'"></span>
                            </div>
                        </div>
                    </div>
                    <perfect-scrollbar>
                        <div v-if="loading" class="text-center" >
                            <i class="fas fa-spinner fa-spin"></i> Loading {{ getModalAccounts.data.platform.label }}
                            accounts
                        </div>
                        <ul v-if="accounts.length > 0 & !loading" class="input-selector-list has-visible-input">
                            <li v-for="account in filterAccounts"
                                class="form-label"
                                :key="account.id">
                                <input v-model="selectedAccount"
                                       type="checkbox"
                                       :id="account.id"
                                       :disabled="selectedAccount.length === 1 && selectedAccount[0]!== account.name"
                                       :value="account.name">
                                <label :for="account.id"
                                       :class="selectedAccount.length === 1 && selectedAccount[0]!== account.name ? 'disabled' : null">
                                    {{ account.name }}
                                </label>
                            </li>
                        </ul>
                    </perfect-scrollbar>
                </div>
                <div class="ap2-modal-footer modal-controls">
                    <div class="modal-controls">
                        <button @click="goBack()"
                                type="button" class="ap2-btn btn btn-dark close-modal-demo">Back
                        </button>

                        <button @click="goToDataSourceCreation()"
                                type="button" class="ap2-btn btn btn-success close-modal-demo modal-footer-button-link">
                            Create Data Source
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";
    import _ from "lodash";
    import {PerfectScrollbar} from 'vue3-perfect-scrollbar';
    import {accountsByPlatform} from "@/services/platform";
    import plusSolid from '@/assets/images/plus-solid.svg';
    import search from '@/assets/images/magnifying-glass-solid.svg';

    export default {
        name: 'ModalAccounts',
        components: {
            PerfectScrollbar
        },
        data() {
            return {
                page: 0,
                pageSize: 14,
                accounts: [],
                searchValue: null,
                showDropdown: false,
                loading: false,
                selectedAccount: [],
                plusSolid: plusSolid,
                search: search
            }
        },
        computed: {
            ...mapGetters(['getModalAccounts', 'getConstants', 'getModalPlatforms']),
            filterAccounts() {
                let toReturn = this.accounts
                if (this.searchValue) {
                    this.initPage()
                    toReturn = _.filter(this.accounts, account => _.toLower(account.name).includes(_.toLower(this.searchValue)))
                }
                return toReturn
            },
        },
        created() {
            window.addEventListener('keydown', (e) => {
                if (e.key === 'Escape') {
                    this.close();
                }
            });
        },
        mounted() {
            this.$store.watch(state => state.modalAccounts, async (value) => {
                if (value.show === true) {
                    await this.loadAccounts(this.getModalAccounts.data.platform.name);
                }
            }, {deep: true});
        },
        methods: {
            nextPage() {
                if (this.allowNext) {
                    this.page = this.page + 1;
                }
            },
            previousPage() {
                if (this.allowPrevious) {
                    this.page = this.page - 1;
                }
            },
            setPage(page) {
                this.page = page - 1;
            },
            async loadAccounts(platform) {
                this.loading = true;
                let response;
                try {
                    response = await accountsByPlatform(platform);
                } catch (e) {
                    return;
                }
                if (response.data.status === this.getConstants.RESPONSE_CODES.RESPONSE_CODE_ERROR) {
                    this.$toast.open({
                        message: 'Error recovering platform accounts',
                        type: 'error',
                        position: 'top-right',
                        duration: 2000,
                    });
                    return;
                }
                this.accounts = response.data.result
                this.loading = false;
            },
            close() {
                _.set(this.getModalAccounts, 'show', false);
                _.set(this.getModalAccounts, 'data', null);
                this.selectedAccount = [];
            },
            resetSearchValue() {
                this.searchValue = null;
            },
            initPage() {
                this.page = 0;
            },
            goBack() {
                this.close()
                _.set(this.getModalPlatforms, 'show', true);
            },
            goToDataSourceCreation() {
                let platform = this.getModalAccounts.data.platform.name
                let account = _.filter(this.accounts, account => account.name === this.selectedAccount[0]);
                this.selectedAccount = [];
                this.close()
                window.location = '/data-sources/'+ platform + '/new?&accountId=' + account[0].id + '&accountName=' + account[0].name;
            }
        }
    }
</script>
<style lang="scss">
.entity-selector {
    min-height: 100px;
}
</style>
