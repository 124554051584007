import AxiosWrapper from '@/helpers/axios-wrapper'

/**
 * @returns Promise
 */
export function list() {
    const params = {};
    return AxiosWrapper('/insights/platforms/list/', {params});
}

/**
 * @returns Promise
 */
export function accountsByPlatform(platform) {
    const params = {network: platform};
    return AxiosWrapper('/insights/platforms/accounts_list/', {params});
}