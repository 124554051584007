<template>
    <AppPageDrawer v-if="getDrawerAppPage.origin === 'scripts'"
                   :data="allSimpleScripts"
                   title="Scripts"
                   subtitle="Select"
                   @dispatch-save="setSelectedScripts"
                   @dispatch-close-drawer="closeScriptsDrawer">
        <template v-slot:item="{item}">
            <input v-model="scriptsSelected"
                   type="checkbox"
                   :disabled="item.main && mainSelected && !this.scriptsSelected.includes(item)"
                   :id="item.id"
                   :value="item">
            <label :for="item.id" :class="[item.main && mainSelected && !this.scriptsSelected.includes(item) ? 'disabled': null]"> {{item.name}} </label>
        </template>
    </AppPageDrawer>
</template>

<script>
    import {list} from "@/services/script";
    import {mapGetters} from "vuex";
    import _ from "lodash";
    import AppPageDrawer from "@/pages/apps-system/detail/app-page-drawers";

    export default {
        name: "ScriptsDrawer",
        components: {AppPageDrawer},
        computed: {
            ...mapGetters(['getDrawerAppPage', 'getConstants']),
            mainSelected() {
                let isMainSelected = false;
                _.forEach(this.existingScripts, existingScript => {
                    if (existingScript.main) {
                        isMainSelected = true;
                    }
                });
                _.forEach(this.scriptsSelected, script => {
                   if (script.main) {
                       isMainSelected = true;
                   }
                });
                return isMainSelected
            }
        },
        data() {
            return {
                existingScripts: [],
                allScripts: [],
                allSimpleScripts: [],
                scriptsSelected: []
            }
        },
        watch: {
          mainSelected: {
              handler: function (newVal) {
                  if (newVal === true) {
                      this.allSimpleScripts.sort((a,b) => a.main - b.main)
                  }
              }
          }
        },
        mounted() {
            this.$store.watch(state => state.drawerAppPage, async (value) => {
                if (value.origin === 'scripts' && value.show === true) {
                    await this.loadAllScripts();
                }
            }, {deep: true});
        },
        methods: {
            setSelectedScripts() {
                let scriptsToSet = [];
                _.forEach(this.scriptsSelected, script => {
                    scriptsToSet.push(this.allScripts.filter(element => element.id === script.id)[0]);
                })
                _.set(this.getDrawerAppPage, 'result', scriptsToSet);
                this.closeScriptsDrawer()

            },
            getSimpleScripts() {
                _.forEach(this.allScripts, script => {
                    this.allSimpleScripts.push(this.defScriptSimpleObject(script))
                })
            },
            closeScriptsDrawer() {
                this.allScripts = [];
                this.allSimpleScripts = [];
                this.scriptsSelected = [];
                this.existingScripts = [];
                _.set(this.getDrawerAppPage, 'show', false);
                _.set(this.getDrawerAppPage, 'data', {
                    itemsSelected: []
                })
            },
            defScriptSimpleObject(script) {
                return {
                    'id': script.id,
                    'name': script.name,
                    'main': script.main
                }
            },
            async loadAllScripts() {
                let response;
                try {
                    response = await list();
                } catch (e) {
                    return;
                }
                if (response.data.status === this.getConstants.RESPONSE_CODES.RESPONSE_CODE_ERROR) {
                    this.$toast.open({
                        message: 'Error recovering user details',
                        type: 'error',
                        position: 'top-right',
                        duration: 2000,
                    });
                    return;
                }
                _.forEach(response.data.result, script => {
                    if (!_.includes(this.getDrawerAppPage.data.itemsSelected, script.id)) {
                        this.allScripts.push(script);
                    } else {
                        this.existingScripts.push(script);
                    }
                })
                this.getSimpleScripts()
            },
        }
    }
</script>

<style scoped>

</style>