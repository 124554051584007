<template>
    <ListHeader v-if="$route.name === 'Dimensions'" :has-filters="this.getFilterDrawer.origin==='dimensions-imported'">
        <template v-slot:breadcrumb>
            <div id="breadcrumb">
                <ul>
                    <li>
                        <a class="navigate-router">
                            <span class="breadcrumb-level-1 navigate-to ap2-truncate"
                                  :style="getBulkActions.selectedItems.length > 0 ? 'color: var(--bulkActionsText)' : null">
                                Dimensions
                            </span>
                        </a>
                    </li>
                </ul>
            </div>
        </template>
        <template v-slot:bulk-actions>
            <div v-if="getBulkActions.origin === 'dimensions' && getBulkActions.selectedItems.length > 0"
                 class="header-right">
                <DimensionsBulkActions/>
            </div>
        </template>
        <template v-slot:filters>
            <div v-if="getBulkActions.selectedItems.length === 0 && !showSearch" class="header-right">
                <div>
                    <ul class="filter-pills-wrapper">
                        <li v-for="platform in selectedPlatforms"
                            :key="platform.name"
                            :class="platform.name">
                            <span></span>
                            {{ platform.label }}
                            <div @click="resetPlatformFilter(platform)"
                                 class="close">
                                <svg height="1em" viewBox="0 0 384 512" xmlns="http://www.w3.org/2000/svg">
                                    <path class="svg-fill"
                                          d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"></path>
                                </svg>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </template>
    </ListHeader>
</template>

<script>
    import {mapGetters} from "vuex";
    import search from '@/assets/images/magnifying-glass-solid.svg';
    import ListHeader from "@/components/commons/header/list-header";
    import newIcon from "@/assets/images/plus-solid.svg";
    import _ from "lodash";
    import DimensionsBulkActions from "@/components/commons/bulk-actions/dimensions/index.vue";

    export default {
        name: 'DimensionsListHeader',
        components: {
            DimensionsBulkActions,
            ListHeader
        },
        computed: {
            ...mapGetters(['getBulkActions', 'getSearchTerm', 'getModalPlatforms', 'getFilterDrawer']),
            showSearch() {
                return this.getFilterDrawer.filters.length === 0;
            },
            typeFilters() {
                let filters = [];
                _.forEach(this.getFilterDrawer.filters, filter => {
                    if (filter.type === 'appType') {
                        filters.push(filter.filter)
                    }
                })
                return filters;
            },
        },
        data() {
            return {
                searchTerm: null,
                search: search,
                newIcon: newIcon,
                origin: null,
                selectedPlatforms: [],
            }
        },
        watch: {
            searchTerm: {
                handler: function (newVal) {
                    this.$store.dispatch('setSearchTerm', {searchTerm: newVal})
                }
            },
            typeFilters: {
                handler: function (newVal) {
                    this.selectedPlatforms = newVal;
                }
            },
        },
        methods: {
            resetPlatformFilter(platform) {
                let filters = _.filter(_.cloneDeep(this.getFilterDrawer.filters), filter => filter.type !== 'appType' || filter.filter.name !== platform.name);
                _.set(this.getFilterDrawer, 'filters', filters)
                this.selectedPlatforms = _.filter(this.selectedPlatforms, selectedPlatform => selectedPlatform.name !== platform.name);
            },
        }
    }
</script>
