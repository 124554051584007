<template>
    <div v-if="getUser && getFilterDrawer.origin === 'data-share'"
         :class="['ap2-modal', 'ap2-modal-md', 'ap2-modal-is-drawer', getFilterDrawer.show ? 'open' : null]"
         id="drawer-test">
        <div class="modal-wrapper ">
            <div class="ap2-modal-header">
                <div class="modal-header-title-wrapper">
                    <div class="modal-header-reference">
                        Apps
                    </div>
                    <div class="modal-header-title">
                        Filters
                    </div>
                </div>

                <div class="modal-header-close close-modal-demo">
                    <svg @click="closeAppsFiltersDrawer()"
                         height="1em"
                         viewBox="0 0 384 512"
                         xmlns="http://www.w3.org/2000/svg">
                        <path class="svg-fill"
                              d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"/>
                    </svg>
                </div>
            </div>
            <div class="ap2-modal-content">
                <perfect-scrollbar>
                    <toggle-collapse
                            @dispatch-selected-option="changeSelectedStatus"
                            :name="'Filter elements'"
                            :options="getElementsViewModeList()"
                            :options-type="'icon'"
                            :selected-option="selectedStatus"
                    />
                    <multi-toggle-platforms-collapse
                            name="Platforms"
                            :options="getAppsTypes()"
                            :selected-options="selectedTypes"
                    />
                </perfect-scrollbar>
            </div>
            <div class="ap2-modal-footer modal-controls">
                <div class="modal-controls">
                    <button @click="closeAppsFiltersDrawer()"
                            type="button"
                            class="ap2-btn btn btn-dark close-modal-demo">
                        Cancel
                    </button>
                    <button @click="setFilters()"
                            type="button"
                            class="ap2-btn btn btn-success close-modal-demo">
                        Save
                    </button>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";
    import ToggleCollapse from "@/components/commons/toggle-collapse/index.vue";
    import Constants from "@/helpers/constants";
    import enabled from '@/assets/images/play-solid.svg';
    import disabled from '@/assets/images/pause-solid.svg';
    import {getInsightsConstants} from "@/store/getters";
    import _ from 'lodash';
    import {PerfectScrollbar} from 'vue3-perfect-scrollbar';
    import search from '@/assets/images/magnifying-glass-solid.svg';
    import MultiTogglePlatformsCollapse from "@/components/commons/multi-toggle-platforms-collapse/index.vue";
    import {list as PlatformList} from "@/services/platform";

    export default {
        name: 'filter-data-source-drawer',
        components: {MultiTogglePlatformsCollapse, ToggleCollapse, PerfectScrollbar},
        computed: {
            ...mapGetters(['getUser', 'getFilterDrawer', 'getConstants', 'getInsightsConstants']),
            getItemsWithFilter() {
                let items = [];
                if (this.filterItemsText) {
                    _.forEach(this.apps.filter(element => _.toLower(element.name).includes(_.toLower(this.filterItemsText))), item => {
                        items.push(item);
                    })
                } else {
                    _.forEach(this.apps, item => {
                        items.push(item);
                    })
                }
                return items
            },
            typeFilters() {
                let filters = [];
                _.forEach(this.getFilterDrawer.filters, filter => {
                    if (filter.type === 'appType') {
                        filters.push(filter.filter)
                    }
                })
                return filters;
            },
            statusFilter() {
                let status = null;
                _.forEach(this.getFilterDrawer.filters, filter => {
                    if (filter.type === 'appStatus') {
                        status = filter.filter
                    }
                })
                return status;
            },
        },
        watch: {
            statusFilter: {
                handler: function (newVal) {
                    this.selectedStatus = newVal;
                }
            },
            typeFilters: {
                handler: function (newVal) {
                    this.selectedTypes = newVal;
                }
            },
        },
        data() {
            return {
                selectedTypes: [],
                search: search,
                apps: null,
                selectedStatus: null,
                filterItemsText: null,
            }
        },
        created() {
            this.loadPlatforms();
            this.selectedTypes = this.typeFilters;
            this.selectedStatus = this.statusFilter;

        },
        methods: {
            async loadPlatforms() {
                let response;
                try {
                    response = await PlatformList();
                } catch (e) {
                    return;
                }
                if (response.data.status === this.getInsightsConstants.RESPONSE_CODES.RESPONSE_CODE_ERROR) {
                    this.$toast.open({
                        message: 'Error recovering platform list',
                        type: 'error',
                        position: 'top-right',
                        duration: 2000,
                    });
                    return;
                }
                this.platforms = response.data.result;
            },
            getAppsTypes() {
                return this.platforms
            },
            getElementsViewModeList() {
                // let elementsViewMode =  _.forOwn(this.getConstants.ELEMENTS_VIEW_MODE_LIST, (value,) => value);
                return [
                    {'name': 'Enabled', 'icon': enabled},
                    {'name': 'Disabled', 'icon': disabled}
                ]
            },
            closeAppsFiltersDrawer() {
                this.selectedTypes = [];
                this.selectedStatus = null;
                _.set(this.getFilterDrawer, 'show', false)
            },
            changeSelectedStatus(status) {
                this.selectedStatus = status;
            },
            setFilters() {
                let filters = [];
                if (this.selectedTypes.length > 0) {
                    _.forEach(this.selectedTypes, type => {
                        filters.push({'type': 'appType', 'filter': type})
                    })
                }

                if (this.selectedStatus) {
                    filters.push({'type': 'appStatus', 'filter': this.selectedStatus})
                }
                this.selectedStatus = null;
                this.selectedTypes = [];
                _.set(this.getFilterDrawer, 'filters', filters)
                this.$store.dispatch('setSearchTerm', {searchTerm: null})
                this.closeAppsFiltersDrawer();
            },
            showFilterDrawer() {
                return this.getFilterDrawer.origin === 'data-source' || this.getFilterDrawer.origin === 'data-share'
            }
        }
    }
</script>