import Constants from "@/helpers/constants";
import {setBulkActionsDefault, setCurrentObjectDefault, setDetailActionDefault, setFilterDrawerDefault} from "@/store/definitions";

export default {
    searchTerm: null,
    message: '',
    modalClient: {'id': null, 'data': null, 'show': false, 'result': null},
    modalScript: {'id': null, 'data': null, 'show': false, 'result': null},
    modalApp: {'id': null, 'data': null, 'show': false, 'result': null},
    modalAppInClient: {'id': null, 'data': null,'show': false, 'result': null, 'appId': null},
    modalMarketplace: {'id': null, 'data': null, 'show': false, 'result': null},
    modalPlatforms: {'id': null, 'data': null, 'show': false, 'result': null},
    modalTypes: {'id': null, 'data': null, 'show': false, 'result': null},
    modalAccounts: {'id': null, 'data': null, 'show': false, 'result': null},
    modalFieldsGoogleSheets: {'id': null, 'data': null, 'show': false, 'result': null},
    modalFieldsLookerStudio: {'id': null, 'data': null, 'show': false, 'result': null},
    modalAppsFilter: {'id': null, 'data': null, 'show': false, 'result': null},
    modalUser: {'id': null, 'data': null, 'show': false, 'result': null},
    modalTeam: {'id': null, 'data': null, 'show': false, 'result': null},
    modalResetPassword: {'id': null, 'data': null, 'show': false, 'result': null},
    modalOptionsParameters: {'id': null, 'data': null, 'show': false, 'result': null, 'origin': null},
    modalCredentials: false,
    drawerSummary: {'show': false, 'data': null, 'result': null},
    elementsViewMode: Constants.ELEMENTS_VIEW_MODE_ALL,
    clientTeams: Constants.ALL_TEAMS,
    appsFiltered: [],
    currentObject: setCurrentObjectDefault(),
    currentClient: null,
    user: null,
    constants: null,
    insightsConstants: null,
    appPlatform: null,
    adBlock: true,
    sidebarOpen: false,
    savingObject: false,
    bulkActions: setBulkActionsDefault(),
    detailAction: setDetailActionDefault(),
    filterDrawer: setFilterDrawerDefault(),
    changedClients: {'items': [], change: null},
    clientsDrawerOpen: false,
    drawerAppPage: {'data': null, 'show': false, 'result': null, 'origin': null},
    drawerTeamVariables: {'data': null, 'show': false, 'result': null, 'origin': null},
    drawerClientAccounts: {'data': null, 'show': false, 'result': null, 'origin': null},
    drawerAppInClientAccounts: {'data': null, 'show': false, 'result': null, 'origin': null},
    modalOverwrite: {'clientId': null, 'data': null, 'show': false, 'result': null},
    modalConfirmationDataSource: {'show': false, 'items': [], 'result': null, 'action': null, 'origin': null},
    modalConfirmation: {'show': false, 'items': null, 'result': null, 'origin': null, 'destiny': null, 'action': null},
    drawerTeams: {'data': null, 'show': false, 'result': null, 'origin': null},
    drawerUsers: {'data': null, 'show': false, 'result': null, 'origin': null},
    drawerDataSources: {'data': null, 'show': false, 'result': null, 'origin': null},
    drawerDataShares: {'data': null, 'show': false},
    drawerMetrics: {'data': null, 'show': false, 'result': null, 'origin': null},
    drawerDimension: {'data': null, 'show': false, 'result': null, 'origin': null},
    drawerGroupCampaigns: {'data': null, 'show': false, 'result': null, 'origin': null},
    drawerCampaignAdGroups: {'data': null, 'show': false, 'result': null, 'origin': null},
    drawerManageGroups: {'data': null, 'show': false, 'result': null, 'origin': null},
    emptySelectedData: {'empty': false, 'origin': null},
    dataSourceAccount: {},
    loading: false,
    isObjectChanged: false,
}