<template>
    <GeneralBulkActions @dispatch-bulk-action="applyAction($event)"/>
</template>

<script>
    import {getCookie} from "@/helpers/get-cookie";
    import {disableElements, enableElements} from "@/services/app-in-client";
    import _ from "lodash";
    import GeneralBulkActions from "@/components/commons/bulk-actions"
    import {mapGetters} from "vuex";

    export default {
        name: "AllAppsInClientBulkActions",
        components: {GeneralBulkActions},
        computed: {
            ...mapGetters(['getBulkActions', 'getConstants', 'getModalConfirmation'])
        },
        methods: {
            applyAction(action) {
                if (action === 'delete') {
                    _.set(this.getModalConfirmation, 'origin', 'all-apps-in-client');
                    _.set(this.getModalConfirmation, 'show', true);
                    _.set(this.getModalConfirmation, 'items', this.getBulkActions.selectedItems);
                    _.set(this.getModalConfirmation, 'action', 'delete');
                } else if (action === 'disable') {
                    _.set(this.getModalConfirmation, 'origin', 'all-apps-in-client');
                    _.set(this.getModalConfirmation, 'show', true);
                    _.set(this.getModalConfirmation, 'items', this.getBulkActions.selectedItems);
                    _.set(this.getModalConfirmation, 'action', 'pause')
                } else if (action === 'enable') {
                    this.enableElements(this.getBulkActions.selectedItems);
                }
                _.set(this.getBulkActions, 'selectedItems', []);
            },
            async disableElements(elements) {
                let response;
                try {
                    const formData = new FormData();
                    formData.append('ids', JSON.stringify(elements));
                    formData.append('csrfmiddlewaretoken', getCookie('csrftoken'));
                    response = await disableElements(formData);
                } catch (e) {
                    return e;
                }
                if (response.data.status === this.getConstants.RESPONSE_CODES.RESPONSE_CODE_ERROR) {
                    this.$toast.open({
                        message: response.data.message,
                        type: 'error',
                        position: 'top-right',
                        duration: 5000,
                    });
                    return;
                }
                _.set(this.getBulkActions, 'changedItems', elements);
                _.set(this.getBulkActions, 'change', 'disabled');

                this.$toast.open({
                    message: response.data.message,
                    type: 'success',
                    position: 'top-right',
                    duration: 5000,
                });
            },
            async enableElements(elements) {
                let response;
                try {
                    const formData = new FormData();
                    formData.append('ids', JSON.stringify(elements));
                    formData.append('csrfmiddlewaretoken', getCookie('csrftoken'));
                    response = await enableElements(formData);
                } catch (e) {
                    return e;
                }

                if (response.data.status === this.getConstants.RESPONSE_CODES.RESPONSE_CODE_ERROR) {
                    this.$toast.open({
                        message: response.data.message,
                        type: 'error',
                        position: 'top-right',
                        duration: 5000,
                    });
                    return;
                }
                _.set(this.getBulkActions, 'changedItems', elements);
                _.set(this.getBulkActions, 'change', 'enabled');

                this.$toast.open({
                    message: response.data.message,
                    type: 'success',
                    position: 'top-right',
                    duration: 5000,
                });
            },
        }
    }
</script>

<style scoped>

</style>