<template>
    <div :class="['ap2-card app-card-marketplace', app.type]">
        <a :href="'/apps-in-client/new?appId=' + app.id + '&clientId=' + client.id" style="color: black; text-decoration: none;">
            <div class="card-wrapper">
                <div class="card-content"
                     :style="{ 'background-image': 'url(' + app.logos.logo_150_150 + ')' }">
                    <div class="app-card-labels">
                        <div :class="['rounded-label', app.type]" :tooltip="app.type">
                            <span :style="{ 'background-image': 'url(' + getPlatformImage() + ')' }"></span>
                        </div>
                    </div>
                    <img :src="noLogoImage" alt="">
                </div>

                <div class="card-info">
                    <div class="app-card-name ap2-truncate">
                        {{ app.name }}
                    </div>
                    <div class="app-card-description " :tooltip="app.description">
                        <div class="multilineEllipseText">
                            {{ app.description }}
                        </div>
                    </div>
                    <!--                <router-link :to="{name: 'AppInClientNew', params: { view: 'new', appId: app.id }}" class="ap2-btn btn btn-primary">-->
                    <!--                </router-link>-->
                </div>


            </div>
        </a>
    </div>
</template>

<script>
    import _ from "lodash";
    import {mapGetters} from "vuex";
    import noLogoImage from "@/assets/images/clients/no-logo.png";
    import facebookImage from "@/assets/images/facebook.svg";
    import googleImage from "@/assets/images/google.svg";
    import systemImage from "@/assets/images/code.svg";
    import twitterImage from "@/assets/images/twitter-x.svg";
    import tiktokImage from "@/assets/images/tiktok.svg";
    import dv360Image from "@/assets/images/DV360.svg";
    import {setMultiline} from "@/helpers/multiline";

    export default {
        name: "MarketplaceCard",
        props: {
            app: {
                type: Object,
            },
            client: {
                type: Object
            }
        },
        data() {
            return {
                noLogoImage: noLogoImage,
                facebookImage: facebookImage,
                googleImage: googleImage,
                systemImage: systemImage,
                twitterImage: twitterImage,
                tiktokImage: tiktokImage,
                dv360Image: dv360Image,

            }
        },
        computed: {
            ...mapGetters(['getConstants'])
        },
        methods: {
            getPlatformImage() {
                setMultiline();
                if (this.app.type === this.getConstants.APP_TYPE_FACEBOOK) {
                    return facebookImage;
                } else if (this.app.type === this.getConstants.APP_TYPE_GOOGLE) {
                    return googleImage;
                } else if (this.app.type === this.getConstants.APP_TYPE_SYSTEM) {
                    return systemImage;
                } else if (this.app.type === this.getConstants.APP_TYPE_TWITTER) {
                    return twitterImage;
                } else if (this.app.type === this.getConstants.APP_TYPE_TIKTOK) {
                    return tiktokImage;
                } else if (this.app.type === this.getConstants.APP_TYPE_DV360) {
                    return dv360Image;
                }
            }
        }
    }
</script>

<style scoped>

</style>