<template>
    <div :class="['ap2-card app-card-marketplace platforms',platform.status]">
        <div :class="['card-wrapper',platform.status]">
            <div class="card-content">
                <div :class="['rounded-label-platforms', platform.name]">
                    <span></span>
                </div>
            </div>
            <div class="card-info">
                <div class="app-card-name ap2-truncate platforms-text">
                    {{ platform.label }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import _ from "lodash";
    import {mapGetters} from "vuex";

    export default {
        name: "PlatformCard",
        props: {
            platform: {
                type: Object,
            }
        },
        data() {
            return {}
        },
        computed: {
            ...mapGetters(['getConstants'])
        },
        methods: {}
    }
</script>

<style scoped>

</style>