export function setBulkActionsDefault() {
    return {'selectedItems': [], 'allItemsSelected': false, 'pageItemsSelected': false, 'changedItems': [], 'change': null, 'origin': null}
}

export function setDetailActionDefault() {
    return {'action': null, 'origin': null}
}

export function setFilterDrawerDefault() {
    return {'show': false, 'filters': [], 'origin': null}
}

export function setSavingObjectToDefault() {
    return null
}

export function setCurrentObjectDefault() {
    return {'object': null, 'origin': null}
}