import AxiosWrapper from '@/helpers/axios-wrapper'

/**
 * @returns Promise
 */
export function list() {
    const params = {};
    return AxiosWrapper('/insights/datasources/list/', {params});
}

export function list_exclude_inactive() {
    const params = {};
    return AxiosWrapper('/insights/datasources/list_exclude_inactive/', {params});
}

/**
 * @returns Promise
 * @param task
 * @param datasourceId
 */
export function execute(task, datasourceId) {
    const params = {task: task, datasourceId: datasourceId};
    return AxiosWrapper('/insights/datasources/execute/', {params});
}



/**
 * @returns Promise
 * @param id
 */
export function detail(id) {
    const params = {id: id};
    return AxiosWrapper('/insights/datasources/detail/', {params});
}

export function draft_detail(platform) {
    const params = {platform: platform};
    return AxiosWrapper('/insights/datasources/draft_detail/', {params});
}


/**
 * @returns Promise
 */
export function list_platforms() {
    const params = {};
    return AxiosWrapper('/insights/datasources/list_platforms/', {params});
}


/**
 * @returns Promise
 * @param dataSourceId
 * @param action
 */
export function getDataSourceRelatedDataShares(dataSourceId, action) {
    const params = {dataSourceId: dataSourceId, action: action};
    return AxiosWrapper('/insights/datasources/get_related_datashares/', {params});
}


/**
 * @returns Promise
 */
export function deleteElement(formData) {
    return AxiosWrapper('/insights/datasources/delete_one/', {headers: {"Content-Type": "multipart/form-data"}}, 'POST', formData);
}

/**
 * @returns Promise
 */
export function deleteElements(formData) {
    return AxiosWrapper('/insights/datasources/delete_list/', {headers: {"Content-Type": "multipart/form-data"}}, 'POST', formData);
}

/**
 * @returns Promise
 */
export function enableElement(formData) {
    return AxiosWrapper('/insights/datasources/enable_one/', {headers: {"Content-Type": "multipart/form-data"}}, 'POST', formData);
}

/**
 * @returns Promise
 */
export function enableElements(formData) {
    return AxiosWrapper('/insights/datasources/enable_list/', {headers: {"Content-Type": "multipart/form-data"}}, 'POST', formData);
}

/**
 * @returns Promise
 */
export function disableElement(formData) {
    return AxiosWrapper('/insights/datasources/disable_one/', {headers: {"Content-Type": "multipart/form-data"}}, 'POST', formData);
}

/**
 * @returns Promise
 */
export function disableElements(formData) {
    return AxiosWrapper('/insights/datasources/disable_list/', {headers: {"Content-Type": "multipart/form-data"}}, 'POST', formData);
}

