<template>
    <div v-if="getNumberOfSelectedItems > 0"
         class="element" style="background-color: var(--bulkActionsArea)">
        <div class="d-flex flex-row bulk-actions-wrapper"
             style="background-color: var(--bulkActionsArea)">
            <div class="ap2-selection-wrapper">
                <div class="ap2-selection-resume">{{ getNumberOfSelectedItems }} items selected</div>
                <ul class="input-selector-list">
                    <li @click="selectAllItems"
                        class="form-label">
                        <input name="selectors" type="radio" id="select-all" value="select-all">
                        <label for="select-all" class="select-all">Select all items</label>
                    </li>
                    <li @click="selectPageItems"
                        class="form-label">
                        <input name="selectors" type="radio" id="select-items" value="select-page">
                        <label for="select-items" class="select-all">Select page items</label>
                    </li>
                </ul>
            </div>
            <div class="vd"></div>
            <ul class="input-selector-list has-icon">
                <li v-if="actions[this.$route.name].indexOf('Delete') !== -1" @click="setSelectedAction('delete')"
                    class="form-label">
                    <input name="bulk-actions" type="radio" id="bulk-actions-1" value="bulk-actions-1">
                    <label for="bulk-actions-1" class="delete">
                        Delete
                    </label>
                </li>
                <li v-if="actions[this.$route.name].indexOf('Enable') !== -1" @click="setSelectedAction('enable')"
                    class="form-label">
                    <input name="bulk-actions" type="radio" id="bulk-actions-2" value="bulk-actions-2">
                    <label for="bulk-actions-2" class="play">
                        Enable
                    </label>
                </li>
                <li v-if="actions[this.$route.name].indexOf('Disable') !== -1" @click="setSelectedAction('disable')"
                    class="form-label">
                    <input name="bulk-actions" type="radio" id="bulk-actions-3" value="bulk-actions-3">
                    <label for="bulk-actions-3" class="pause">
                        Disable
                    </label>
                </li>
            </ul>
            <button @click="dispatchAction()"
                    class="btn ap2-btn bulk-actions-button">Apply
            </button>
            <svg @click="closeBulkActions()"
                 class="close-bulk"
                 height="1em"
                 viewBox="0 0 384 512"
                 xmlns="http://www.w3.org/2000/svg">
                <path class="svg-fill"
                      d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"/>
            </svg>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";
    import _ from "lodash";
    import Constants from "@/helpers/constants";
    import trash from '@/assets/images/trash-solid.svg';

    export default {
        name: 'GeneralBulkActions',
        computed: {
            getNumberOfSelectedItems() {
                return this.getBulkActions.selectedItems.length
            },
            ...mapGetters(['getBulkActions', 'getConstants', 'getEmptySelectedData'])
        },
        data() {
            return {
                selectedAction: null,
                actions: Constants.BULK_ACTIONS,
                trash: trash
            }
        },
        methods: {
            setSelectedAction(action) {
                this.selectedAction = action;
            },
            selectAllItems() {
                _.set(this.getBulkActions, 'pageItemsSelected', false);
                _.set(this.getBulkActions, 'allItemsSelected', true);

            },
            selectPageItems() {
                _.set(this.getBulkActions, 'allItemsSelected', false);
                _.set(this.getBulkActions, 'pageItemsSelected', true);

            },
            dispatchAction() {
                this.$emit('dispatch-bulk-action', this.selectedAction);
            },
            closeBulkActions() {
                _.set(this.getBulkActions, 'selectedItems', []);
                _.set(this.getEmptySelectedData, 'empty', true);
                _.set(this.getBulkActions, 'allItemsSelected', false);
                _.set(this.getBulkActions, 'pageItemsSelected', false);
            }
        }
    }
</script>

<style scoped>

</style>