<template>
    <div v-if="data" :class="['ap2-modal ap2-modal-md ap2-modal-is-drawer ap2-modal-scrollable', getDrawerSummary.show ? 'open': null]"
         id="overview-modal-drawer">
        <div class="modal-wrapper ">
            <div class="ap2-modal-header">
                <div class="modal-header-title-wrapper">
                    <div class="modal-header-reference">
                        {{ data.app.name }}
                    </div>
                    <div class="modal-header-title">
                        Overview
                    </div>
                </div>
                <div @click="close()" class="modal-header-close close-modal-demo">
                    <svg height="1em" viewBox="0 0 384 512" xmlns="http://www.w3.org/2000/svg">
                        <path class="svg-fill"
                              d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"/>
                    </svg>
                </div>
            </div>
            <div class="ap2-modal-content">
                <perfect-scrollbar :options="{suppressScrollX: true}">
                    <div :class="['app-overview-wrapper', data.status, data.app.type, getClassLastExecution()]">
                        <div class="app-overview-main ">
                            <div>
                                <div class="status" tooltip="Active" flow="right"></div>
                            </div>
                            <div>
                                <div class="thumbnail thumbnail-circle"
                                     :style="{ 'background-image': 'url(' + data.app.logos.logo_150_150 + ')' }"></div>

                            </div>
                            <div class="app-overview-name ap2-truncate">
                                {{ data.app.name }}
                            </div>
                            <div v-if="data.app.is_automatic" class="automatic-app-label">Automatic app</div>
                        </div>
                        <div class="app-overview-description">
                            {{
                                data.app.description
                            }}
                        </div>
                    </div>
                    <div class="drawer-title-section ap2-toggle-collapse show" data-ap2-target="#setup-content">
                    <span class="ap2-toggle-collapse-title ">
                            Set up
                    </span>
                        <span class="ap2-toggle-collapse-chevron">
                    <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                        <path class="svg-fill"
                              d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"></path>
                    </svg>
                    </span>
                    </div>
                    <div id="setup-content" class="collapsable-element show">
                        <div class="row">
                            <div class="col">
                                <div class="at-tree mb-2">
                                    <tree-view
                                            id="my-tree"
                                            :initial-model="data['scheme_tree']"
                                            :model-defaults="modelDefaults"
                                    >
                                        <template v-slot:text="{ model, customClasses }">
                                            <div class="d-flex align-items-center">
                                                <div class="ms-1 mt-1 mb-1">
                                                    <i :class="model.icon"></i>
                                                    <span class="ms-1">{{ model.label }}</span>
                                                </div>
                                            </div>
                                        </template>
                                    </tree-view>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="drawer-title-section ap2-toggle-collapse show" data-ap2-target="#variable-content">
                    <span class="ap2-toggle-collapse-title ">
                            Variables
                            </span>
                        <span class="ap2-toggle-collapse-chevron">
                        <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                            <path class="svg-fill"
                                  d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"></path>
                        </svg>
                    </span>
                    </div>
                    <div v-if="!appInClientAccounts" class="text-center pt-3">
                        <i class="fas fa-spinner fa-spin"></i>
                    </div>
                    <div v-if="appInClientAccounts" id="variable-content" class="collapsable-element show">
                        <div v-for="appInClientAccount in appInClientAccounts" :key="appInClientAccount.id">
                            <div v-for="group in appInClientAccount.groups" :key="group.id">
                                <div class="ap2-table-header">
                                    <div class="variable-detail-wrapper">
                                        <div class="variable-detail-name">
                                            {{ appInClientAccount.account.name }}
                                        </div>
                                        <div class="variable-detail-group">
                                            {{ group.name }}
                                        </div>
                                    </div>
                                </div>
                                <table class="ap2-table is-sortable">
                                    <thead>
                                    <tr class="sticky-top">
                                        <th scope="col" v-for="header in tableConfig.headers" :key="header">
                                            <div class="cell-content-wrapper">
                                                {{ header }}
                                                <div class="ap2-table-sort">
                                                    <div class="arrow-up"></div>
                                                    <div class="arrow-down"></div>
                                                </div>
                                            </div>
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody v-if="group.params.length > 0">
                                    <tr class="" v-for="row in group.params" :key="row.id">
                                        <td>
                                            <text-field
                                                    :value="getPropertyColumnValue(row, getConfig(row).columns.label.property)"
                                            />
                                        </td>
                                        <td>
                                            <text-field
                                                    :value="getPropertyColumnValue(row, getConfig(row).columns.type.property)"
                                            />
                                        </td>
                                        <td>
                                            <list-field
                                                    v-if="paramTypeHelper.isMultipleChoiceParam(getPropertyColumnValue(row, getConfig(row).columns.type.property))"
                                                    :value="getPropertyColumnValue(row, getMainConfig(row).columns.value.property)"
                                            />
                                            <text-field
                                                    v-else-if="paramTypeHelper.isUniqueChoiceParam(getPropertyColumnValue(row, getConfig(row).columns.type.property))"
                                                    :value="getPropertyColumnValue(row, getMainConfig(row).columns.value.property) ? getPropertyColumnValue(row, getMainConfig(row).columns.value.property).name : null"
                                            />
                                            <text-field
                                                    v-else
                                                    :value="getPropertyColumnValue(row, getMainConfig(row).columns.value.property)"
                                            />
                                        </td>

                                    </tr>
                                    </tbody>
                                    <tbody v-else>
                                    <tr>
                                        <td class="text-center text-muted" :colspan="tableConfig.headers.length">
                                            No variables
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </perfect-scrollbar>
            </div>
            <div class="ap2-modal-footer">
                <div class="modal-controls">
                    <button @click="close()" type="button" class="ap2-btn btn btn-dark close-modal-demo">Close
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import TreeView from "@grapoza/vue-tree"
    import TextField from "@/components/commons/table-fields/text";
    import ListField from "@/components/commons/table-fields/list";
    import paramTypeFunctions from "@/helpers/param-type";
    import {mapGetters} from "vuex";
    import _ from "lodash";
    import {PerfectScrollbar} from 'vue3-perfect-scrollbar';
    import {detail as DetailAppInClient} from "@/services/app-in-client";


    export default {
        name: 'DrawerAppInClientSummary',
        components: {
            TreeView,
            TextField,
            ListField,
            PerfectScrollbar
        },
        data() {
            return {
                data: null,
                appInClientAccounts: null,
                modelDefaults: {
                    state: {
                        expanded: true,
                    },
                    customizations: {
                        classes: {
                            treeViewNodeSelfExpandedIndicator: 'fas fa-chevron-right',
                            treeViewNodeSelf: 'large-line',
                            treeViewNodeSelfText: 'big-text'
                        },
                    }
                },
                tableConfig: {
                    appInClientParamConfig: {
                        columns: {
                            'value': {
                                property: 'value',
                            },
                        }
                    },
                    appParamConfig: {
                        columns: {
                            'label': {
                                property: 'app_param.label',
                                columnType: 'input',
                            },
                            'type': {
                                property: 'app_param.type',
                                columnType: 'select',
                            },
                            'value': {
                                property: 'app_param.default_value',
                            },
                        },
                    },
                    accountVariableConfig: {
                        columns: {
                            'label': {
                                property: 'app_param.account_variable.label',
                                columnType: 'text',
                            },
                            'type': {
                                property: 'app_param.account_variable.type',
                                columnType: 'text',
                            },
                            'value': {
                                property: 'app_param.account_variable.default_value',
                            }
                        },
                    },
                    headers: [
                        'Name',
                        'Type',
                        'Value'
                    ],
                },
                paramTypeHelper: paramTypeFunctions
            }
        },
        computed: {
            ...mapGetters(['getDrawerSummary', 'getConstants']),
        },
        mounted() {
            this.$store.watch(state => state.drawerSummary, (value) => {
                if (value.show === true) {
                    this.data = value.data.app_in_client;
                    this.loadAppInClientAccounts();
                }
            }, {deep: true});
        },
        methods: {
            async loadAppInClientAccounts() {
                let response;
                try {
                    response = await DetailAppInClient(this.data.id, this.data.client.id, this.data.app.id);
                } catch (e) {
                    console.log("ERROR: ", e);
                    return;
                }
                this.appInClientAccounts = response.data.result.accounts;
            },
            getClassLastExecution() {
                if (this.data['last_execution']) {
                    return this.getConstants.RESULT_STATUS_OPTIONS[this.data['last_execution']].class;
                }
                return null;
            },
            getConfig(row) {
                if (row['app_param'].account_variable.id !== null) {
                    return this.tableConfig.accountVariableConfig;
                }
                return this.tableConfig.appParamConfig;
            },
            getMainConfig() {
                return this.tableConfig.appInClientParamConfig;
            },
            getDefaultValueProperty(row) {
                if (row['app_param'].account_variable.id !== null && row['app_param']['account_param'] !== undefined && row['app_param']['account_param'] !== null) {
                    return 'app_param.account_param.value';
                }
                return 'app_param.default_value';
            },
            getPropertyColumnValue(row, column) {
                return this.resolve(column, row);
            },
            resolve(path, obj) {
                return path.split('.').reduce(function (prev, curr) {
                    return prev ? prev[curr] : null
                }, obj || this)
            },
            close() {
                this.data = null;
                this.appInClientAccounts = null;
                _.set(this.getDrawerSummary, 'show', false);
            }
        }
    }
</script>