<template>
    <ListHeader v-if="$route.name === 'Teams'"
                :has-filters="false">
        <template v-slot:breadcrumb>
            <div id="breadcrumb">
                <ul>
                    <li>
                        <a class="navigate-router">
                            <span class="breadcrumb-level-1 navigate-to ap2-truncate"
                                  :style="getBulkActions.selectedItems.length > 0 ? 'color: var(--bulkActionsText)' : null">
                                Teams
                            </span>
                        </a>
                    </li>
                </ul>
            </div>
        </template>
        <template v-slot:bulk-actions>
            <div v-if="getBulkActions.origin === 'teams' && getBulkActions.selectedItems.length > 0" class="header-right">
                <TeamsBulkActions/>
            </div>
        </template>
    </ListHeader>
</template>

<script>
    import {mapGetters} from "vuex";
    import search from '@/assets/images/magnifying-glass-solid.svg';
    import ListHeader from "@/components/commons/header/list-header";
    import ellipsisVertical from "@/assets/images/ellipsis-vertical-solid.svg";
    import play from "@/assets/images/play-solid.svg";
    import pause from "@/assets/images/pause-solid.svg";
    import TeamsBulkActions from "@/components/commons/bulk-actions/teams/index.vue";

    export default {
        name: 'TeamsListHeader',
        components: {
            TeamsBulkActions,
            ListHeader
        },
        computed: {
            ...mapGetters(['getFilterDrawer', 'getBulkActions', 'getSearchTerm']),
        },
        data() {
            return {
                searchTerm: null,
                ellipsisVertical: ellipsisVertical,
                search: search,
                play: play,
                pause: pause,
                selectedStatus: null,
                selectedTypes: []
            }
        },
        watch: {
            searchTerm: {
                handler: function (newVal) {
                    this.$store.dispatch('setSearchTerm', {searchTerm: newVal})
                }
            },
        },
    }
</script>
