<template>
    <ModalFields v-if="getModalFieldsLookerStudio.show === true"
                 @dispatch-close-modal="close()"
                 @dispatch-save-fields="saveFields($event)"
                 :modal-data="getModalFieldsLookerStudio.data"
                 :ref="'modalFields'">
        <template v-slot:field-list="{scope}">
            <li v-for="field in $refs.modalFields.filteredFields[scope.id].fields"
                :key="field.id"
                :class="[isFieldDisabled(field) ? 'disabled': null]">
                <input
                        type="checkbox"
                        :id="'check-'+field.id"
                        :name="'check-'+field.id"
                        @input="changeSelectField(scope.id, field)"
                        :checked="isFieldSelected(scope.id, field)"
                >
                <label :for="'check-'+field.id"
                       :class="['entity-icon', scope.icon_class]">
                    {{ field.label }}
                </label>
                <div class="to-right">
                        <span class="field-unit-type">
                            <span :class="['field-unit-type_icon', field.datatype]"></span>
                            {{ field.datatype }}
                        </span>
                    <span class="info-icon"></span>
                </div>
            </li>
        </template>
    </ModalFields>
</template>
<script>

import ModalFields from "@/components/modal/modal-fields/index.vue";
import {mapGetters} from "vuex";
import _ from "lodash";
import {getFieldsDependenciesForField} from "@/services/field-scope";
import {getModalFieldsLookerStudio} from "@/store/getters";

export default {
    name: 'LookerStudioDataShareModalFields',
    components: {ModalFields},
    data() {
        return {}
    },
    computed: {
        ...mapGetters(['getModalFieldsLookerStudio', 'getInsightsConstants', 'getCurrentObject']),
    },
    created() {
        window.addEventListener('keydown', (e) => {
            if (e.key === 'Escape') {
                this.close();
            }
        });
    },
    methods: {
        async getFieldsDependencies(field) {
            let response;
            try {
                response = await getFieldsDependenciesForField(field.id);
            } catch (e) {
                return;
            }
            if (response.data.status === this.getInsightsConstants.RESPONSE_CODES.RESPONSE_CODE_ERROR) {
                this.$toast.open({
                    message: 'Error recovering fields dependencies',
                    type: 'error',
                    position: 'top-right',
                    duration: 2000,
                });
                return;
            }
            field.fields_dependencies = response.data.result
        },
        async changeSelectField(scopeId, field) {
            if (this.isFieldSelected(scopeId, field)) {
                this.unSelectField(field.name, scopeId);
            } else {
                this.$refs.modalFields.fieldsByScope[scopeId].selected.push(field);
                if (field.has_dependencies) {
                    if (!field.hasOwnProperty('fields_dependencies')) {
                        await this.getFieldsDependencies(field)
                    }
                    _.forEach(field.fields_dependencies, (field_dependencies, type) => {
                        _.forEach(field_dependencies, field_dependency => {
                            if (!this.isFieldSelected(field_dependency.field_scope.id, field_dependency)) {
                                this.changeSelectField(field_dependency.field_scope.id, field_dependency);
                            }
                        })
                    })
                }
            }
        },
        isFieldSelected(scopeId, field) {
            let element = _.find(this.$refs.modalFields.fieldsByScope[scopeId].selected, fieldSelected => fieldSelected.name === field.name)
            return element !== undefined;
        },
        close() {
            _.set(this.getModalFieldsLookerStudio, 'show', false);
        },
        saveFields(fieldsByScope) {
            _.set(this.getModalFieldsLookerStudio, 'show', false);
            _.set(this.getModalFieldsLookerStudio, 'data', fieldsByScope);
        },
        unSelectField(fieldName, scopeId) {
            this.$refs.modalFields.fieldsByScope[scopeId].selected = _.filter(this.$refs.modalFields.fieldsByScope[scopeId].selected, field => field.name !== fieldName);
        },
        isFieldDisabled(field) {
            return this.$route.name.includes('Edit') && field.type === 'Dimension'
        }
    }
}
</script>
<style scoped>

</style>