<template>
    <div class="form-group ap2-form-group" style="width: 50%">
        <div class=" left position-relative">
            <button
                    aria-expanded="true"
                    class="btn dropdown-toggle form-control ap2-form-control "
                    data-bs-toggle="dropdown"
                    type="button"
                    :value="selected ? selected.name : defaultPrint"
                    :disabled="disabled"
            >
                <span class="ap2-truncate">{{ selected ? selected.name : defaultPrint }}</span>
                <span class="chevron"
                      :style="'-webkit-mask-image: url(' + chevron + ')'"></span>
            </button>
            <ul aria-labelledby="btnGroupDrop1" class="dropdown-menu dropdown-menu-left ">
                <li @click="dispatchChange(option)" class="form-label" v-for="option in options" :key="option.name"
                    data-popper-placement="bottom-start">
                    <a class="dropdown-item ap2-dropdown-item ">
                        <label class="brand-icon">{{ option.name }}</label>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
    import chevron from "@/assets/images/chevron-down-solid.svg"

    export default {
        name: "SelectField",
        props: {
            options: {
                type: Array,
                required: true,
            },
            value: {
                type: Object,
                required: false,
                default: null,
            },
            defaultPrint: {
                type: String,
                required: true
            },
            disabled: {
                type: Boolean,
                required: false,
                default: false
            },
        },
        data() {
            return {
                selected: this.value
                        ? this.value
                        : null,
                chevron: chevron
            }
        },
        watch: {
            value: {
                handler: 'valueChange',
                immediate: true,
            }
        },
        methods: {
            isActive(item) {
                if (!this.selected)
                    return 'dropdown-item';
                return item.name === this.selected.name ? 'dropdown-item active' : 'dropdown-item';
            },
            dispatchChange(option) {
                this.selected = option;
                this.$emit('dispatch-change', option);
            },
            valueChange(value) {
                this.selected = value;
            }
        }
    }
</script>