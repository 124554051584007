<template>
    <div>
        <ul>
            <li v-for="val in getValue" :key="val">
                {{ val.name }}
            </li>
        </ul>
    </div>
</template>

<script>
    export default {
        name: "ListField",
        props: {
            value: {
                type: Array
            },
        },
        computed: {
            getValue() {
                return this.value ?? [];
            }
        }
    }
</script>

<style scoped>

</style>