<template>
    <AppPageDrawer v-if="getDrawerAppPage.origin === 'accountVariables'"
                   :data="allAccountVariables"
                   title="Account Variables"
                   subtitle="Select"
                   @dispatch-save="setSelectedAccountVariables"
                   @dispatch-close-drawer="closeAccountVariablesDrawer">
        <template v-slot:item="{item}">
            <input v-model="accountVariablesSelected"
                   type="checkbox"
                   :id="item.id"
                   :value="item">
            <label :for="item.id"> {{ item.label }} </label>
        </template>
    </AppPageDrawer>
</template>

<script>
    import {list} from "@/services/account-variable";
    import {mapGetters} from "vuex";
    import _ from "lodash";
    import AppPageDrawer from "@/pages/apps-system/detail/app-page-drawers";

    export default {
        name: "AccountVariablesDrawer",
        components: {AppPageDrawer},
        computed: {
            ...mapGetters(['getDrawerAppPage', 'getConstants']),
        },
        data() {
            return {
                allAccountVariables: [],
                accountVariablesSelected: [],
            }
        },
        mounted() {
            this.$store.watch(state => state.drawerAppPage, async (value) => {
                if (value.origin === 'accountVariables' && value.show === true) {
                    await this.loadAccountVariables();
                }
            }, {deep: true});
        },
        methods: {
            closeAccountVariablesDrawer() {
                this.allAccountVariables = [];
                this.accountVariablesSelected = [];
                _.set(this.getDrawerAppPage, 'data', {
                    itemsSelected: []
                })
                _.set(this.getDrawerAppPage, 'show', false);
            },
            setSelectedAccountVariables() {
                _.set(this.getDrawerAppPage, 'result', this.accountVariablesSelected);
                this.closeAccountVariablesDrawer()
            },
            async loadAccountVariables() {
                let response;
                try {
                    response = await list();
                } catch (e) {
                    return;
                }
                if (response.data.status === this.getConstants.RESPONSE_CODES.RESPONSE_CODE_ERROR) {
                    this.$toast.open({
                        message: 'Error recovering user details',
                        type: 'error',
                        position: 'top-right',
                        duration: 2000,
                    });
                    return;
                }
                _.forEach(response.data.result, accountVariable => {
                    if (!_.includes(this.getDrawerAppPage.data.itemsSelected, accountVariable.id)) {
                        this.allAccountVariables.push(accountVariable);
                    }
                })
            },
        }
    }
</script>

<style scoped>

</style>