import AxiosWrapper from '@/helpers/axios-wrapper'

/**
 * @returns Promise
 * @param id
 */
export function detail(id) {
    const params = {id: id};
    return AxiosWrapper('/insights/datashares/detail/', {params});
}

/**
 * @returns Promise
 * @param task
 * @param datashareId
 * @param datasourceId
 */
export function execute(task, datasourceId, datashareId) {
    const params = {task: task, datasourceId: datasourceId, datashareId: datashareId};
    return AxiosWrapper('/insights/datashares/execute/', {params});
}

export function list_datashare_types() {
    const params = {};
    return AxiosWrapper('/insights/datashares/list_datashare_types/', {params});
}

export function draft_detail(typeId) {
    const params = {typeId: typeId};
    return AxiosWrapper('/insights/datashares/draft_detail/', {params});
}

/**
 * @returns Promise
 */

export function getProblematicDataSharesEnabling(formData) {
    return AxiosWrapper('/insights/datashares/problematic_enabling/', {headers: {"Content-Type": "multipart/form-data"}}, 'POST', formData);
}
