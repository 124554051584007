import AxiosWrapper from "@/helpers/axios-wrapper";

/**
* @returns Promise
 */
export function getInsightsConstantsData() {
    const params = {};
    return AxiosWrapper('/get_insights_constants_data/', {params});
}

export function getCurrencies() {
    const params = {};
    return AxiosWrapper('/get_currencies/', {params});
}
