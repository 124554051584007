import {createWebHistory, createRouter, createWebHashHistory} from "vue-router";
import AxiosWrapper from "@/helpers/axios-wrapper";
import _ from 'lodash';
import store from '@/store';
import {
    setBulkActionsDefault,
    setFilterDrawerDefault,
    setSavingObjectToDefault
} from "@/store/definitions";

const constants = Object.freeze({
    RESPONSE_CODE_SUCCESS: 0,
    ROLE_USER: 'User',
    ROLE_ADMIN: 'Admin',
})


const routes = [
    {
        path: '/',
        redirect: {name: 'Clients'},
        meta: {excludeRoles: []}
    },
    {
        path: '/apps-in-client',
        name: 'AppsInClient',
        component: () => import('@/pages/apps-in-client/list/index.vue'),
        meta: {excludeRoles: []}
    },
    {
        path: '/apps-in-client/new',
        name: 'AppInClientNew',
        component: () => import('@/pages/apps-in-client/detail/index.vue'),
        // props: true,
        meta: {excludeRoles: []}
    },
    {
        path: '/apps-in-client/edit',
        name: 'AppInClientEdit',
        component: () => import('@/pages/apps-in-client/detail/index.vue'),
        // props: true,
        meta: {excludeRoles: []}
    },
    {

        path: '/all-apps-client',
        name: 'AllAppsInClient',
        component: () => import('@/pages/all-apps-in-client/list'),
        meta: {excludeRoles: [constants.ROLE_USER, constants.ROLE_ADMIN]}
    },
    {
        path: '/apps-system/list',
        name: 'AppsSystem',
        component: () => import('@/pages/apps-system/list'),
        meta: {excludeRoles: [constants.ROLE_USER, constants.ROLE_ADMIN]}
    },
    {
        path: '/apps-system/new',
        name: 'AppsSystemNew',
        component: () => import('@/pages/apps-system/detail'),
        meta: {excludeRoles: [constants.ROLE_USER, constants.ROLE_ADMIN]}
    },
    {
        path: '/apps-system/edit',
        name: 'AppsSystemEdit',
        component: () => import('@/pages/apps-system/detail'),
        meta: {excludeRoles: [constants.ROLE_USER, constants.ROLE_ADMIN]}
    },
    {
        path: '/teams',
        name: 'Teams',
        component: () => import('@/pages/teams/list/index.vue'),
        meta: {excludeRoles: [constants.ROLE_USER]}
    },
    {
        path: '/clients',
        name: 'Clients',
        component: () => import('@/pages/clients/list/index.vue'),
        meta: {excludeRoles: []}
    },
    {
        path: '/clients/new',
        name: 'ClientsNew',
        // props: { mode: 'new' },
        component: () => import('@/pages/clients/detail/index.vue'),
        meta: {excludeRoles: []}
    },
    {
        path: '/clients/edit',
        name: 'ClientsEdit',
        // props: { mode: 'edit' },
        component: () => import('@/pages/clients/detail/index.vue'),
        meta: {excludeRoles: []}
    },
    {
        path: '/users',
        name: 'Users',
        component: () => import('@/pages/users/list/index.vue'),
        meta: {excludeRoles: [constants.ROLE_USER]}
    },
    {
        path: '/data-sources',
        name: 'DataSources',
        component: () => import('@/pages/data-sources/list/index.vue'),
        meta: {excludeRoles: []}
    },
    {
        path: '/data-sources/facebook/new',
        name: 'FacebookDataSourceNew',
        component: () => import('@/pages/data-sources/detail/facebook/index.vue'),
        meta: {excludeRoles: []}
    },
    {
        path: '/data-sources/google-ads/new',
        name: 'GoogleAdsDataSourceNew',
        component: () => import('@/pages/data-sources/detail/google-ads/index.vue'),
        meta: {excludeRoles: []}
    },
    {
        path: '/data-sources/facebook/edit',
        name: 'FacebookDataSourceEdit',
        component: () => import('@/pages/data-sources/detail/facebook/index.vue'),
        meta: {excludeRoles: []}
    },
    {
        path: '/data-sources/google-ads/edit',
        name: 'GoogleAdsDataSourceEdit',
        component: () => import('@/pages/data-sources/detail/google-ads/index.vue'),
        meta: {excludeRoles: []}
    },
    {
        path: '/metrics',
        name: 'Metrics',
        component: () => import('@/pages/metrics/list/index.vue'),
        meta: {excludeRoles: []}
    },
    {
        path: '/metrics/new',
        name: 'MetricsNew',
        component: () => import('@/pages/metrics/detail/index.vue'),
        meta: {excludeRoles: []}
    },
    {
        path: '/metrics/edit',
        name: 'MetricsEdit',
        component: () => import('@/pages/metrics/detail/index.vue'),
        meta: {excludeRoles: []}
    },
    {
        path: '/dimensions',
        name: 'Dimensions',
        component: () => import('@/pages/dimensions/list/index.vue'),
        meta: {excludeRoles: []}
    },
    {
        path: '/dimensions/new',
        name: 'DimensionsNew',
        component: () => import('@/pages/dimensions/detail/index.vue'),
        meta: {excludeRoles: []}
    },
    {
        path: '/dimensions/edit',
        name: 'DimensionsEdit',
        component: () => import('@/pages/dimensions/detail/index.vue'),
        meta: {excludeRoles: []}
    },
    {
        path: '/data-shares',
        name: 'DataShares',
        component: () => import('@/pages/data-shares/list/index.vue'),
        meta: {excludeRoles: []}
    },
    {
        path: '/data-shares/google-looker-studio/new',
        name: 'GoogleLookerStudioDataShareNew',
        // props: { mode: 'new' },
        component: () => import('@/pages/data-shares/detail/looker-studio/index.vue'),
        meta: {excludeRoles: []}
    },
    {
        path: '/data-shares/google-sheets/new',
        name: 'GoogleSheetsDataShareNew',
        // props: { mode: 'new' },
        component: () => import('@/pages/data-shares/detail/google-sheets/index.vue'),
        meta: {excludeRoles: []}
    },
    {
        path: '/data-shares/google-looker-studio/edit',
        name: 'GoogleLookerStudioDataShareEdit',
        // props: { mode: 'new' },
        component: () => import('@/pages/data-shares/detail/looker-studio/index.vue'),
        meta: {excludeRoles: []}
    },
    {
        path: '/data-shares/google-sheets/edit',
        name: 'GoogleSheetsDataShareEdit',
        // props: { mode: 'new' },
        component: () => import('@/pages/data-shares/detail/google-sheets/index.vue'),
        meta: {excludeRoles: []}
    },
    {
        path: '/users/new',
        name: 'UsersNew',
        // props: { mode: 'new' },
        component: () => import('@/pages/users/detail/index.vue'),
        meta: {excludeRoles: []}
    },
    {
        path: '/users/edit',
        name: 'UsersEdit',
        // props: { mode: 'edit' },
        component: () => import('@/pages/users/detail/index.vue'),
        meta: {excludeRoles: []}
    },
    {
        path: '/teams/new',
        name: 'TeamsNew',
        // props: { mode: 'new' },
        component: () => import('@/pages/teams/detail/index.vue'),
        meta: {excludeRoles: []}
    },
    {
        path: '/teams/edit',
        name: 'TeamsEdit',
        // props: { mode: 'edit' },
        component: () => import('@/pages/teams/detail/index.vue'),
        meta: {excludeRoles: []}
    },
    {
        path: '/credentials',
        name: 'Credentials',
        component: () => import('@/pages/credentials/index.vue'),
        meta: {excludeRoles: []}
    },
    {
        path: '/scripts',
        name: 'Scripts',
        component: () => import('@/pages/scripts/list/index.vue'),
        meta: {excludeRoles: [constants.ROLE_USER, constants.ROLE_ADMIN]}
    },
    {
        path: '/scripts/new',
        name: 'ScriptNew',
        component: () => import('@/pages/scripts/detail/index.vue'),
        meta: {excludeRoles: [constants.ROLE_USER, constants.ROLE_ADMIN]}
    },
    {
        path: '/scripts/edit',
        name: 'ScriptEdit',
        component: () => import('@/pages/scripts/detail/index.vue'),
        meta: {excludeRoles: [constants.ROLE_USER, constants.ROLE_ADMIN]}
    },
    {
        path: '/login/',
        name: 'Login',
        component: () => import('@/pages/login'),
        meta: {excludeRoles: []}
    },
    {
        path: '/help/crontab',
        name: 'CrontabHelp',
        component: () => import('@/pages/CrontabHelp'),
        meta: {excludeRoles: []}
    },
    {
        path: '/admin',
        name: 'Admin',
        beforeEnter() {
            location.href = 'admin/'
        }
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});


router.beforeEach((to, from, next) => {
    if (to.path === '/login/' || to.path === '/login') {
        next()
    } else {
        AxiosWrapper('/users/session/user/', {})
            .then(function (response) {
                if (response.data.status === constants.RESPONSE_CODE_SUCCESS) {
                    if (_.includes(to.meta.excludeRoles, response.data.result.role)) {
                        next('/');
                    } else {
                        console.log("Estoy en el router link: ", store.state, from.path, to.path);
                        if (from.path !== to.path) {
                            store.state.bulkActions = setBulkActionsDefault()
                            store.state.filterDrawer = setFilterDrawerDefault()
                            store.state.savingObject = setSavingObjectToDefault()
                        }
                        next();
                    }
                } else {
                    next('/login/')
                }
            })
            .catch(function (error) {
                console.log("Error: ", error)
                next('/login/')
            });
    }
});


export default router;