<template>
    <div>
        <ModalClient />
        <ModalMarketplace />
        <ModalAppsFilter />
        <ModalScript />
        <ModalUser />
        <ModalApp />
        <ModalPlatforms/>
        <ModalDataShareType/>
        <ModalAccounts/>
        <ModalTeam />
        <ModalResetPassword />
        <ModalOptionsParameters />
        <ModalOverwriteVariables/>
        <ModalConfirmationDataSource/>
        <ModalConfirmation/>
        <LookerStudioDataShareModalFields/>
        <GoogleSheetDataShareModalFields/>
    </div>
</template>

<script>
    import ModalClient from '@/components/modal/modal-client'
    import ModalMarketplace from '@/components/modal/modal-marketplace'
    import ModalAppsFilter from '@/components/modal/modal-apps-filter'
    import ModalScript from '@/components/modal/modal-script'
    import ModalApp from '@/components/modal/modal-app'
    // import ModalAppInClient from '@/components/modal/modal-app-in-client'
    import ModalUser from "@/components/modal/modal-user";
    import ModalTeam from "@/components/modal/modal-team";
    import ModalResetPassword from "@/components/modal/modal-reset-password";
    import ModalOptionsParameters from "@/components/modal/modal-options-parameters";
    import ModalOverwriteVariables from "@/components/modal/overwrite-variables/index.vue";
    import ModalConfirmation from "@/components/modal/modal-confirmation";
    import ModalPlatforms from "@/components/modal/modal-platforms/index.vue";
    import ModalAccounts from "@/components/modal/modal-accounts/index.vue";
    import ModalDataShareType from "@/components/modal/modal-datashare-type/index.vue";
    import ModalConfirmationDataSource from "@/components/modal/modal-confirmation-datasource/index.vue";
    import LookerStudioDataShareModalFields from "@/components/modal/modal-fields/looker-studio-datashare/index.vue";
    import GoogleSheetDataShareModalFields from "@/components/modal/modal-fields/google-sheet-datashare/index.vue";

    export default {
        name: 'Modal',
        components: {
            GoogleSheetDataShareModalFields,
            LookerStudioDataShareModalFields,
            ModalConfirmationDataSource,
            ModalDataShareType,
            ModalAccounts,
            ModalPlatforms,
            ModalConfirmation,
            ModalOverwriteVariables,
            ModalOptionsParameters,
            ModalResetPassword,
            ModalTeam,
            ModalUser,
            ModalClient,
            ModalMarketplace,
            ModalAppsFilter,
            ModalScript,
            ModalApp,
            // ModalAppInClient,
        },
    }
</script>
