import AxiosWrapper from '@/helpers/axios-wrapper'

/**
 * @returns Promise
 * @param id
 * @param app
 * @param account
 */
export function detail(id=null, app, account) {
    const params = {id: id, app: app, account: account};
    return AxiosWrapper('/apps_client/group/detail/', {params});
}
