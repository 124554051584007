<template>
    <div :class="['align-items-center', textCenter ? 'text-center': null, 'ap2-truncate']">
        {{ value }}
         <span v-if="isMandatory" style="color:red">*</span>
    </div>
</template>

<script>
    export default {
        name: "TextField",
        props: {
            value: {
                type: [String, Number],
                required: false,
                default: null
            },
            isMandatory: {
                type: Boolean,
                required: false,
                default: false
            },
            textCenter: {
                type: Boolean,
                required: false,
                default: false
            }
        }
    }
</script>