<template>
    <div v-if="getInsightsConstants"
         :class="['ap2-modal ap2-modal-md ap2-modal-content-cols ap2-scrollbar ap2-modal-large ap2-modal-scrollable  ap2-modal-scrollable-short', 'open']"
         id="fields-modal">
        <div class="modal-wrapper ">
            <div class="ap2-modal-header">
                <div class="modal-header-title-wrapper">
                    <div class="modal-header-reference">
                        DATASHARE
                    </div>
                    <div class="modal-header-title">
                        Choose fields
                    </div>
                </div>
                <div class="modal-header-close close-modal-demo" @click="close()">
                    <svg height="1em" viewBox="0 0 384 512" xmlns="http://www.w3.org/2000/svg">
                        <path class="svg-fill"
                              d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"/>
                    </svg>
                </div>
            </div>
            <div class="ap2-modal-content ">
                <div class="modal-block fields" id="ps-fields">
                    <div class="modal-actions-header-fields sticky-top">
                        <div class="pull-right">
                            <div :class="['search-extensible', 'sticky-top', selectedTypes.length === 0 ? 'active': null]"
                                 @click="resetFilters()">
                                <input :value="searchValue"
                                       @input="searchValue=$event.target.value"
                                       type="text"
                                       :class="['input', selectedTypes.length === 0 ? 'active' : null]"
                                       placeholder="Search">
                                <span v-if="searchValue"
                                      @click="resetSearchValue()"
                                      class="icon"></span>
                                <span v-else class="icon"></span>
                            </div>
                        </div>
                    </div>
                    <div v-for="scope in scopes"
                         :key="scope.id"
                         :class="['entity-wrapper', isDisabledScope(scope) ? 'disabled': null]">
                        <div :class="['entity-header sticky-margin sticky-top  ap2-toggle-collapse-entity', isScopeOpened(scope) ? 'show' : null]"
                             data-ap2-target-entity="#fields1">
                            <div class="entity-row">
                                <ul class="entity-item">
                                    <li class="entity-name">
                                        <span class="select-child"></span>
                                        <span :class="['entity-icon', scope.icon_class]"></span>
                                        {{ scope.label }}
                                        <span class="selected-badge">
                                        {{
                                                isDisabledScope(scope) ? 'COMING SOON' : fieldsByScope[scope.id].selected.length
                                            }}
                                    </span>
                                    </li>
                                </ul>
                            </div>
                            <div class="entity-row entity-header-controls ap2-form-inline">
                                <div class="entity-header-actions">
                                    <div @click="collapseScope(scope)"
                                         :class="['rounded-control ap2-toggle-collapse-entity ', isScopeOpened(scope) ? 'show' : null]"
                                         data-ap2-target-entity="#test2"
                                         tooltip="Details">
                                        <div class="collapse-toggle"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div :class="['entity-detail-wrapper', isScopeOpened(scope) ? 'show' : null]"
                             id="fields1">
                            <ul v-if="fieldsByScope[scope.id].loading" class="fields-list">
                                <li
                                        class="text-center">
                                    <i class="fas fa-spinner fa-spin"></i> Loading {{ scope.label }}
                                    fields
                                </li>
                            </ul>
                            <ul v-else-if="!hasData[scope.id]" class="fields-list">
                                <li
                                        class="text-center text-muted">
                                    No Data
                                </li>
                            </ul>
                            <ul v-else class="fields-list">
                                <slot name="field-list" v-bind:scope="scope"/>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="modal-block fields-selected" id="ps-fields-selected">
                    <div class="modal-actions-header">
                        <h4>
                            Selected fields
                        </h4>
                        <div class="pull-right">
                            <div class="header-options">
                            </div>
                        </div>
                    </div>
                    <div class="entity-wrapper">
                        <div class="entity-detail-wrapper show" id="fields20">
                            <ul class="fields-list" v-for="(fields, key) in fieldsByScope"
                                :key="key">
                                <li v-for="field in fields.selected"
                                    :key="field.id"
                                    :class="[isFieldDisabled(field) ? 'disabled': null]">
                                    <input type="checkbox" id="check-20-1" name="check-20-1">
                                    <label for="check-20-1" :class="['entity-icon', field.field_scope.icon_class]">
                                        {{ field.label }}
                                    </label>
                                    <div class="to-right">
                                    <span class="field-unit-type">
                                        <span :class="['field-unit-type_icon', field.datatype]"></span>
                                        {{ field.datatype }}
                                    </span>
                                        <span class="info-icon"></span>
                                        <span @click="unSelectField(field.name, key)"
                                              class="trash-icon"></span>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="ap2-modal-footer modal-controls">
                <div class="modal-controls">
                    <button @click="close()" type="button" class="ap2-btn btn btn-dark close-modal-demo">Cancel
                    </button>
                    <button v-if="!isSaveButtonDisabled()" @click="saveFields()" type="button"
                            class="ap2-btn btn btn-success close-modal-demo">
                        Use these fields
                    </button>
                    <Popper v-else
                            class="light"
                            arrow
                            placement="top"
                            :show="isPopperActive()">
                        <div @mouseover="addShowPopper()"
                             @mouseleave="removeShowPopper()">
                            <button type="button" class="ap2-btn btn btn-success close-modal-demo ms-2"
                                    :disabled="isSaveButtonDisabled()">
                                Use these fields
                            </button>
                        </div>
                        <template #content>
                            <p><b>Fields in this DataShare cannot be edited</b></p>
                        </template>
                    </Popper>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import _ from "lodash";
import Popper from "vue3-popper";
import {list, getFieldsByScope} from "@/services/field-scope";

export default {
    name: 'ModalFields',
    components: {Popper},
    props: {
        modalData: {
            type: Object
        }
    },
    data() {
        return {
            accounts: [],
            searchValue: null,
            showDropdown: false,
            loading: false,
            selectedAccount: [],
            filteredFields: {},
            selectedTypes: [],
            selectedInDropdown: [],
            fieldsByScope: {},
            scopes: [],
            scopesOpened: [],
            hasData: {},
            popper: false
        }
    },
    computed: {
        ...mapGetters(['getConstants', 'getCurrentObject', 'getInsightsConstants']),
    },
    watch: {
        searchValue: {
            handler: function (newVal) {
                if (Object.entries(this.hasData).length !== 0) {
                    this.makeFiltration(newVal);
                }
            }
        },
    },
    created() {
        this.loadScopes(this.modalData);
        window.addEventListener('keydown', (e) => {
            if (e.key === 'Escape') {
                this.close();
            }
        });
    },
    methods: {
        async loadScopes(modalData) {
            let response;
            try {
                response = await list();
            } catch (e) {
                return;
            }
            if (response.data.status === this.getConstants.RESPONSE_CODES.RESPONSE_CODE_ERROR) {
                this.$toast.open({
                    message: 'Error recovering scopes',
                    type: 'error',
                    position: 'top-right',
                    duration: 2000,
                });
                return;
            }

            this.scopes = response.data.result;
            _.forEach(this.scopes, scope => {
                if (!this.fieldsByScope[scope.id]) {
                    this.fieldsByScope[scope.id] = {'fields': [], 'loading': false, 'selected': []};
                    if (!this.isDisabledScope(scope)) {
                        this.getFieldsByScope(scope.id);
                    }
                }
            });
            if (modalData !== null) {
                _.forEach(_.cloneDeep(modalData), (scope, scopeId) => {
                    this.fieldsByScope[scopeId]['selected'].push.apply(this.fieldsByScope[scopeId]['selected'], scope.selected);

                })
                this.filteredFields = _.cloneDeep(this.fieldsByScope);
            }
        },
        async getFieldsByScope(scopeId) {
            this.fieldsByScope[scopeId].loading = true;
            let response;
            try {
                response = await getFieldsByScope(scopeId);
            } catch (e) {
                return;
            }
            if (response.data.status === this.getInsightsConstants.RESPONSE_CODES.RESPONSE_CODE_ERROR) {
                this.$toast.open({
                    message: 'Error recovering platform accounts',
                    type: 'error',
                    position: 'top-right',
                    duration: 2000,
                });
                return;
            }

            this.fieldsByScope[scopeId].fields = response.data.result;
            this.addMandatorySelected(scopeId);
            this.setHasData(scopeId);
            this.fieldsByScope[scopeId].loading = false;
            this.filteredFields = _.cloneDeep(this.fieldsByScope);
            this.makeFiltration(this.searchValue);
        },
        isFieldSelected(scopeId, field) {
            let element = _.find(this.fieldsByScope[scopeId].selected, fieldSelected => fieldSelected.id === field.id)
            return element !== undefined;
        },
        isDisabledScope(scope) {
            if (scope.type !== this.getInsightsConstants.SCOPE_PLATFORM) {
                return false;
            }
            return scope.status === this.getInsightsConstants.PLATFORM_DISABLED_STATUS;
        },
        collapseScope(scope) {
            if (this.isScopeOpened(scope)) {
                this.scopesOpened = _.filter(this.scopesOpened, element => element !== scope.id);
            } else {
                this.scopesOpened.push(scope.id);
            }
        },
        isScopeOpened(scope) {
            return _.includes(this.scopesOpened, scope.id);
        },
        close() {
            this.$emit('dispatch-close-modal')
            this.fieldsByScope = {};
        },
        saveFields() {
            this.$emit('dispatch-save-fields', this.fieldsByScope)
            this.fieldsByScope = {};
        },
        resetSearchValue() {
            this.searchValue = null;
        },
        addMandatorySelected(scopeId) {
            _.forEach(this.fieldsByScope[scopeId].fields, (field) => {
                if (_.includes(this.getCurrentObject.object.mandatory_fields, field.id) && !this.isFieldSelected(scopeId, field)) {
                    this.fieldsByScope[scopeId].selected.push(field);
                }
            });
        },
        setHasData(scopeId) {
            this.hasData[scopeId] = this.fieldsByScope[scopeId].fields.length > 0;
        },
        resetFilters() {
            this.selectedTypes = [];
            this.selectedInDropdown = [];
        },
        makeFiltration(searchValue) {
            this.filteredFields = _.cloneDeep(this.fieldsByScope);
            _.forEach(this.filteredFields, (fields, key) => {
                if (searchValue) {
                    this.filteredFields[key].fields = _.filter(_.cloneDeep(this.filteredFields[key].fields), field => _.toLower(field.label).includes(_.toLower(searchValue)));
                }
            })
        },
        unSelectField(fieldName, scopeId) {
            this.fieldsByScope[scopeId].selected = _.filter(this.fieldsByScope[scopeId].selected, field => field.name !== fieldName);
        },
        isSaveButtonDisabled() {
            return this.$route.name.includes('Edit') && _.find(this.getCurrentObject.object.datasources, datasource => datasource.platform.name === 'google-ads' && datasource.specific_data.type === 'Shopping') !== undefined
        },
        isFieldDisabled(field) {
            return this.$route.name.includes('Edit') && field.type === 'Dimension'
        },
        addShowPopper() {
            this.popper = true;
        },
        removeShowPopper() {
            this.popper = false;
        },
        isPopperActive() {
            return this.popper;
        }
    }
}
</script>
<style lang="scss">
.entity-selector {
    min-height: 100px;
}
</style>
