<template>
    <ListHeader v-if="$route.name === 'Users'"
                :has-filters="false">
        <template v-slot:breadcrumb>
            <div id="breadcrumb">
                <ul>
                    <li>
                        <a class="navigate-router">
                            <span class="breadcrumb-level-1 navigate-to ap2-truncate"
                                  :style="getBulkActions.selectedItems.length > 0 ? 'color: var(--bulkActionsText)' : null">
                                Users
                            </span>
                        </a>
                    </li>
                </ul>
            </div>
        </template>
        <template v-slot:bulk-actions>
            <div v-if="getBulkActions.origin === 'users' && getBulkActions.selectedItems.length > 0" class="header-right">
                <UsersBulkActions/>
            </div>
        </template>
        <template v-slot:actions>
            <a href="/users/new">
<!--            <router-link to="/users/new">-->
                <button type="button" class="ap2-btn btn btn-primary">
            <span :style="{'webkit-mask-image':'url(' + newIcon + ')',
                                      'mask-repeat': 'no-repeat',
                                      'mask-size': '70%',
                                      'mask-position': 'center'}"
                  class="new">
            </span>
                    New user
                </button>
            </a>
<!--            </router-link>-->
        </template>
    </ListHeader>
</template>

<script>
    import {mapGetters} from "vuex";
    import search from '@/assets/images/magnifying-glass-solid.svg';
    import UsersBulkActions from "@/components/commons/bulk-actions/users";
    import ListHeader from "@/components/commons/header/list-header";
    import ellipsisVertical from "@/assets/images/ellipsis-vertical-solid.svg";
    import play from "@/assets/images/play-solid.svg";
    import pause from "@/assets/images/pause-solid.svg";
    import newIcon from "@/assets/images/plus-solid.svg";

    export default {
        name: 'UsersListHeader',
        components: {
            ListHeader,
            UsersBulkActions,
        },
        computed: {
            ...mapGetters(['getFilterDrawer', 'getBulkActions', 'getSearchTerm']),
        },
        data() {
            return {
                searchTerm: null,
                ellipsisVertical: ellipsisVertical,
                search: search,
                play: play,
                pause: pause,
                newIcon: newIcon,
                selectedStatus: null,
                selectedTypes: []
            }
        },
        watch: {
            searchTerm: {
                handler: function (newVal) {
                    this.$store.dispatch('setSearchTerm', {searchTerm: newVal})
                }
            },
        },
    }
</script>
