<template>
    <div v-if="getModalDataShareType.show && types.length > 0">
        <div
                :class="['ap2-modal ap2-modal-md  ap2-scrollbar ap2-modal-short ap2-modal-scrollable  ap2-modal-scrollable-short', getModalDataShareType.show ? 'open': null]"
                id="marketplace-modal">
            <div class="modal-wrapper">
                <div class="ap2-modal-header">
                    <div class="modal-header-title-wrapper">
                        <div class="modal-header-reference">
                            INSIGHTS
                        </div>
                        <div class="modal-header-title">
                            Select a DataShare Type
                        </div>
                    </div>
                    <div class="modal-header-close close-modal-demo">
                        <svg @click="close()"
                             height="1em"
                             viewBox="0 0 384 512"
                             xmlns="http://www.w3.org/2000/svg">
                            <path class="svg-fill"
                                  d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"/>
                        </svg>
                    </div>
                </div>
                <div class="ap2-modal-content">
                    <div class="modal-actions-header">
                        <div class="pull-right">
                            <div class="search-extensible active">
                                <input :value="searchValue"
                                       @input="searchValue=$event.target.value"
                                       type="text"
                                       class="input active"
                                       placeholder="Search">
                                <span v-if="searchValue"
                                      @click="resetSearchValue()"
                                      class="icon"
                                      :style="['mask-image: url(' + plusSolid + ')', 'mask-repeat: no-repeat',
                      'mask-position: center', 'mask-size: 11px', 'background-color: var(--overAppContainerColorMedium)',
                      'transform: rotate(45deg)']"></span>
                                <span v-else class="icon" :style="'-webkit-mask-image: url(' + search + ')'"></span>
                            </div>
                        </div>
                    </div>
                    <perfect-scrollbar>
                        <div class="cards-wrapper">
                            <TypeCard @selection="typeSelection"
                                      v-for="type in block"
                                      @click="goToDataShareCreation()"
                                      :key="type.id"
                                      :type="type"
                            />
                        </div>
                        <nav aria-label="Page navigation example" class="float-end">
                            <ul class="ap2-pagination pagination pagination-sm justify-content-start mt-2">
                                <li
                                        @click="previousPage()"
                                        :class="['page-item', !allowPrevious ? 'disabled' : null]"
                                >
                                    <a class="page-link">Previous</a>
                                </li>
                                <li
                                        id="page-selector"
                                        class="page-item"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                        style="..."
                                >
                                    <button class="page-link dropdown-toggle">
                                        {{ page + 1 }}
                                    </button>
                                </li>
                                <div
                                        tabindex="-1"
                                        role="menu"
                                        aria-hidden="true"
                                        class="dropdown-menu at-dropdown-menu-pages overflow-auto">
                                    <div class="page-scrollbar-container ps ">
                                        <section class="at-dropdown-items_listWrap">
                                            <div class="col-xs-12">
                                            </div>
                                            <ul class="nav flex-column">
                                                <li v-for="page in getNextPages"
                                                    @click="setPage(page)"
                                                    :key="page"
                                                >
                                                    <a :class="['dropdown-item', getNextPages === page ? 'active' : null]">
                                                        {{ page }}
                                                    </a>
                                                </li>
                                            </ul>
                                        </section>
                                    </div>
                                </div>
                                <li
                                        @click="nextPage()"
                                        :class="['page-item', !allowNext ? 'disabled' : null]"
                                >
                                    <a class="page-link">Next</a>
                                </li>
                            </ul>
                        </nav>
                    </perfect-scrollbar>
                </div>
                <div class="ap2-modal-footer modal-controls">
                    <div class="modal-controls">
                        <button type="button"
                                class="ap2-btn btn btn-dark close-modal-demo"
                                @click="close()">
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";
    import _ from "lodash";
    import {PerfectScrollbar} from 'vue3-perfect-scrollbar';
    import plusSolid from "@/assets/images/plus-solid.svg";
    import search from "@/assets/images/magnifying-glass-solid.svg";
    import TypeCard from "@/components/modal/modal-datashare-type/type-card/index.vue";
    import {list_datashare_types as DataShareTypesList} from "@/services/data-share";

    export default {
        name: 'ModalDataShareType',
        components: {
            TypeCard,
            PerfectScrollbar
        },
        data() {
            return {
                page: 0,
                pageSize: 14,
                types: [],
                searchValue: null,
                plusSolid: plusSolid,
                search: search,
                showDropdown: false,
                selectedType: null
            }
        },
        computed: {
            ...mapGetters(['getModalDataShareType', 'getModalAccounts', 'getConstants']),
            filterTypes() {
                let toReturn = this.types
                if (this.searchValue) {
                    this.initPage()
                    toReturn = _.filter(this.types, type => _.toLower(type.label).includes(_.toLower(this.searchValue)))
                }
                return toReturn
            },
            items() {
                return _.chunk(this.filterTypes, this.pageSize);
            },
            block() {
                return this.items.length === 0 ? [] : this.items[this.page];
            },
            currentPageSize() {
                return this.block.length;
            },
            start() {
                if (this.items.length === 0) {
                    return 0;
                }
                let normalStart = (this.page * this.currentPageSize) + 1;
                let optimalStart = (this.page * this.pageSize) + 1;
                if (normalStart < optimalStart) {
                    return ((this.page - 1) * this.pageSize) + this.pageSize + 1;
                }
                return normalStart;
            },

            end() {
                if (this.items.length === 0) {
                    return 0;
                }

                let end = (this.start - 1) + this.currentPageSize;
                return this.total < end ? this.total : end;
            },
            total() {
                return this.filterTypes ? this.filterTypes.length : 0;
            },
            allowNext() {
                return ((this.page + 1) * this.pageSize) < this.total;
            },
            allowPrevious() {
                return this.page > 0;
            },
            getNextPages() {
                let page = 1;
                let pages = [];
                while (page <= this.items.length) {
                    pages.push(page);
                    page++;
                }
                return pages;
            },
        },
        created() {
            window.addEventListener('keydown', (e) => {
                if (e.key === 'Escape') {
                    this.close();
                }
            });
        },
        mounted() {
            this.$store.watch(state => state.modalTypes, async (value) => {
                if (value.show === true) {
                    await this.loadTypes();
                }
            }, {deep: true});
        },
        methods: {
            nextPage() {
                if (this.allowNext) {
                    this.page = this.page + 1;
                }
            },
            previousPage() {
                if (this.allowPrevious) {
                    this.page = this.page - 1;
                }
            },
            setPage(page) {
                this.page = page - 1;
            },
            async loadTypes() {
                let response;
                try {
                    response = await DataShareTypesList();
                    console.log(response)
                } catch (e) {
                    console.log("Error: ", e);
                    return null;
                }
                this.types = response.data.result;
            },
            close() {
                _.set(this.getModalDataShareType, 'show', false);
            },
            resetSearchValue() {
                this.searchValue = null;
            },
            initPage() {
                this.page = 0;
            },
            typeSelection(type) {
                this.selectedType = type;
            },

            goToDataShareCreation() {
                let type = this.selectedType
                this.close()
                window.location = '/data-shares/' + type.name + '/new?typeId=' + type.id
            }
        }
    }
</script>
<style lang="scss">
.entity-selector {
    min-height: 100px;
}
</style>
