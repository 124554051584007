<template>
    <div v-if="getUser && getDrawerTeams.show"
         :class="['ap2-modal', 'ap2-modal-md', 'ap2-modal-is-drawer', getDrawerTeams.show ? 'open' : null]"
         id="drawer-test">
        <div class="modal-wrapper ">
            <div class="ap2-modal-header">
                <div class="modal-header-title-wrapper">
                    <div class="modal-header-reference">
                        {{ getCurrentObject.object.first_name + ' ' + getCurrentObject.object.last_name }}
                    </div>
                    <div class="modal-header-title">
                        New team(s)
                    </div>
                </div>
                <div class="modal-header-close close-modal-demo">
                    <svg @click="closeTeamsDrawer()"
                         height="1em"
                         viewBox="0 0 384 512"
                         xmlns="http://www.w3.org/2000/svg">
                        <path class="svg-fill"
                              d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"/>
                    </svg>
                </div>
            </div>
            <div class="ap2-modal-content ps" id="ps-drawer-teams">
                <div @click="setCollapse()"
                     :class="['drawer-title-section', 'ap2-toggle-collapse', getCollapseShow ? 'show' : null]"
                     data-ap2-target="#tst-options">
                    <span class="ap2-toggle-collapse-title">
                        Teams
                    </span>
                    <span class="ap2-toggle-collapse-chevron">
                        <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                            <path class="svg-fill"
                                d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"></path>
                        </svg>
                    </span>
                </div>
                <div id="tst-options" :class="['collapsable-element', getCollapseShow ? 'show' : null]">
                    <div v-if="teams.length > 0" class="form-group ap2-form-group mt-2">
                        <div class="ap2-form-control-has-icon left is-search">
                            <input :value="filterItemsText"
                                   @input="filterItemsText=$event.target.value"
                                   class="form-control ap2-form-control"
                                   placeholder="Search"
                                   type="text">
                            <span class="icon" :style="'-webkit-mask-image: url(' + search + ')'"></span>
                        </div>
                    </div>
                    <div v-if="loading" class="text-center">
                        <i class="fas fa-spinner fa-spin"></i> Loading teams
                    </div>
                    <ul v-if="teams.length > 0 & !loading" class="input-selector-list has-visible-input">
                        <li v-for="item in getItemsWithFilter"
                            class="form-label"
                            :key="item.id">
                            <input v-model="itemsSelected"
                                   type="checkbox"
                                   :id="item.id"
                                   :value="item.name">
                            <label :for="item.id">
                                {{ item.name }}
                            </label>
                        </li>
                    </ul>
                    <p v-if="teams.length === 0 && !loading" class="mt-2">
                    <center>
                        No more teams to add user
                        </center>
                    </p>
                </div>
            </div>
            <div class="ap2-modal-footer modal-controls">
                <div class="modal-controls">
                    <button @click="cancelButtonActions()"
                            type="button"
                            class="ap2-btn btn btn-dark close-modal-demo">
                        Cancel
                    </button>
                    <button @click="setSelectedItems()"
                            type="button"
                            class="ap2-btn btn btn-success close-modal-demo">
                        Save
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";
    import _ from "lodash";
    import search from '@/assets/images/magnifying-glass-solid.svg';
    import {list as TeamList} from "@/services/team";
    import {PerfectScrollbar} from 'vue3-perfect-scrollbar';

    export default {
        name: "TeamsDrawer",
        // components: {PerfectScrollbar},
        computed: {
            ...mapGetters(['getDrawerTeams', 'getConstants', 'getUser', 'getCurrentObject']),
            getItemsWithFilter() {
                let items = [];
                if (this.filterItemsText) {
                    _.forEach(this.teams.filter(element => _.toLower(element.name).includes(_.toLower(this.filterItemsText))), item => {
                        items.push(item);
                    })
                } else {
                    _.forEach(this.teams, item => {
                        items.push(item);
                    })
                }
                return items
            },
        },
        data() {
            return {
                filterItemsText: null,
                search: search,
                teams: [],
                itemsSelected: [],
                getCollapseShow: true,
                loading: false,
            }
        },
        mounted() {
            this.$store.watch(state => state.drawerTeams, async (value) => {
                if (value.origin === 'userTeams' && value.show === true) {
                    await this.loadTeams();
                }
            }, {deep: true});
        },
        methods: {
            closeTeamsDrawer() {
                this.itemsSelected = [];
                this.teams = [];
                this.filterItemsText = null;
                this.getCollapseShow = true;
                _.set(this.getDrawerTeams, 'data', {
                    itemsSelected: []
                })
                _.set(this.getDrawerTeams, 'show', false);
            },
            setSelectedItems() {
                _.set(this.getDrawerTeams, 'result', this.getSelectTeamsObjects());
                this.closeTeamsDrawer()
            },
            cancelButtonActions() {
                this.itemsSelected = this.getDrawerTeams.data.itemsSelected;
                this.closeTeamsDrawer();
            },
            setCollapse() {
                this.getCollapseShow ? this.getCollapseShow = false : this.getCollapseShow = true
            },
            async loadTeams() {
                this.loading = true;
                let response;
                try {
                    response = await TeamList();
                } catch (e) {
                    console.log("ERROR: ", e);
                    return;
                }
                let selectedItemsIds = [];
                _.forEach(this.getDrawerTeams.data.itemsSelected, item => {
                    selectedItemsIds.push(item.id);
                });
                _.forEach(response.data.result, team => {
                    if (!_.includes(selectedItemsIds, team.id)) {
                        this.teams.push(team);
                    }
                })
                this.loading = false;
            },
            getSelectTeamsObjects() {
                let selectedTeams = []
                _.forEach(this.teams, team => {
                    if (_.includes(this.itemsSelected, team.name)) {
                        selectedTeams.push(team)
                    }
                })
                return selectedTeams;
            }
        }
    }
</script>

<style scoped>

</style>