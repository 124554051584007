<template>
    <GeneralBulkActions @dispatch-bulk-action="applyAction"/>
</template>

<script>
    import {getCookie} from "@/helpers/get-cookie";
    import {deleteElements} from "@/services/user";
    import _ from "lodash";
    import GeneralBulkActions from "@/components/commons/bulk-actions"
    import {mapGetters} from "vuex";

    export default {
        name: "DimensionsBulkActions",
        components: {GeneralBulkActions},
        computed: {
            ...mapGetters(['getBulkActions', 'getConstants', 'getModalConfirmation'])
        },
        methods: {
            applyAction(action) {
                if (action === 'delete') {
                    _.set(this.getModalConfirmation, 'origin', 'dimensions')
                    _.set(this.getModalConfirmation, 'show', true)
                    _.set(this.getModalConfirmation, 'items', this.getBulkActions.selectedItems)
                    _.set(this.getModalConfirmation, 'action', 'delete')
                }
                _.set(this.getBulkActions, 'selectedItems', []);
            },
        }
    }
</script>

<style scoped>

</style>