<template>
    <div v-if="getUser && getDrawerManageGroups.show"
         :class="['ap2-modal', 'ap2-modal-md', 'ap2-modal-is-drawer', getDrawerManageGroups.show ? 'open' : null]"
         id="drawer-manage-groups"
    >
        <div class="modal-wrapper ">
            <div class="ap2-modal-header">
                <div class="modal-header-title-wrapper">
                    <div class="modal-header-reference">
                        {{ appInClientAccount.account.name }}
                    </div>
                    <div class="modal-header-title">
                        Manage groups
                    </div>
                </div>
                <div class="modal-header-close close-modal-demo">
                    <svg @click="closeDrawer()"
                         height="1em"
                         viewBox="0 0 384 512"
                         xmlns="http://www.w3.org/2000/svg">
                        <path class="svg-fill"
                              d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"/>
                    </svg>
                </div>
            </div>
            <div class="ap2-modal-content" id="ps-drawer-test">
                <perfect-scrollbar>
<!--                    <draggable v-model="groups" tag="ul" class="ap2-draganddrop_list">-->
<!--                        <template #item="{ element: group }">-->
                    <ul class="ap2-draganddrop_list" v-for="group in groups" :key="group.id">
                            <li class="ap2-draganddrop_element">
<!--                                <div class="drag-icon">-->
<!--                                    <span :style="'-webkit-mask-image: url(' + ellipsisIcon + ')'"></span>-->
<!--                                    <span :style="'-webkit-mask-image: url(' + ellipsisIcon + ')'"></span>-->
<!--                                </div>-->
                                <div class="form-group ap2-form-group">
                                    <input
                                            @input="group.name = $event.target.value"
                                            :value="group.name"
                                            class="form-control ap2-form-control"
                                            placeholder="Please add a name"
                                            type="text"
                                    >
                                </div>
                                <div @click="deleteGroup(group)"
                                     :class="['rounded-control ', groups.length === 1 ? 'disabled' : null]"
                                     tooltip="Delete"
                                     flow="left">
                                    <div class="delete" :style="'-webkit-mask-image: url(' + deleteIcon + ')'"></div>
                                </div>
                            </li>
                    </ul>
<!--                        </template>-->
<!--                    </draggable>-->
                    <div class="align-left">
                        <button @click="addGroup()" type="button" class="ap2-btn btn btn-light">
                            <span class="new" :style="{'webkit-mask-image':'url(' + newIcon + ')',
                                      'mask-repeat': 'no-repeat',
                                      'mask-size': '70%',
                                      'mask-position': 'center'}"></span>
                            Add New Group
                        </button>
                    </div>
                </perfect-scrollbar>
            </div>
            <div class="ap2-modal-footer modal-controls">
                <div class="modal-controls">
                    <button @click="cancelButtonActions()"
                            type="button"
                            class="ap2-btn btn btn-dark close-modal-demo">
                        Cancel
                    </button>
                    <button @click="save()"
                            type="button"
                            class="ap2-btn btn btn-success close-modal-demo">
                        Save
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

    import _ from "lodash";
    import {mapGetters} from "vuex";
    import {PerfectScrollbar} from "vue3-perfect-scrollbar";
    import ellipsisIcon from "@/assets/images/ellipsis-vertical-solid.svg"
    import deleteIcon from "@/assets/images/trash-solid.svg"
    import newIcon from "@/assets/images/plus-solid.svg";
    import {detail as DetailAppInClientGroup} from "@/services/app-in-client-group";
    import draggable from 'vuedraggable';

    export default {
        name: 'AppInClientAccountManageGroupsDrawer',
        props: {},
        components: {
            PerfectScrollbar,
            // draggable
        },
        setup: () => ({}),
        data() {
            return {
                appInClient: null,
                appInClientAccount: null,
                groups: [],
                ellipsisIcon: ellipsisIcon,
                deleteIcon: deleteIcon,
                newIcon: newIcon
            }
        },
        computed: {
            ...mapGetters(['getDrawerManageGroups', 'getConstants', 'getUser', 'getCurrentClient']),
        },
        created() {

        },
        watch() {
        },
        updated() {
        },
        mounted() {
            this.$store.watch(state => state.drawerManageGroups, (value) => {
                if (value.show) {
                    this.appInClient = value.data.appInClient;
                    this.appInClientAccount = value.data.appInClientAccount;
                    this.groups = _.cloneDeep(this.appInClientAccount.groups);
                }
            }, {deep: true});
        },
        methods: {
            closeDrawer() {
                _.set(this.getDrawerManageGroups, 'show', false);
                this.appInClientAccount = null;
                this.appInClient = null;
                this.groups = []
                this.$emit('dispatch-close-drawer')
            },
            save() {
                _.set(this.getDrawerManageGroups, 'result', this.groups);
                this.closeDrawer()
            },
            cancelButtonActions() {
                this.closeDrawer();
            },
            async addGroup() {
                let response;
                try {
                    response = await DetailAppInClientGroup(null, this.appInClient.app.id, this.appInClientAccount.account.id);
                } catch (e) {
                    console.log("ERROR: ", e);
                    return;
                }
                let appInClientGroup = response.data.result;
                appInClientGroup.name = ''
                this.groups.push(appInClientGroup);
            },
            deleteGroup(group) {
                this.groups = _.filter(_.cloneDeep(this.groups), g => g.id !== group.id);
            }
        },
        validations() {
        }
    }
</script>