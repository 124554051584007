<template>
    <div class="form-group ap2-form-group" style="width: 50%">
        <input class="form-control ap2-form-control"
               placeholder="Set a value"
               type="text"
               :value="value"
        >
    </div>
</template>
<script>
    export default {
        name: "InputTextField",
        props: {
            value: {
                type: String,
                required: false,
                default: null
            }
        }
    }
</script>