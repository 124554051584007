import {getDrawerDataSource} from "@/store/getters";

export const setSearchTerm = ({commit}, {searchTerm}) => {
    commit('SET_SEARCH_TERM', searchTerm);
};

export const setModalClient = ({commit}, {modalClient}) => {
    commit('SET_MODAL_CLIENT', modalClient);
};

export const setModalScript = ({commit}, {modalScript}) => {
    commit('SET_MODAL_SCRIPT', modalScript);
};

export const setModalFieldsGoogleSheets = ({commit}, {modalFieldsGoogleSheets}) => {
    commit('SET_MODAL_FIELDS_GOOGLE_SHEETS', modalFieldsGoogleSheets);
};

export const setModalFieldsLookerStudio = ({commit}, {modalFieldsLookerStudio}) => {
    commit('SET_MODAL_FIELDS_LOOKER_STUDIO', modalFieldsLookerStudio);
};

export const setConstants = ({commit}, {constants}) => {
    commit('SET_CONSTANTS', constants);
};

export const setInsightsConstants = ({commit}, {insightsConstants}) => {
    commit('SET_INSIGHTS_CONSTANTS', insightsConstants);
};

export const setDrawerDataSource = ({commit}, {drawerDataSource}) => {
    commit('SET_DRAWER_DATASOURCE', drawerDataSource);
};

export const setDrawerDataShares = ({commit}, {drawerDataShares}) => {
    commit('SET_DRAWER_DATASHARES', drawerDataShares);
};

export const setDrawerDimensions = ({commit}, {drawerDimension}) => {
    commit('SET_DRAWER_DIMENSIONS', drawerDimension);
};

export const setDrawerMetrics = ({commit}, {drawerMetrics}) => {
    commit('SET_DRAWER_METRICS', drawerMetrics);
};
export const setModalApp = ({commit}, {modalApp}) => {
    commit('SET_MODAL_APP', modalApp);
};

export const setModalAppInClient = ({commit}, {modalAppInClient}) => {
    commit('SET_MODAL_APP_IN_CLIENT', modalAppInClient);
};

export const setModalAppsFilter = ({commit}, {modalAppsFilter}) => {
    commit('SET_MODAL_APPS_FILTER', modalAppsFilter);
};

export const setModalMarketplace = ({commit}, {modalMarketplace}) => {
    commit('SET_MODAL_MARKETPLACE', modalMarketplace);
};

export const setModalPlatforms = ({commit}, {modalPlatforms}) => {
    commit('SET_MODAL_PLATFORMS', modalPlatforms);
};

export const setModalDataShareTyPE = ({commit}, {modalDataShareType}) => {
    commit('SET_MODAL_DATASHARE_TYPES', modalDataShareType);
};

export const setModalAccounts = ({commit}, {modalAccounts}) => {
    commit('SET_MODAL_ACCOUNTS', modalAccounts);
};

export const setModalCredentials = ({commit}, {modalCredentials}) => {
    commit('SET_MODAL_CREDENTIALS', modalCredentials);
};

export const setModalUser = ({commit}, {modalUser}) => {
    commit('SET_MODAL_USER', modalUser);
};

export const setModalTeam = ({commit}, {modalTeam}) => {
    commit('SET_MODAL_TEAM', modalTeam);
};

export const setModalResetPassword = ({commit}, {modalResetPassword}) => {
    commit('SET_MODAL_RESET_PASSWORD', modalResetPassword);
};

export const setModalOptionsParameters = ({commit}, {modalOptionsParameters}) => {
    commit('SET_MODAL_OPTIONS_PARAMETERS', modalOptionsParameters);
};

export const setAppsFiltered = ({commit}, {appsFiltered}) => {
    commit('SET_APPS_FILTERED', appsFiltered);
};

export const setElementsViewMode = ({commit}, {elementsViewMode}) => {
    commit('SET_ELEMENTS_VIEW_MODE', elementsViewMode);
};

export const setClientTeam = ({commit}, {clientTeams}) => {
    commit('SET_CLIENT_TEAM', clientTeams);
};

export const setCurrentObject = ({commit}, {currentObject}) => {
    commit('SET_CURRENT_OBJECT', currentObject);
};

export const setCurrentClient = ({commit}, {currentClient}) => {
    commit('SET_CURRENT_CLIENT', currentClient);
};

export const setUser = ({commit}, {user}) => {
    commit('SET_USER', user);
};

export const setAllItemsSelected = ({commit}, {allItemsSelected}) => {
    commit('SET_ALL_ITEMS_SELECTED', allItemsSelected);
};

export const setAppPlatform = ({commit}, {appPlatform}) => {
    commit('SET_APP_PLATFORM', appPlatform);
};

export const setAdBlock = ({commit}, {adBlock}) => {
    commit('SET_AD_BLOCK', adBlock);
};

export const setSidebarOpen = ({commit}, {sidebarOpen}) => {
    commit('SET_SIDEBAR_OPEN', sidebarOpen);
};

export const setSavingObject = ({commit}, {savingObject}) => {
    commit('SET_SAVING_OBJECT', savingObject);
};

export const setBulkActions = ({commit}, {bulkActions}) => {
    commit('SET_BUlK_ACTIONS', bulkActions);
};

export const setDetailAction = ({commit}, {detailAction}) => {
    commit('SET_DETAIL_ACTION', detailAction);
};

export const setFilterDrawer = ({commit}, {filterDrawer}) => {
    commit('SET_FILTER_DRAWER', filterDrawer);
};

export const setChangedClients = ({commit}, {changedClients}) => {
    commit('SET_CHANGED_CLIENTS', changedClients);
};

export const setClientsDrawerOpen = ({commit}, {clientsDrawerOpen}) => {
    commit('SET_CLIENTS_DRAWER_OPEN', clientsDrawerOpen);
};

export const setDrawerSummary = ({commit}, {drawerSummary}) => {
    commit('SET_DRAWER_SUMMARY', drawerSummary);
};

export const setDrawerAppPage = ({commit}, {drawerAppPage}) => {
    commit('SET_DRAWER_APP_PAGE', drawerAppPage);
};

export const setDrawerTeamVariables = ({commit}, {drawerTeamVariables}) => {
    commit('SET_DRAWER_TEAM_VARIABLES', drawerTeamVariables);
};

export const setDrawerClientAccounts = ({commit}, {drawerClientAccounts}) => {
    commit('SET_DRAWER_CLIENT_ACCOUNTS', drawerClientAccounts);
};

export const setDrawerAppInClientAccounts = ({commit}, {drawerAppInClientAccounts}) => {
    commit('SET_DRAWER_APP_IN_CLIENT_ACCOUNTS', drawerAppInClientAccounts);
};

export const setDrawerTeams = ({commit}, {drawerTeams}) => {
    commit('SET_DRAWER_TEAMS', drawerTeams);
};

export const setDrawerUsers = ({commit}, {drawerUsers}) => {
    commit('SET_DRAWER_USERS', drawerUsers);
}

export const setDrawerGroupCampaigns = ({commit}, {drawerGroupCampaigns}) => {
    commit('SET_DRAWER_GROUP_CAMPAIGNS', drawerGroupCampaigns);
}

export const setDrawerCampaignAdGroups = ({commit}, {drawerCampaignAdGroups}) => {
    commit('SET_DRAWER_CAMPAIGN_AD_GROUPS', drawerCampaignAdGroups);
}

export const setDrawerManageGroups = ({commit}, {drawerManageGroups}) => {
    commit('SET_DRAWER_MANAGE_GROUPS', drawerManageGroups);
}

export const setModalOverwrite = ({commit}, {modalOverwrite}) => {
    commit('SET_MODAL_OVERWRITE', modalOverwrite);
};

export const setModalConfirmationDataSource = ({commit}, {modalConfirmationDataSource}) => {
    commit('SET_MODAL_CONFIRMATION_DATA_SOURCE', modalConfirmationDataSource);
};

export const setModalConfirmation = ({commit}, {modalConfirmation}) => {
    commit('SET_MODAL_CONFIRMATION', modalConfirmation);
};

export const setLoading = ({commit}, {loading}) => {
    commit('SET_LOADING', loading);
};

export const setIsObjectChanged = ({commit}, {isObjectChanged}) => {
    commit('SET_IS_OBJECT_CHANGED', isObjectChanged);
};

export const setDataSourceAccount = ({commit}, {dataSourceAccount}) => {
    commit('SET_DATASOURCE_ACCOUNT', dataSourceAccount);
};

export const setEmptySelectedData = ({commit}, {emptySelectedData}) => {
    commit('SET_EMPTY_SELECTED_DATA', emptySelectedData);
};


