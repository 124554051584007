<template>
    <div :class="['ap2-modal ap2-modal-almostfull  ap2-scrollbar ap2-modal-short ap2-modal-scrollable  ap2-modal-scrollable-short', getModalMarketplace.show ? 'open': null]"
         id="marketplace-modal">
        <div class="modal-wrapper">
            <div class="ap2-modal-header">
                <div class="modal-header-title-wrapper">
                    <div class="modal-header-reference">
                        APPS
                    </div>
                    <div class="modal-header-title">
                        Marketplace
                    </div>
                </div>
                <div class="modal-header-close close-modal-demo">
                    <svg @click="close()"
                         height="1em"
                         viewBox="0 0 384 512"
                         xmlns="http://www.w3.org/2000/svg">
                        <path class="svg-fill"
                              d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"/>
                    </svg>
                </div>
            </div>
            <div class="ap2-modal-content">
                <div class="modal-actions-header">
                    <div class="pull-right">
                        <div :class="['search-extensible', selectedTypes.length === 0 ? 'active': null]"
                             @click="resetFilters()">
                            <input :value="searchValue"
                                   @input="searchValue=$event.target.value"
                                   type="text"
                                   :class="['input', selectedTypes.length === 0 ? 'active' : null]"
                                   placeholder="Search">
                            <span v-if="searchValue"
                                  @click="resetSearchValue()"
                                  class="icon"
                                  :style="['mask-image: url(' + plusSolid + ')', 'mask-repeat: no-repeat',
                      'mask-position: center', 'mask-size: 11px', 'background-color: var(--overAppContainerColorMedium)',
                      'transform: rotate(45deg)']"></span>
                            <span v-else class="icon" :style="'-webkit-mask-image: url(' + search + ')'"></span>
                        </div>
                        <ul class="filter-pills-wrapper">
                            <li v-for="type in selectedTypes"
                                :class="type"
                                :key="type">
                                <!--                                <img :src="getTypeIcon(type)" class="img-filter-pill">-->
                                <span :style="{ 'background-image': 'url(' + getTypeIcon(type) + ')' }"></span>
                                {{ type }}
                                <div @click="resetTypeFilter(type)"
                                     class="close">
                                    <svg height="1em" viewBox="0 0 384 512" xmlns="http://www.w3.org/2000/svg">
                                        <path class="svg-fill"
                                              d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"></path>
                                    </svg>
                                </div>
                            </li>
                        </ul>
                        <div class="btn-group form-group ap2-form-group" role="group">
                            <button @click="setShowDropdown"
                                    id="btnGroupDrop1"
                                    type="button"
                                    class="header-options"
                                    :style="{'mask-image': 'url(' + ellipsisVertical + ')'}"
                                    tooltip="Options"
                                    aria-expanded="false"
                            />
                            <div :class="['ap2-form-control-has-icon', 'left', 'dropdown-menu', 'entity-selector', showDropdown ? 'show' : null]"
                                 :style="showDropdown ? 'position: absolute; inset: 0px auto auto 0px; margin: 0px; transform: translate3d(-134px, 22px, 0px);' : null"
                                 :data-popper-placement="showDropdown ? 'bottom-end' : null">
                                <ul class="input-selector-list has-icon">
                                    <li v-for="option in getAppTypes()"
                                        @click="selectOption(option)"
                                        :key="option"
                                        :class="[option, 'form-label']">
                                        <label :class="selectedInDropdown.indexOf(option) !== -1 ? 'selected-option' : null">
                                            <span :style="{ 'background-image': 'url(' + getTypeIcon(option) + ')' }"></span>
                                            {{ option }}
                                        </label>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <perfect-scrollbar>
                    <div class="cards-wrapper">
                        <marketplace-card
                                v-for="app in block"
                                :key="app.id"
                                :app="app"
                                :client="getCurrentClient"
                        />
                    </div>
                    <nav aria-label="Page navigation example" class="float-end">
                        <ul class="ap2-pagination pagination pagination-sm justify-content-start mt-2">
                            <li
                                    @click="previousPage()"
                                    :class="['page-item', !allowPrevious ? 'disabled' : null]"
                            >
                                <a class="page-link">Previous</a>
                            </li>
                            <li
                                    id="page-selector"
                                    class="page-item"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                    style="..."
                            >
                                <button class="page-link dropdown-toggle">
                                    {{ page + 1 }}
                                </button>
                            </li>
                            <div
                                    tabindex="-1"
                                    role="menu"
                                    aria-hidden="true"
                                    class="dropdown-menu at-dropdown-menu-pages overflow-auto">
                                <div class="page-scrollbar-container ps ">
                                    <section class="at-dropdown-items_listWrap">
                                        <div class="col-xs-12">
                                        </div>
                                        <ul class="nav flex-column">
                                            <li v-for="page in getNextPages"
                                                @click="setPage(page)"
                                                :key="page"
                                            >
                                                <a :class="['dropdown-item', getNextPages === page ? 'active' : null]">
                                                    {{ page }}
                                                </a>
                                            </li>
                                        </ul>
                                    </section>
                                </div>
                            </div>
                            <li
                                    @click="nextPage()"
                                    :class="['page-item', !allowNext ? 'disabled' : null]"
                            >
                                <a class="page-link">Next</a>
                            </li>
                        </ul>
                    </nav>
                </perfect-scrollbar>
            </div>
            <div class="ap2-modal-footer modal-controls">
                <div class="modal-controls">
                    <button type="button"
                            class="ap2-btn btn btn-dark close-modal-demo"
                            @click="close()">
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {list_marketplace} from "@/services/app";
    import {mapGetters} from "vuex";
    import _ from "lodash";
    import {PerfectScrollbar} from 'vue3-perfect-scrollbar';
    import plusSolid from "@/assets/images/plus-solid.svg";
    import search from "@/assets/images/magnifying-glass-solid.svg";
    import google from "@/assets/images/google.svg";
    import facebook from "@/assets/images/facebook.svg";
    import tiktok from "@/assets/images/tiktok.svg";
    import dv360 from "@/assets/images/DV360.svg";
    import twitter from "@/assets/images/twitter-x.svg";
    import system from "@/assets/images/code.svg";
    import ellipsisVertical from "@/assets/images/ellipsis-vertical-solid.svg";
    import MarketplaceCard from "@/components/modal/modal-marketplace/marketplace-app-card";
    import {setMultiline} from "@/helpers/multiline";

    export default {
        name: 'ModalMarketPlace',
        components: {
            MarketplaceCard,
            PerfectScrollbar
        },
        data() {
            return {
                page: 0,
                pageSize: 14,
                apps: [],
                searchValue: null,
                selectedTypes: [],
                selectedInDropdown: [],
                plusSolid: plusSolid,
                search: search,
                google: google,
                facebook: facebook,
                tiktok: tiktok,
                dv360:dv360,
                twitter: twitter,
                system: system,
                ellipsisVertical: ellipsisVertical,
                showDropdown: false
            }
        },
        computed: {
            ...mapGetters(['getModalMarketplace', 'getAppPlatform', 'getConstants', 'getCurrentClient']),
            filterApps() {
                let toReturn = this.apps
                if (this.searchValue) {
                    this.initPage()
                    toReturn = _.filter(this.apps, app => _.toLower(app.name).includes(_.toLower(this.searchValue)))
                }
                if (this.selectedTypes.length > 0) {
                    this.initPage()
                    toReturn = _.filter(toReturn, app => this.selectedTypes.includes(app.type));
                }
                return toReturn
            },
            items() {
                return _.chunk(this.filterApps, this.pageSize);
            },
            block() {
                return this.items.length === 0 ? [] : this.items[this.page];
            },
            currentPageSize() {
                return this.block.length;
            },
            start() {
                if (this.items.length === 0) {
                    return 0;
                }
                let normalStart = (this.page * this.currentPageSize) + 1;
                let optimalStart = (this.page * this.pageSize) + 1;
                if (normalStart < optimalStart) {
                    return ((this.page - 1) * this.pageSize) + this.pageSize + 1;
                }
                return normalStart;
            },
            end() {
                if (this.items.length === 0) {
                    return 0;
                }

                let end = (this.start - 1) + this.currentPageSize;
                return this.total < end ? this.total : end;
            },
            total() {
                return this.filterApps ? this.filterApps.length : 0;
            },
            allowNext() {
                return ((this.page + 1) * this.pageSize) < this.total;
            },
            allowPrevious() {
                return this.page > 0;
            },
            getNextPages() {
                let page = 1;
                let pages = [];
                while (page <= this.items.length) {
                    pages.push(page);
                    page++;
                }
                return pages;
            },
        },
        created() {
            window.addEventListener('keydown', (e) => {
                if (e.key === 'Escape') {
                    this.close();
                }
            });
        },
        mounted() {
            setMultiline();
            this.$store.watch(state => state.modalMarketplace, async (value) => {
                if (value.show === true) {
                    await this.loadApps();
                }
            }, {deep: true});
        },
        updated() {
            this.$nextTick(() => {
                setMultiline();
            })
        },
        methods: {
            nextPage() {
                if (this.allowNext) {
                    this.page = this.page + 1;
                }
            },
            previousPage() {
                if (this.allowPrevious) {
                    this.page = this.page - 1;
                }
            },
            setPage(page) {
                this.page = page - 1;
            },
            async loadApps() {
                let response;
                try {
                    response = await list_marketplace();
                } catch (e) {
                    console.log("Error: ", e);
                    return null;
                }

                this.apps = response.data.result;
            },
            close() {
                _.set(this.getModalMarketplace, 'show', false);
            },
            getAppTypes() {
                let appTypes = [];
                _.forEach(this.getConstants.APP_TYPES, appType => {
                    if (appType.name !== 'All') {
                        appTypes.push(appType.name)
                    }
                })
                return appTypes
            },
            resetSearchValue() {
                this.searchValue = null;
            },
            getTypeIcon(type) {
                let icons = {
                    'Google': google,
                    'Facebook': facebook,
                    'TikTok': tiktok,
                    'DV360':dv360,
                    'Twitter': twitter,
                    'System': system
                }
                return icons[type]
            },
            resetTypeFilter(type) {
                _.remove(this.selectedTypes, (typeSelected) => typeSelected === type);
                _.remove(this.selectedInDropdown, (typeSelected) => typeSelected === type);
            },
            resetFilters() {
                this.selectedTypes = [];
                this.selectedInDropdown = [];
            },
            apply() {
                this.selectedTypes = this.selectedInDropdown;
                this.showDropdown = false;
            },
            setShowDropdown() {
                this.showDropdown ? this.showDropdown = false : this.showDropdown = true
                if (this.showDropdown) {
                    this.selectedInDropdown = this.selectedTypes;
                }
            },
            initPage() {
                this.page = 0;
            },
            selectOption(option) {
                if (_.includes(this.selectedInDropdown, option)) {
                    _.remove(this.selectedInDropdown, value => value === option);
                } else {
                    this.selectedInDropdown.push(option);
                }
            }
        }
    }
</script>
<style lang="scss">
.entity-selector {
    min-height: 100px;
}
</style>
