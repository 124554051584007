<template>
    <div v-if="getUser && getDrawerCampaignAdGroups.show"
         :class="['ap2-modal', 'ap2-modal-md', 'ap2-modal-is-drawer', getDrawerCampaignAdGroups.show ? 'open' : null]"
         id="drawer-test">
        <div class="modal-wrapper ">
            <div class="ap2-modal-header">
                <div class="modal-header-title-wrapper">
                    <div class="modal-header-reference">
                        {{ appInClientAccount.account.name }}
                    </div>
                    <div class="modal-header-title">
                        {{ group.name }}
                    </div>
                </div>
                <div class="modal-header-close close-modal-demo">
                    <svg @click="closeDrawer()"
                         height="1em"
                         viewBox="0 0 384 512"
                         xmlns="http://www.w3.org/2000/svg">
                        <path class="svg-fill"
                              d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"/>
                    </svg>
                </div>
            </div>
            <div class="ap2-modal-content ps">
                <perfect-scrollbar>
                    <div
                            class="drawer-title-section ap2-toggle-collapse show"
                            data-ap2-target="#tst-options">
                    <span class="ap2-toggle-collapse-title">
                        AdGroups ({{ campaign.name }})
                    </span>
                        <span class="ap2-toggle-collapse-chevron">
                        <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                            <path class="svg-fill"
                                  d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"></path>
                        </svg>
                    </span>
                    </div>
                    <div id="tst-options" class="collapsable-element show">
                        <div v-if="adGroups.length > 0" class="form-group ap2-form-group mt-2">
                            <div class="ap2-form-control-has-icon left is-search">
                                <input :value="filterItemsText"
                                       @input="filterItemsText=$event.target.value"
                                       class="form-control ap2-form-control"
                                       placeholder="Search"
                                       type="text">
                                <span class="icon" :style="'-webkit-mask-image: url(' + search + ')'"></span>
                            </div>
                        </div>
                        <ul v-if="adGroups.length > 0" class="input-selector-list has-visible-input">
                            <li v-for="item in getItemsWithFilter"
                                class="form-label"
                                :key="item.id">
                                <input v-model="itemsSelected"
                                       type="checkbox"
                                       :id="item.id"
                                       :value="item">
                                <label :for="item.id">
                                    {{ item.name }}
                                </label>
                            </li>
                        </ul>
                    </div>
                </perfect-scrollbar>
            </div>
            <div class="ap2-modal-footer modal-controls">
                <div class="modal-controls">
                    <button @click="cancelButtonActions()"
                            type="button"
                            class="ap2-btn btn btn-dark close-modal-demo">
                        Cancel
                    </button>
                    <button @click="setSelectedItems()"
                            type="button"
                            class="ap2-btn btn btn-success close-modal-demo">
                        Save
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";
    import _ from "lodash";
    import search from '@/assets/images/magnifying-glass-solid.svg';
    import {getAdGroupsByCampaign} from "@/services/account";
    import {PerfectScrollbar} from 'vue3-perfect-scrollbar';

    export default {
        name: "AppInClientAccountGroupCampaignsAdGroupsDrawer",
        components: {PerfectScrollbar},
        data() {
            return {
                filterItemsText: null,
                search: search,
                appInClientAccount: null,
                adGroups: [],
                itemsSelected: [],
                group: null,
                campaign: null,
            }
        },
        computed: {
            ...mapGetters(['getDrawerCampaignAdGroups', 'getConstants', 'getUser']),
            getItemsWithFilter() {
                let items = [];
                if (this.filterItemsText) {
                    _.forEach(this.adGroups.filter(element => _.toLower(element.name).includes(_.toLower(this.filterItemsText))), item => {
                        items.push(item);
                    })
                } else {
                    _.forEach(this.adGroups, item => {
                        items.push(item);
                    })
                }
                return items
            },
        },
        mounted() {
            this.$store.watch(state => state.drawerCampaignAdGroups, (value) => {
                if (value.show) {
                    this.group = value.data.group;
                    this.campaign = value.data.campaign;
                    this.appInClientAccount = value.data.appInClientAccount;
                    this.loadAdGroups(value.data.itemsSelected);
                }
            }, {deep: true});
        },
        methods: {
            async loadAdGroups(itemsSelected) {
                let response;

                try {
                    response = await getAdGroupsByCampaign(this.appInClientAccount.account.id, this.campaign.id, this.appInClientAccount.account.network)
                } catch (e) {
                    console.log("ERROR: ", e);
                    return;
                }

                _.forEach(response.data.result, adGroup => {
                    if (_.find(itemsSelected, item => item.id === adGroup.id) === undefined) {
                        this.adGroups.push(adGroup)
                    }
                });
                this.itemsSelected = [];
            },
            closeDrawer() {
                _.set(this.getDrawerCampaignAdGroups, 'show', false);
                this.itemsSelected = [];
                this.adGroups = [];
                this.appInClientAccount = null;
                this.group = null;
                this.campaign = null;
                this.filterItemsText = null;
                this.$emit('dispatch-close-drawer')
            },
            setSelectedItems() {
                _.set(this.getDrawerCampaignAdGroups, 'result', this.itemsSelected);
                this.closeDrawer()
            },
            cancelButtonActions() {
                this.itemsSelected = this.getDrawerCampaignAdGroups.data.itemsSelected;
                this.closeDrawer();
            },
        }
    }
</script>