<template>
    <div v-if="getUser && getFilterDrawer.origin === 'apps' || getFilterDrawer.origin === 'all-apps-in-client' || getFilterDrawer.origin === 'apps-in-client'"
         :class="['ap2-modal', 'ap2-modal-md', 'ap2-modal-is-drawer', getFilterDrawer.show ? 'open' : null]"
         id="drawer-test">
        <div class="modal-wrapper ">
            <div class="ap2-modal-header">
                <div class="modal-header-title-wrapper">
                    <div class="modal-header-reference">
                        Apps
                    </div>
                    <div class="modal-header-title">
                        Filters
                    </div>
                </div>

                <div class="modal-header-close close-modal-demo">
                    <svg @click="closeAppsFiltersDrawer()"
                         height="1em"
                         viewBox="0 0 384 512"
                         xmlns="http://www.w3.org/2000/svg">
                        <path class="svg-fill"
                              d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"/>
                    </svg>
                </div>
            </div>
            <div class="ap2-modal-content">
                <perfect-scrollbar>
                    <multi-toggle-collapse
                            :name="'Filter type'"
                            :options="getAppsTypes()"
                            :options-type="'icon'"
                            :selected-options="selectedTypes"
                    />
                    <toggle-collapse
                            @dispatch-selected-option="changeSelectedStatus"
                            :name="'Filter elements'"
                            :options="getElementsViewModeList()"
                            :options-type="'icon'"
                            :selected-option="selectedStatus"
                    />

                    <div :class="['drawer-title-section', 'ap2-toggle-collapse', 'show']"
                             data-ap2-target="#tst-options"  v-if="getFilterDrawer.origin === 'all-apps-in-client'">
                    <span class="ap2-toggle-collapse-title">
                        Apps
                    </span>
                            <span class="ap2-toggle-collapse-chevron">
                        <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                            <path class="svg-fill"
                                  d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"></path>
                        </svg>
                    </span>
                        </div>
                    <div id="tst-options" :class="['collapsable-element', 'show']"  v-if="getFilterDrawer.origin === 'all-apps-in-client'">
                            <div class="form-group ap2-form-group mt-2">
                                <div class="ap2-form-control-has-icon left is-search">
                                    <input :value="filterItemsText"
                                           @input="filterItemsText=$event.target.value"
                                           class="form-control ap2-form-control"
                                           placeholder="Search"
                                           type="text">
                                    <span class="icon" :style="'-webkit-mask-image: url(' + search + ')'"></span>
                                </div>
                            </div>
                            <ul class="input-selector-list has-visible-input">
                                <li v-for="item in getItemsWithFilter"
                                    class="form-label"
                                    :key="item.id">
                                    <input v-model="selectedApps"
                                           type="checkbox"
                                           :id="item.id"
                                           :value="item.name">
                                    <label :for="item.id">
                                        {{ item.name }}
                                    </label>
                                </li>
                            </ul>

                        </div>
                </perfect-scrollbar>
            </div>
            <div class="ap2-modal-footer modal-controls">
                <div class="modal-controls">
                    <button @click="closeAppsFiltersDrawer()"
                            type="button"
                            class="ap2-btn btn btn-dark close-modal-demo">
                        Cancel
                    </button>
                    <button @click="setFilters()"
                            type="button"
                            class="ap2-btn btn btn-success close-modal-demo">
                        Save
                    </button>

                </div>
            </div>
        </div>

    </div>
</template>

<script>
import {mapGetters} from "vuex";
import ToggleCollapse from "@/components/commons/toggle-collapse/index.vue";
import Constants from "@/helpers/constants";
import enabled from '@/assets/images/play-solid.svg';
import disabled from '@/assets/images/pause-solid.svg';
import facebook from "@/assets/images/facebook.svg";
import google from "@/assets/images/google.svg";
import system from "@/assets/images/code.svg";
import tiktok from "@/assets/images/tiktok.svg";
import dv360 from "@/assets/images/DV360.svg";
import twitter from "@/assets/images/twitter-x.svg";
import _ from 'lodash';
import MultiToggleCollapse from "@/components/commons/multi-toggle-collapse";
import {PerfectScrollbar} from 'vue3-perfect-scrollbar';
import {list_marketplace} from "@/services/app";
import search from '@/assets/images/magnifying-glass-solid.svg';

export default {
    name: 'AppFilterDrawer',
    components: {MultiToggleCollapse, ToggleCollapse, PerfectScrollbar},
    computed: {
        ...mapGetters(['getUser','getFilterDrawer', 'getConstants']),
        getItemsWithFilter() {
            let items = [];
            if (this.filterItemsText) {
                _.forEach(this.apps.filter(element => _.toLower(element.name).includes(_.toLower(this.filterItemsText))), item => {
                    items.push(item);
                })
            } else {
                _.forEach(this.apps, item => {
                    items.push(item);
                })
            }
            return items
        },
        typeFilters() {
            let filters = [];
            _.forEach(this.getFilterDrawer.filters, filter => {
                if (filter.type === 'appType') {
                    filters.push(filter.filter)
                }
            })
            return filters;
        },
        statusFilter() {
            let status = null;
            _.forEach(this.getFilterDrawer.filters, filter => {
                if (filter.type === 'appStatus') {
                    status = filter.filter
                }
            })
            return status;
        },
        fieldTypeFilters() {
            let fieldType = null;
            _.forEach(this.getFilterDrawer.filters, filter => {
                if (filter.type === 'fieldType') {
                    fieldType = filter.filter
                }
            })
            return fieldType;
        },
        appsFilter() {
            let apps = [];
            _.forEach(this.getFilterDrawer.filters, filter => {
                if (filter.type === 'app') {
                    apps.push(filter.filter)
                }
            })
            return apps;

        },
    },
    watch: {
        statusFilter: {
            handler: function (newVal) {
                this.selectedStatus = newVal;
            }
        },
        typeFilters: {
            handler: function (newVal) {
                this.selectedTypes = newVal;
            }
        },
        appsFilter: {
            handler: function (newVal) {
                this.selectedApps = newVal;

            }
        },
        fieldTypeFilters: {
            handler: function (newVal) {
                this.selectedFieldType = newVal;
            }
        },
        selectedTypes: {
            handler: function (newVal) {
                if (newVal !== this.selectedTypes) {
                    if (this.selectedTypes.includes(Constants.ELEMENTS_VIEW_MODE_ALL)) {
                        if (this.selectedTypes.length > 1) {
                            _.remove(this.selectedTypes, function (type) {
                                return type === Constants.ELEMENTS_VIEW_MODE_ALL;
                            })
                        }
                    } else {
                        if (newVal.includes(Constants.ELEMENTS_VIEW_MODE_ALL)) {
                            this.selectedTypes = [Constants.ELEMENTS_VIEW_MODE_ALL];
                        }
                    }
                }
            }, deep: true
        },
        selectedApps: {
            handler: function (newVal) {
                if (newVal !== this.selectedApps) {
                    if (this.selectedApps.includes(Constants.ELEMENTS_VIEW_MODE_ALL)) {
                        if (this.selectedApps.length > 1) {
                            _.remove(this.selectedApps, function (type) {
                                return type === Constants.ELEMENTS_VIEW_MODE_ALL;
                            })
                        }
                    } else {
                        if (newVal.includes(Constants.ELEMENTS_VIEW_MODE_ALL)) {
                            this.selectedApps = [Constants.ELEMENTS_VIEW_MODE_ALL];
                        }
                    }
                }
            }, deep: true
        },

    },

    data() {
        return {
            selectedApps: [],
            selectedTypes: [],
            search: search,
            apps: null,
            selectedStatus: null,
            filterItemsText: null,
            selectedFieldType: null,
        }
    }
    ,
    created() {
        this.selectedTypes = this.typeFilters;
        this.selectedStatus = this.statusFilter;
        this.selectedApps = this.appsFilter;
        this.selectedFieldType = this.fieldTypeFilters;
        this.getApps();
    },
    methods: {
        async getApps() {
            let response;
            try {
                response = await list_marketplace();
            } catch (e) {
                console.log("Error: ", e);
                return null;
            }
            if (response === null || response.data.status === this.getConstants.RESPONSE_CODES.RESPONSE_CODE_ERROR) {
                    this.$toast.open({
                        message: 'Error recovering apps list',
                        type: 'error',
                        position: 'top-right',
                        duration: 2000,
                    });
                    return;
            }

            this.apps = response.data.result;
        },
        getAppsTypes() {
            return [
                {'name': 'Google', 'icon': google},
                {'name': 'Facebook', 'icon': facebook},
                {'name': 'TikTok', 'icon': tiktok},
                {'name': 'DV360', 'icon': dv360},
                {'name': 'Twitter', 'icon': twitter},
                {'name': 'System', 'icon': system},
            ]
        },
        getElementsViewModeList() {
            // let elementsViewMode =  _.forOwn(this.getConstants.ELEMENTS_VIEW_MODE_LIST, (value,) => value);
            return [
                {'name': 'Enabled', 'icon': enabled},
                {'name': 'Disabled', 'icon': disabled}
            ]
        },
        closeAppsFiltersDrawer() {
            this.selectedTypes = [];
            this.selectedStatus = null;
            this.selectedApps = [];
            this.selectedFieldType = null;
            _.set(this.getFilterDrawer, 'show', false)
        },
        changeSelectedStatus(status) {
            this.selectedStatus = status;
        },
        changeSelectedFieldType(type) {
            this.selectedFieldType = type;
        },
        setFilters() {
            let filters = [];
            if (this.selectedTypes.length > 0) {
                _.forEach(this.selectedTypes, type => {
                    filters.push({'type': 'appType', 'filter': type})
                })
            }
            if (this.selectedStatus) {
                filters.push({'type': 'appStatus', 'filter': this.selectedStatus})
            }
            if (this.selectedFieldType) {
                filters.push({'type': 'fieldType', 'filter': this.selectedFieldType})
            }
            if (this.selectedApps.length > 0) {
                _.forEach(this.selectedApps, type => {
                    filters.push({'type': 'app', 'filter': type})
                })

            }
            this.selectedStatus = null;
            this.selectedTypes = [];
            this.selectedApps = [];
            _.set(this.getFilterDrawer, 'filters', filters)
            this.$store.dispatch('setSearchTerm', {searchTerm: null})
            this.closeAppsFiltersDrawer();
        },
        showFilterDrawer() {
            return this.getFilterDrawer.origin === 'apps' || this.getFilterDrawer.origin === 'apps-in-client'
        }
    }
}
</script>