<template>
    <div v-if="getUser && getDrawerUsers.show"
         :class="['ap2-modal', 'ap2-modal-md', 'ap2-modal-is-drawer', getDrawerUsers.show ? 'open' : null]"
         id="drawer-test">
        <div class="modal-wrapper ">
            <div class="ap2-modal-header">
                <div class="modal-header-title-wrapper">
                    <div class="modal-header-reference">
                        {{ getCurrentObject.object.first_name + ' ' + getCurrentObject.object.last_name }}
                    </div>
                    <div class="modal-header-title">
                        New user(s)
                    </div>
                </div>
                <div class="modal-header-close close-modal-demo">
                    <svg @click="closeUsersDrawer()"
                         height="1em"
                         viewBox="0 0 384 512"
                         xmlns="http://www.w3.org/2000/svg">
                        <path class="svg-fill"
                              d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"/>
                    </svg>
                </div>
            </div>
            <div class="ap2-modal-content ps">
                <perfect-scrollbar>
                    <div @click="setCollapse()"
                         :class="['drawer-title-section', 'ap2-toggle-collapse', getCollapseShow ? 'show' : null]"
                         data-ap2-target="#tst-options">
                    <span class="ap2-toggle-collapse-title">
                        Users
                    </span>
                        <span class="ap2-toggle-collapse-chevron">
                        <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                            <path class="svg-fill"
                                  d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"></path>
                        </svg>
                    </span>
                    </div>
                    <div id="tst-options" :class="['collapsable-element', getCollapseShow ? 'show' : null]">
                        <div v-if="users.length > 0" class="form-group ap2-form-group mt-2">
                            <div class="ap2-form-control-has-icon left is-search">
                                <input :value="filterItemsText"
                                       @input="filterItemsText=$event.target.value"
                                       class="form-control ap2-form-control"
                                       placeholder="Search"
                                       type="text">
                                <span class="icon" :style="'-webkit-mask-image: url(' + search + ')'"></span>
                            </div>
                        </div>
                        <div v-if="loading" class="text-center">
                            <i class="fas fa-spinner fa-spin"></i> Loading users
                        </div>
                        <ul v-if="users.length > 0 & !loading" class="input-selector-list has-visible-input">
                            <li v-for="item in getItemsWithFilter"
                                class="form-label"
                                :key="item.id">
                                <input v-model="itemsSelected"
                                       type="checkbox"
                                       :id="item.id"
                                       :value="item.name">
                                <label :for="item.id">
                                    {{ item.name }}
                                </label>
                            </li>
                        </ul>
                        <p v-if="users.length === 0 && !loading" class="mt-2">
                        <center>
                            No more users to add user
                        </center>
                        </p>
                    </div>
                </perfect-scrollbar>
            </div>
            <div class="ap2-modal-footer modal-controls">
                <div class="modal-controls">
                    <button @click="cancelButtonActions()"
                            type="button"
                            class="ap2-btn btn btn-dark close-modal-demo">
                        Cancel
                    </button>
                    <button @click="setSelectedItems()"
                            type="button"
                            class="ap2-btn btn btn-success close-modal-demo">
                        Save
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";
    import _ from "lodash";
    import search from '@/assets/images/magnifying-glass-solid.svg';
    import {list} from "@/services/user";
    import {PerfectScrollbar} from 'vue3-perfect-scrollbar';

    export default {
        name: "UsersDrawer",
        components: {PerfectScrollbar},
        computed: {
            ...mapGetters(['getDrawerUsers', 'getConstants', 'getUser', 'getCurrentObject']),
            getItemsWithFilter() {
                let items = [];
                if (this.filterItemsText) {
                    _.forEach(this.users.filter(element => _.toLower(element.name).includes(_.toLower(this.filterItemsText))), item => {
                        items.push(item);
                    })
                } else {
                    _.forEach(this.users, item => {
                        items.push(item);
                    })
                }
                return items
            },
        },
        data() {
            return {
                filterItemsText: null,
                search: search,
                users: [],
                itemsSelected: [],
                getCollapseShow: true,
                loading: false,
            }
        },
        mounted() {
            this.$store.watch(state => state.drawerUsers, async (value) => {
                if (value.origin === 'teamUsers' && value.show === true) {
                    await this.loadUsers();
                }
            }, {deep: true});
        },
        methods: {
            closeUsersDrawer() {
                this.itemsSelected = [];
                this.users = [];
                this.filterItemsText = null;
                this.getCollapseShow = true;
                _.set(this.getDrawerUsers, 'data', {
                    itemsSelected: []
                })
                _.set(this.getDrawerUsers, 'show', false);
            },
            setSelectedItems() {
                _.set(this.getDrawerUsers, 'result', this.getSelectUsersObjects());
                this.closeUsersDrawer()
            },
            cancelButtonActions() {
                this.itemsSelected = this.getDrawerUsers.data.itemsSelected;
                this.closeUsersDrawer();
            },
            setCollapse() {
                this.getCollapseShow ? this.getCollapseShow = false : this.getCollapseShow = true
            },
            async loadUsers() {
                this.loading = true;
                let response;
                try {
                    response = await list();
                } catch (e) {
                    console.log("ERROR: ", e);
                    return;
                }
                let selectedItemsIds = [];
                _.forEach(this.getDrawerUsers.data.itemsSelected, item => {
                    selectedItemsIds.push(item.id);
                });
                _.forEach(response.data.result, user => {
                    if (!_.includes(selectedItemsIds, user.id)) {
                        this.users.push(user);
                    }
                })
                this.loading = false;
            },
            getSelectUsersObjects() {
                let selectedUsers = []
                _.forEach(this.users, user => {
                    if (_.includes(this.itemsSelected, user.name)) {
                        selectedUsers.push(user)
                    }
                })
                return selectedUsers;
            }
        }
    }
</script>

<style scoped>

</style>